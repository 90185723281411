import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TranslatedMultivalueCombobox } from "../../../../components/TranslatedMultivalueCombobox";
import { useAllParameters } from "../../../Estimation/Report/hooks/useAllParameters";
import { parameterSchema } from "../parameterSchema";

export const ParameterSelector = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const allParameters = useAllParameters();

  // This can be removed after all parameters are working with ActiveReports
  const workingParameters = allParameters.filter(({ key }) => {
    return parameterSchema[key] !== undefined;
  });

  return (
    <>
      <Controller
        name={"parameters"}
        render={({ field: { onChange, value = [] } }) => (
          <TranslatedMultivalueCombobox
            label={t("parameters")}
            items={workingParameters}
            onValueChange={onChange}
            selectedItems={value}
          />
        )}
      />
    </>
  );
};
