import { ColDef, StatusPanelDef } from "ag-grid-enterprise";
import { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Table } from "src/client-ts/components/Table";
import { useWorkerState } from "src/client-ts/hooks/useWorkerState";
import { ClassificationGroup } from "ts-bindings/ClassificationGroup";
import { EstimationProjectSummary } from "ts-bindings/EstimationProjectSummary";
import { useGetGroupsQuery } from "../ComponentsView/hooks/useGetGroups";
import { ImportSelectedGroupsButton } from "./ImportSelectedGroupsButton";
import { useComponentGroupsQuery } from "./useComponentGroups";

type ImportComponentGroupsProps = {
  projectId: number;
  groupsEdited: () => void;
};
export function ImportComponentGroups({
  projectId,
  groupsEdited,
}: ImportComponentGroupsProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });
  const projects = useWorkerState("EstimationProjectsState");

  const { data: projectsExistingGroups } = useComponentGroupsQuery(projectId);
  const methods = useForm<{ projectId: number }>();
  const { watch, control } = methods;

  const selectedProjectId = watch("projectId");
  const { data: projectGroups } = useGetGroupsQuery(selectedProjectId);

  const projectColDefs: ColDef<EstimationProjectSummary>[] = useMemo(
    () => [
      { field: "code", headerName: t`form.code`, flex: 1.5 },
      { field: "name", headerName: t`form.name`, flex: 3.5 },
      {
        field: "version",
        headerName: t`form.version`,
        comparator: (a, b) => Number(a) - Number(b),
      },
    ],
    [t]
  );
  const groupColDefs: ColDef<ClassificationGroup>[] = useMemo(
    () => [
      { field: "code", headerName: t`form.code`, flex: 1.5 },
      { field: "description", headerName: t`form.name`, flex: 3.5 },
    ],
    [t]
  );
  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: ImportSelectedGroupsButton,
          statusPanelParams: {
            projectId,
            groupsEdited,
          },
        },
      ],
    };
  }, []);

  return (
    <div className={"h-[700px]"}>
      <FormProvider {...methods}>
        <Controller
          control={control}
          name={"projectId"}
          render={({ field: { onChange } }) => (
            <Table<EstimationProjectSummary>
              rowData={projects}
              columnDefs={projectColDefs}
              rowSelection={{ mode: "singleRow", checkboxes: true }}
              className="h-128 w-full mt-2 mb-2"
              defaultColDef={{
                sortable: true,
                filter: "agTextColumnFilter",
                floatingFilter: true,
                resizable: true,
                flex: 1,
              }}
              onSelectionChanged={(e) => {
                onChange(e.api.getSelectedRows()[0]?.id);
              }}
            />
          )}
        />
        <div className={"mt-1"}>{t`duplicateCodeInfo`}</div>
        <Table<ClassificationGroup>
          rowData={selectedProjectId ? projectGroups : []}
          columnDefs={groupColDefs}
          className="h-[315px] w-full mt-2 mb-2"
          rowSelection={{ mode: "multiRow", checkboxes: true }}
          suppressRowClickSelection={true}
          statusBar={statusBar}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1,
          }}
          isRowSelectable={(params) => {
            if (!projectsExistingGroups) return true;
            const groupExistsOnProject = projectsExistingGroups.find(
              (group) => group.code === params.data?.code
            );
            return groupExistsOnProject === undefined;
          }}
        />
      </FormProvider>
    </div>
  );
}
