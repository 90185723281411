import { Input } from "@tocoman/ui";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import { isNumber } from "../../../../utils/validations";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { StringCombobox } from "../../../../components/StringCombobox";
import { useTranslation } from "react-i18next";
import { TransportFileFormData } from "./TransportFileUploadModal";
import { OrgClassification } from "../../../../../../ts-bindings/OrgClassification";
import { useDefault } from "../../../../hooks/useDefault";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { useMemo } from "react";
import { FeatureFlagContainer } from "../../../../split/FeatureFlagContainer";
import { FeatureFlags } from "../../../../split/FeatureFlags";
import { useSetNextAvailableVersion } from "../../../../hooks/useSetNextAvailableVersion";
import { useProjectGroups } from "../../ProjectDetailsModal/useProjectGroups";
import { useAllowedCurrenciesQuery } from "src/client-ts/containers/Admin/Settings/OrganizationSettings/OrganizationCurrencySettings/useOrganizationCurrencySettings";

export function ProjectDataForm() {
  const { t } = useTranslation("costControl", {
    keyPrefix: "projectList.transportFileModal",
  });
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<TransportFileFormData>();

  const { data: projectGroups } = useProjectGroups();

  const { data: allowedCurrencies } = useAllowedCurrenciesQuery();

  const orgClassifications: OrgClassification[] = useDefault(
    useWorkerState("ClassificationsState"),
    []
  );

  const classifications = useMemo(
    () => orgClassifications.map((item) => item.name),
    [orgClassifications]
  );

  const {
    setNextAvailableVersion,
    versionLoading,
  } = useSetNextAvailableVersion(setValue);

  const mkInputProps = (
    field: keyof TransportFileFormData,
    registerOptions?: RegisterOptions<TransportFileFormData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return (
    <div className={"flex-col"}>
      <div className={"mt-1"}>
        <Input
          className={"w-full"}
          {...mkInputProps("name", {
            minLength: {
              value: 3,
              message: t(`errors.tooShort`, { minLength: "3" }),
            },
            maxLength: {
              value: 50,
              message: t(`errors.tooLong`, { maxLength: "50" }),
            },
            required: { value: true, message: t`errors.required` },
          })}
          testId={"project-name-input"}
        />
        {errors.name && <ErrorMessage errorMessage={errors.name.message} />}
      </div>
      <div className={"mt-1"}>
        <Input
          className={"w-full"}
          disabled={versionLoading}
          {...mkInputProps("code", {
            maxLength: {
              value: 12,
              message: t(`errors.tooLong`, { maxLength: "12" }),
            },
            required: { value: true, message: t`errors.required` },
            onBlur: (e) => setNextAvailableVersion(e.target.value),
          })}
          testId={"project-code-input"}
        />
        {errors.code && <ErrorMessage errorMessage={errors.code.message} />}
      </div>
      <div className={"mt-1"}>
        <Controller
          name={"version"}
          control={control}
          render={({ field: { value } }) => (
            <Input
              value={value}
              className={"w-full"}
              {...mkInputProps("version", {
                minLength: {
                  value: 1,
                  message: t(`errors.tooShort`, { minLength: "1" }),
                },
                maxLength: {
                  value: 3,
                  message: t(`errors.tooLong`, { maxLength: "3" }),
                },
                required: { value: true, message: t`errors.required` },
                validate: {
                  isNumber: (value) =>
                    isNumber(value) || t`errors.versionMustBeANumber`,
                },
              })}
              testId={"project-version-input"}
            />
          )}
        />
        {errors.version && (
          <>
            <ErrorMessage errorMessage={errors.version.message} />
          </>
        )}
        <Controller
          control={control}
          name={"currency"}
          rules={{
            validate: {
              currencyAllowed: (value) =>
                value
                  ? allowedCurrencies?.includes(value) ||
                    t("currencyNotAllowedMessage", {
                      fileCurrency: getValues("currency"),
                    })
                  : t("currencyNotSelectedMessage"),
            },
          }}
          render={({ field: { onChange, value } }) => {
            const isInitialValueAllowed = allowedCurrencies?.includes(value);
            const items = isInitialValueAllowed
              ? allowedCurrencies ?? []
              : ["", ...(allowedCurrencies ?? [])];

            return (
              <StringCombobox
                label={t`currency`}
                items={items}
                onValueChange={onChange}
                initialValue={value}
              />
            );
          }}
        />
        {errors.currency && (
          <ErrorMessage errorMessage={errors.currency.message} />
        )}
      </div>
      <FeatureFlagContainer feature={FeatureFlags.ADVANCED_IMPORT_MODAL}>
        <div className={"mt-1"}>
          <Controller
            control={control}
            name={"projectGroup"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`projectGroup`}
                items={projectGroups ?? []}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
        </div>
      </FeatureFlagContainer>
      <FeatureFlagContainer feature={FeatureFlags.ADVANCED_IMPORT_MODAL}>
        <div className={"mt-1"}>
          <Controller
            control={control}
            name={"classification"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`classification`}
                items={classifications}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
        </div>
      </FeatureFlagContainer>
    </div>
  );
}
