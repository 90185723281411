import { AxiosError } from "axios";
import { useMutation } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";
import { UsersCreationResponse } from "src/server-ts/organizations/OrganizationUserManagementRouter";
import { CreateUserParams } from "ts-bindings/CreateUserParams";
import { UserRoleAssignment } from "ts-bindings/UserRoleAssignment";

export function useCreateUser() {
  const setRoles = useSetUserRoles();
  return useMutation({
    mutationFn: async (params: CreateUserParams) => {
      const result = await axiosClient.post<UsersCreationResponse[]>(
        "/organization/management/users",
        params
      );

      if (params.roleAssignments) {
        for (const createdUser of result.data) {
          if (createdUser.success && createdUser.id) {
            await setRoles.mutateAsync({
              userId: createdUser.id,
              organization: params.organization,
              roleAssignments: params.roleAssignments,
            });
          }
        }
      }

      // TODO: handle failed users by showing a failure message to the user
      // and possibly show a success message for the created users
      return result;
    },
    onError: (error: AxiosError) => {
      reportError("Error while creating user", error);
    },
  });
}

type SetUserRoleParams = {
  userId: string;
  organization: string;
  roleAssignments: Array<UserRoleAssignment>;
};
export function useSetUserRoles() {
  return useMutation({
    mutationFn: (params: SetUserRoleParams) => {
      return axiosClient.post("/setUserRoles", params);
    },
    onError: (error: AxiosError) => {
      const data = error.response?.data;
      if (data && typeof data === "object" && "msg" in data) {
        const msg = (data as { msg: { type: string } }).msg;
        reportError(`Error while setting user roles, ${msg.type}`, error);
      }
      reportError("Error while setting user roles", error);
    },
  });
}

export function useInviteUserToSSO() {
  return useMutation({
    mutationFn: (id: string) => {
      return axiosClient.post("/organization/management/users/sso-invite", {
        id,
      });
    },
    onError: (error: AxiosError) => {
      reportError("Error while inviting user to SSO", error);
    },
  });
}
