export const IconHidden = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8413 17.1578C26.2136 21.3002 21.0796 23.6851 15.7573 23.6851C14.502 23.6851 13.2593 23.5471 12.0417 23.2835L14.8661 20.4591C15.1548 20.5093 15.4435 20.547 15.7448 20.547C18.5189 20.547 20.7658 18.3001 20.7658 15.526C20.7658 15.2247 20.7281 14.936 20.6779 14.6473L22.6989 12.6263C23.0755 13.5175 23.2763 14.4967 23.2763 15.526C23.2763 17.1704 22.7365 18.6892 21.8328 19.9319C24.1173 18.9779 26.2011 17.4967 27.9333 15.5009C26.6153 14.0071 25.0839 12.8021 23.4269 11.8857L25.2596 10.053C26.9417 11.0698 28.4856 12.3627 29.8288 13.8941C30.6447 14.823 30.6447 16.2289 29.8288 17.1578H29.8413ZM26.0504 5.73494L5.33866 26.4467L3.56874 24.6768L6.89518 21.3504C4.96208 20.2834 3.19216 18.8901 1.68585 17.1578C0.869934 16.2289 0.869934 14.823 1.68585 13.8941C5.31355 9.75177 10.4476 7.36678 15.7699 7.36678C17.3013 7.36678 18.8076 7.58017 20.2762 7.9693L24.2805 3.96503L26.0504 5.73494ZM9.12954 19.1035C8.55212 18.0365 8.22575 16.8189 8.22575 15.526C8.22575 13.8816 8.76551 12.3627 9.6693 11.12C7.38473 12.074 5.301 13.5552 3.56874 15.5511C5.0625 17.2457 6.81986 18.5637 8.72786 19.5177L9.14209 19.1035H9.12954ZM17.4142 10.8187C16.887 10.6305 16.3347 10.5175 15.7573 10.5175C12.9832 10.5175 10.7363 12.7644 10.7363 15.5385C10.7363 16.1285 10.8618 16.6808 11.0375 17.1955L17.4142 10.8187Z"
      fill="#3C4B4C"
    />
  </svg>
);
