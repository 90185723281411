// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddOrgUsersModal = require("../AddOrgUsersModal/index.js");
var Admin_Utils = require("../Admin.Utils/index.js");
var AllSet = require("../AllSet/index.js");
var AssignRolesModal = require("../AssignRolesModal/index.js");
var Box = require("../Box/index.js");
var ButtonWithConfirmation = require("../ButtonWithConfirmation/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var Roles = require("../Roles/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var ByEmail = (function () {
    function ByEmail() {

    };
    ByEmail.value = new ByEmail();
    return ByEmail;
})();
var ByApps = (function () {
    function ByApps() {

    };
    ByApps.value = new ByApps();
    return ByApps;
})();
var ByCreate = (function () {
    function ByCreate() {

    };
    ByCreate.value = new ByCreate();
    return ByCreate;
})();
var ByWrites = (function () {
    function ByWrites() {

    };
    ByWrites.value = new ByWrites();
    return ByWrites;
})();
var ByReads = (function () {
    function ByReads() {

    };
    ByReads.value = new ByReads();
    return ByReads;
})();
var EditUserModal = (function () {
    function EditUserModal(value0) {
        this.value0 = value0;
    };
    EditUserModal.create = function (value0) {
        return new EditUserModal(value0);
    };
    return EditUserModal;
})();
var AddUserModal = (function () {
    function AddUserModal() {

    };
    AddUserModal.value = new AddUserModal();
    return AddUserModal;
})();
var NoModal = (function () {
    function NoModal() {

    };
    NoModal.value = new NoModal();
    return NoModal;
})();
var organizationUsersTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var getSortingValue = function (v) {
    return function (v1) {
        if (v instanceof ByEmail) {
            return new TacoTable_Types.StringValue(v1.user.email);
        };
        if (v instanceof ByApps) {
            var appsValue = (function () {
                var v2 = Data_Either.isRight(Roles.usingCostEstimationAllowed(v1.userCapabilities));
                var v3 = Data_Either.isRight(Roles.usingCostControlAllowed(v1.userCapabilities));
                if (v3 && v2) {
                    return -2 | 0;
                };
                if (!v3 && !v2) {
                    return 0;
                };
                return -1 | 0;
            })();
            return TacoTable_Types.IntValue.create(appsValue);
        };
        if (v instanceof ByCreate) {
            return TacoTable_Types.BooleanValue.create(Data_Either.isRight(Roles.projectCreationAllowed(v1.userCapabilities)));
        };
        if (v instanceof ByWrites) {
            return TacoTable_Types.BooleanValue.create(Data_Either.isRight(Roles.ccWriteAllProjectsAllowed(v1.userCapabilities)) || Data_Either.isRight(Roles.ceWriteAllProjectsAllowed(v1.userCapabilities)));
        };
        if (v instanceof ByReads) {
            return TacoTable_Types.BooleanValue.create(Data_Either.isRight(Roles.ccReadAllProjectsAllowed(v1.userCapabilities)) || Data_Either.isRight(Roles.ceReadAllProjectsAllowed(v1.userCapabilities)));
        };
        throw new Error("Failed pattern match at OrganizationUsersTable (line 258, column 1 - line 258, column 82): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getNameOrganizationUsersSortProperty = {
    getName: function (v) {
        return "OrganizationUsersSortProperty";
    }
};
var organizationUsersTableInstance = TacoTable.mkTableSortable(getNameOrganizationUsersSortProperty)("OrganizationUsers");
var eqOrganizationUsersSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return true;
            };
            if (x instanceof ByApps && y instanceof ByApps) {
                return true;
            };
            if (x instanceof ByCreate && y instanceof ByCreate) {
                return true;
            };
            if (x instanceof ByWrites && y instanceof ByWrites) {
                return true;
            };
            if (x instanceof ByReads && y instanceof ByReads) {
                return true;
            };
            return false;
        };
    }
};
var ordOrganizationUsersSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEmail) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEmail) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByApps && y instanceof ByApps) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByApps) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByApps) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreate && y instanceof ByCreate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByWrites && y instanceof ByWrites) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByWrites) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByWrites) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByReads && y instanceof ByReads) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at OrganizationUsersTable (line 248, column 1 - line 248, column 86): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqOrganizationUsersSortProperty;
    }
};
var component = TofuHooks.mkHookComponent("OrganizationUsersTable")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(NoModal.value)();
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var selectionCheckbox = function (v2) {
            return TacoCheckbox.component()()({
                className: "user-selection-checkbox",
                isChecked: v2.isSelected,
                onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (current) {
                    return Data_Set.toggle(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                        reflectSymbol: function () {
                            return "userId";
                        }
                    })(Types_Organization.ordUserId))()({
                        reflectSymbol: function () {
                            return "user";
                        }
                    })(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                        reflectSymbol: function () {
                            return "superAdmin";
                        }
                    })(Data_Ord.ordBoolean))()({
                        reflectSymbol: function () {
                            return "orgPlan";
                        }
                    })(Types_Organization.ordPlan))()({
                        reflectSymbol: function () {
                            return "org";
                        }
                    })(Types_Organization.ordOrganization))()({
                        reflectSymbol: function () {
                            return "hasSSOLogin";
                        }
                    })(Data_Ord.ordBoolean))()({
                        reflectSymbol: function () {
                            return "email";
                        }
                    })(Data_Ord.ordString))()({
                        reflectSymbol: function () {
                            return "admicomSub";
                        }
                    })(Data_Maybe.ordMaybe(Data_Ord.ordString))))))({
                        user: v2.user,
                        userId: v2.userId
                    })(current);
                })),
                labelText: Data_Maybe.Nothing.value,
                testId: "select-user-" + Data_Newtype.unwrap()(v2.userId)
            });
        };
        var roleMap = Data_Map_Internal.fromFoldable(Roles_Types.ordUserRoleId)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (r) {
            return new Data_Tuple.Tuple(r.id, r.role);
        })(props.roles));
        var roleAssignmentsWithRoles = (function () {
            var addRole = function (ra) {
                var v2 = Data_Map_Internal.lookup(Roles_Types.ordUserRoleId)(ra.roleId)(roleMap);
                if (v2 instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(Record.insert({
                        reflectSymbol: function () {
                            return "role";
                        }
                    })()()(Data_Symbol.SProxy.value)(v2.value0)(ra));
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at OrganizationUsersTable (line 69, column 11 - line 71, column 31): " + [ v2.constructor.name ]);
            };
            return Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(addRole)(props.roleAssignments));
        })();
        var roleAssignmentsMap = Data_Functor.map(Data_Map_Internal.functorMap)(Data_Functor.map(Data_Functor.functorArray)(Record["delete"]({
            reflectSymbol: function () {
                return "userId";
            }
        })()()(Data_Symbol.SProxy.value)))(Utils.groupMap(Types_Organization.eqUserId)(Types_Organization.ordUserId)(function (v2) {
            return v2.userId;
        })(roleAssignmentsWithRoles));
        var modal = (function () {
            if (v.value0 instanceof NoModal) {
                return React_Basic.empty;
            };
            if (v.value0 instanceof EditUserModal) {
                return AssignRolesModal.component({
                    onClose: v.value1(function (v2) {
                        return NoModal.value;
                    }),
                    organization: props.organization,
                    userId: v.value0.value0.userId,
                    user: v.value0.value0.user,
                    userRoles: Data_Maybe.fromMaybe([  ])(Data_Map_Internal.lookup(Types_Organization.ordUserId)(v.value0.value0.userId)(roleAssignmentsMap)),
                    orgRoles: props.roles,
                    dispatch: props.dispatch,
                    organizationAdminInfo: props.organizationAdminInfo
                });
            };
            if (v.value0 instanceof AddUserModal) {
                return AddOrgUsersModal.component({
                    appDispatch: props.dispatch,
                    organization: props.organization,
                    onClose: v.value1(function (v2) {
                        return NoModal.value;
                    })
                });
            };
            throw new Error("Failed pattern match at OrganizationUsersTable (line 76, column 7 - line 93, column 12): " + [ v.value0.constructor.name ]);
        })();
        var mkOrgUser = function (user) {
            return {
                userId: user.id,
                user: user.userData,
                userCapabilities: user.userCapabilities,
                isSelected: Data_Set.member(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "userId";
                    }
                })(Types_Organization.ordUserId))()({
                    reflectSymbol: function () {
                        return "user";
                    }
                })(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "superAdmin";
                    }
                })(Data_Ord.ordBoolean))()({
                    reflectSymbol: function () {
                        return "orgPlan";
                    }
                })(Types_Organization.ordPlan))()({
                    reflectSymbol: function () {
                        return "org";
                    }
                })(Types_Organization.ordOrganization))()({
                    reflectSymbol: function () {
                        return "hasSSOLogin";
                    }
                })(Data_Ord.ordBoolean))()({
                    reflectSymbol: function () {
                        return "email";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "admicomSub";
                    }
                })(Data_Maybe.ordMaybe(Data_Ord.ordString))))))({
                    userId: user.id,
                    user: user.userData
                })(v1.value0)
            };
        };
        var orgUsers = Data_Functor.map(Data_Functor.functorArray)(mkOrgUser)(props.users);
        var editButton = function (orgUser) {
            return TacoButton.component()()({
                text: "Muokkaa",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                    return new EditUserModal(orgUser);
                }))
            });
        };
        var deleteUsersButton = (function () {
            var userEmails = Data_Array.intercalate(Data_Monoid.monoidString)(", ")(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                return v2.user.email;
            })(Data_Array.fromFoldable(Data_Set.foldableSet)(v1.value0)));
            var userCount = Data_Set.size(v1.value0);
            return ButtonWithConfirmation.component()()({
                buttonText: "Poista valitut k\xe4ytt\xe4j\xe4t",
                confirmHeadingText: "Haluatkok varmasti poistaa " + (Data_Show.show(Data_Show.showInt)(userCount) + " k\xe4ytt\xe4j\xe4\xe4?"),
                confirmBody: React_Basic_DOM.text("Olet poistamassa seuraavat k\xe4ytt\xe4j\xe4t:\x0a" + userEmails),
                confirmButtonText: "Poista k\xe4ytt\xe4j\xe4t",
                onAction: function __do() {
                    props.dispatch(new Actions.OrgRemoveUsersRequest({
                        userIds: Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                            return v2.userId;
                        })(Data_Array.fromFoldable(Data_Set.foldableSet)(v1.value0))
                    }))();
                    return v1.value1(function (v2) {
                        return Data_Set.empty;
                    })();
                },
                disabled: Data_Set.isEmpty(v1.value0)
            });
        })();
        var columns = [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(selectionCheckbox),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "email",
            label: React_Basic_DOM.text("S\xe4hk\xf6posti"),
            cell: new TacoTable_Types.PlainTextCell(function (v2) {
                return v2.user.email;
            }),
            sortProperty: new Data_Maybe.Just(ByEmail.value),
            width: new TacoTable_Types.Flex(15),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "apps",
            label: React_Basic_DOM.text("Sovellukset"),
            cell: TacoTable_Types.JSXCell.create([  ])(function ($68) {
                return Admin_Utils.appChips((function (v2) {
                    return v2.userCapabilities;
                })($68));
            }),
            sortProperty: new Data_Maybe.Just(ByApps.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "create",
            label: React_Basic_DOM.text("Hankkeen luontioikeus"),
            cell: TacoTable_Types.PlainTextCell.create(function ($69) {
                return Admin_Utils.createProjectsCell((function (v2) {
                    return v2.userCapabilities;
                })($69));
            }),
            sortProperty: new Data_Maybe.Just(ByCreate.value),
            width: new TacoTable_Types.Flex(5),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "readable_projects",
            label: React_Basic_DOM.text("Lukuoikeus"),
            cell: TacoTable_Types.JSXCell.create([  ])(function ($70) {
                return Admin_Utils.readableProjectsCell((function (v2) {
                    return v2.userCapabilities;
                })($70));
            }),
            sortProperty: new Data_Maybe.Just(ByReads.value),
            width: new TacoTable_Types.Flex(7),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "writable_projects",
            label: React_Basic_DOM.text("Muokkausoikeus"),
            cell: TacoTable_Types.JSXCell.create([  ])(function ($71) {
                return Admin_Utils.writableProjectsCell((function (v2) {
                    return v2.userCapabilities;
                })($71));
            }),
            sortProperty: new Data_Maybe.Just(ByWrites.value),
            width: new TacoTable_Types.Flex(7),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "edit-roles",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], editButton),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(5),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var mkUserRow = function (v2) {
            return {
                rowData: v2,
                rowKey: Data_Newtype.un()(Types_Organization.UserId)(v2.userId),
                onClick: function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "user-row",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        var table = TacoTable.tableSortable(getNameOrganizationUsersSortProperty)(eqOrganizationUsersSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "userId";
            }
        })(Types_Organization.eqUserId))()({
            reflectSymbol: function () {
                return "userCapabilities";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "isAdmin";
            }
        })(Roles_Types.isOrganizationAdminEq))()({
            reflectSymbol: function () {
                return "createProjectsPermission";
            }
        })(Roles_Types.createProjectsPermissionEq))()({
            reflectSymbol: function () {
                return "costGroupTargetWritePermission";
            }
        })(Roles_Types.writeCostGroupTargetPermissionEq))()({
            reflectSymbol: function () {
                return "costEstimationPermission";
            }
        })(Roles_Types.costEstimationPermissionEq))()({
            reflectSymbol: function () {
                return "costControlPermission";
            }
        })(Roles_Types.costControlPermissionEq))()({
            reflectSymbol: function () {
                return "ceWritableProjects";
            }
        })(AllSet.eqAllSet(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "ceReadableProjects";
            }
        })(AllSet.eqAllSet(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "ccWritableProjects";
            }
        })(AllSet.eqAllSet(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "ccReadableProjects";
            }
        })(AllSet.eqAllSet(Types_Project.eqProjectId)))))()({
            reflectSymbol: function () {
                return "user";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "superAdmin";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "orgPlan";
            }
        })(Types_Organization.eqPlan))()({
            reflectSymbol: function () {
                return "org";
            }
        })(Types_Organization.eqOrganization))()({
            reflectSymbol: function () {
                return "hasSSOLogin";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "email";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "admicomSub";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqString)))))()({
            reflectSymbol: function () {
                return "isSelected";
            }
        })(Data_Eq.eqBoolean)))(organizationUsersTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByEmail.value), getSortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkUserRow)(orgUsers),
            columns: columns,
            showHeader: true,
            styleSet: organizationUsersTableStyleSet
        });
        var addUserButton = TacoButton.component()()({
            text: "Lis\xe4\xe4 k\xe4ytt\xe4ji\xe4",
            buttonStyle: TacoButton_Types.Outlined.value,
            onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                return AddUserModal.value;
            }))
        });
        var userControls = Box.box("userControls")([ Box.JustifySpaceBetween.value ])([ addUserButton, deleteUsersButton ]);
        TofuHooks.useEffect([ ReactHooksUtils.utf(props.users) ])((function () {
            if (v.value0 instanceof EditUserModal) {
                var updated = Data_Array.find((function () {
                    var $72 = Data_Eq.eq(Types_Organization.eqUserId)(v.value0.value0.userId);
                    return function ($73) {
                        return $72((function (v2) {
                            return v2.id;
                        })($73));
                    };
                })())(props.users);
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(updated)(function (user) {
                    return v.value1(function (v2) {
                        return EditUserModal.create(mkOrgUser(user));
                    });
                }));
            };
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)));
        })())();
        return React_Basic_DOM_Generated.div()({
            className: "__OrganizationUsers",
            children: [ TacoText.component()()({
                text: "K\xe4ytt\xe4j\xe4t",
                variant: new TacoText_Types.Heading(2),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), userControls, table, modal ]
        });
    };
});
module.exports = {
    component: component
};
