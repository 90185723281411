/* eslint-disable i18next/no-literal-string */
import { useEffect, useState } from "react";
// @ts-ignore: Missing typings
import { oAuthLogin as cognitoLogin } from "../../client/auth/Auth.js";
import { getOidcUserManager } from "./oidc";

export enum AuthProvider {
  KEYCLOAK = "KEYCLOAK",
  COGNITO = "COGNITO",
}

export const AuthRedirect = () => {
  const [error, setError] = useState<string | null>(null);

  const provider = window.location.pathname.includes("kc")
    ? AuthProvider.KEYCLOAK
    : AuthProvider.COGNITO;

  useEffect(() => {
    const handleAuth = async () => {
      if (provider === AuthProvider.KEYCLOAK) {
        const oidcUserManager = getOidcUserManager();
        await oidcUserManager.signinCallback();
      } else if (provider === AuthProvider.COGNITO) {
        await cognitoLogin();
      }
    };

    handleAuth()
      .then(() => {
        window.location.href = "/";
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      });
  }, []);

  // TODO: This component is not currently within the i18n context. Fix after getting rid of the cognito auth and
  // refactoring the auth flow.
  return error ? (
    <span className="flex h-screen w-screen items-center justify-center text-lg">
      Kirjautuminen epäonnistui. Ota yhteyttä tukipalveluun.
    </span>
  ) : (
    <span className="flex h-screen w-screen items-center justify-center text-lg">
      Kirjaudutaan sisään. Odota hetki.
    </span>
  );
};
