// eslint-disable-next-line no-restricted-imports
import axios from "axios";
import { getClientConfigVarOptional } from "src/client/config";
import { getAccessToken } from "./auth/accessToken";
import { removeUser } from "./auth/oidc";

/**
 * This is a base URL to be used in unit tests.
 */
export const testBaseURL = "http://localhost/api";

/**
 * This base URL is used in production and development.
 *
 * The /api endpoint is in the same domain as the frontend and needs to be
 * proxied to the backend for this to work.
 */
const proxiedBaseURL = "/api";

const baseURL = process.env.NODE_ENV === "test" ? testBaseURL : proxiedBaseURL;
const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(async (config) => {
  if (process.env.NODE_ENV === "test") {
    return config;
  }

  config.headers.Authorization = await getAccessToken();

  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      console.warn("Invalid token, removing user and redirecting to login");

      await removeUser();
      window.location.href = "/";
    } else if (
      error.response.status === 403 &&
      error.response.data.error === "COGNITO_AUTH_LOCKED" &&
      getClientConfigVarOptional("CLIENT_REDIRECT_COGNITO_USERS") === "true"
    ) {
      console.warn("Redirecting to Admicom SSO login");
      window.location.href = "/sso-redirect";
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
