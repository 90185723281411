import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnPivotModeChangedEvent,
  ColumnState,
  GridApi,
  GridColumnsChangedEvent,
  IAggFunc,
  ModelUpdatedEvent,
} from "ag-grid-community";

export type ApplyColState = {
  colId: string;
  aggFunc: string | IAggFunc | null;
  hide: boolean;
};

type ToolbarCol = {
  field: string;
};

type ToolbarGroup = {
  headerName: string;
  children?: {
    field?: string;
  }[];
};
export const handlePivotChange = (
  event: ColumnPivotModeChangedEvent | ModelUpdatedEvent
) => {
  const api = event.api;

  const pivot = api.getGridOption("pivotMode");

  const columns = api.getColumns() ?? [];
  const columnsStates = api.getColumnState();

  if (pivot) {
    applyPivotColumns(columns, columnsStates, api);
  } else {
    applyNormalColumns(columns, columnsStates, api);
  }
};

export const applyPivotColumns = (
  columns: Column[],
  columnsStates: ColumnState[],
  api: GridApi
) => {
  const pivotColumns = columnsStates?.map((col) => {
    const colId = col.colId;
    const column = api.getColumn(colId);
    const colDef = column?.getColDef();
    const initialAggFunc = colDef?.initialAggFunc ?? null;
    const visible = !col.hide;
    if (visible) {
      return { colId: colId, aggFunc: initialAggFunc, hide: false };
    }
    return { colId: colId, aggFunc: null, hide: true };
  });

  api.applyColumnState({
    state: pivotColumns,
  });

  const columnsWithAggFunc = formatToolpanelColumns(columns, true, api);

  api.getToolPanelInstance("columns")?.setColumnLayout(columnsWithAggFunc);
};
export const applyNormalColumns = (
  columns: Column[],
  columnsStates: ColumnState[],
  api: GridApi
) => {
  const normalColumns = columnsStates?.map((col) => {
    const column = api.getColumn(col.colId);
    const colDef = column?.getColDef();
    const initialAggFunc = colDef?.initialAggFunc ?? null;
    return {
      colId: col.colId,
      aggFunc: initialAggFunc,
      hide: col.hide,
    };
  });
  api.applyColumnState({
    state: normalColumns,
  });

  const originalColumns = formatToolpanelColumns(columns, false, api);
  api.getToolPanelInstance("columns")?.setColumnLayout(originalColumns);
};

function makeVisibilityChanges(event: GridColumnsChangedEvent) {
  const pivotMode = event.api.getGridOption("pivotMode");
  if (pivotMode && event.api.isToolPanelShowing()) {
    const displayedColumns = event.api.getAllDisplayedColumns();
    const allColumns = event.api.getColumns();

    const shownColumns: ApplyColState[] =
      allColumns
        ?.filter((col) => {
          const isVisible = displayedColumns.includes(col);
          return !col.isVisible() && isVisible;
        })
        .map((col) => {
          const colDef = col.getColDef();
          const initialAggFunc = colDef.initialAggFunc ?? null;
          return {
            colId: col.getColId(),
            aggFunc: initialAggFunc,
            hide: false,
          };
        }) ?? [];

    const hiddenColumns: ApplyColState[] =
      allColumns
        ?.filter((col) => {
          const isVisible = displayedColumns.includes(col);
          return col.isVisible() && !isVisible;
        })
        .map((col) => {
          return { colId: col.getColId(), aggFunc: null, hide: true };
        }) ?? [];

    const editedColumns = shownColumns.concat(hiddenColumns);
    if (editedColumns && editedColumns?.length > 0) {
      event.api.applyColumnState({
        state: editedColumns,
      });
    }
  }
}

export const handleVisibilityChange = (event: GridColumnsChangedEvent) => {
  Promise.resolve().then(() => {
    makeVisibilityChanges(event);
  });
};

export const formatToolpanelColumns = (
  columns: Column[],
  pivot: boolean,
  api: GridApi
) => {
  const defs: (ToolbarCol | ToolbarGroup)[] = getColumnDefs(columns, api);

  const noDuplicateDefs = removeDuplicateDefs(defs);

  const pivotColumns = toolBarPivotColumns(noDuplicateDefs, api);
  return (pivot ? pivotColumns : noDuplicateDefs) as (ColDef | ColGroupDef)[];
};

export function getColumnDefs(columns: Column[], api: GridApi) {
  return columns
    ?.map((col) => {
      const parent = col?.getOriginalParent();
      const colId = col?.getColId();
      const groupId = parent?.getGroupId();
      return {
        colId: colId,
        groupId: groupId,
      };
    })
    .map((col) => {
      if (col.groupId) {
        const providedGroup = api.getProvidedColumnGroup(col.groupId);
        const groupDef = providedGroup?.getColGroupDef();
        const groupChildren = providedGroup?.getChildren();
        const headerName = groupDef?.headerName;
        const childArray = groupChildren?.map((child) => {
          const colDef = columns.find((column) => {
            const def = column.getColDef();
            return def.field
              ? def.field === child.getId()
              : def.colId === child.getId();
          });
          return { field: colDef?.getColId() };
        });
        if (headerName) {
          return {
            headerName: headerName,
            children: childArray,
          };
        }
        return { field: col.colId };
      }
      return { field: col.colId };
    });
}
export function removeDuplicateDefs(defs: (ToolbarCol | ToolbarGroup)[]) {
  return defs.filter((value, index, self) => {
    const def = Object(value);
    const colId = def.field ?? def.headerName;
    return (
      index ===
      self.findIndex((t) => {
        const tDef = Object(t);
        const tColId = tDef.field ?? tDef.headerName;
        return tColId === colId;
      })
    );
  }, []);
}
export function toolBarPivotColumns(
  noDuplicateDefs: (ToolbarCol | ToolbarGroup)[],
  api: GridApi
) {
  return noDuplicateDefs
    .map((def) => {
      const colToolbarDef = Object(def);
      const childs = colToolbarDef.children?.filter((child: ToolbarCol) => {
        const childId = child.field;
        const childCol = api.getColumn(childId);
        const childColDef = childCol?.getColDef();
        return childColDef?.initialAggFunc;
      });
      if (colToolbarDef.field) {
        const colId = colToolbarDef.field;
        const col = api.getColumn(colId);
        const cDef = col?.getColDef();
        if (cDef?.initialAggFunc) {
          return def;
        }
      }
      if (colToolbarDef.children) {
        return { headerName: colToolbarDef.headerName, children: childs };
      }
    })
    .filter((def) => def);
}

function makePivotChanges(
  event: ColumnPivotModeChangedEvent | ModelUpdatedEvent
) {
  return new Promise<void>((resolve) => {
    if (!event.api.isToolPanelShowing()) {
      handlePivotChange(event);
    }
    resolve();
  });
}
function addGridEventListener(
  event: ColumnPivotModeChangedEvent | ModelUpdatedEvent
) {
  event.api.addEventListener("gridColumnsChanged", handleVisibilityChange);
}

export const handleModelChange = (
  event: ColumnPivotModeChangedEvent | ModelUpdatedEvent
) => {
  event.api.removeEventListener("gridColumnsChanged", handleVisibilityChange);
  makePivotChanges(event).then(() => {
    addGridEventListener(event);
  });
};
