import { Button, Modal } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import { ReportTemplate } from "@prisma/client";
import { useDeleteReportTemplate } from "../hooks/useReportTemplates";

type ConfirmDeleteReportTemplateModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  template?: ReportTemplate;
};
export const ConfirmDeleteReportTemplateModal = ({
  isOpen,
  closeModal,
  template,
}: ConfirmDeleteReportTemplateModalProps) => {
  const { t } = useTranslation("superAdmin", { keyPrefix: "reportDesigner" });

  const deleteReportTemplate = useDeleteReportTemplate();

  const confirmDelete = (template: ReportTemplate) => {
    deleteReportTemplate.mutate(template.id);
  };

  const confirmTitle = t`deleteTemplateDialog.title`;

  const confirmActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <Button
          id="reportDesigner-cancel-template-deletion-button"
          color="danger"
          variant="text"
          size="large"
          onClick={closeModal}
        >
          {t`deleteTemplateDialog.cancel`}
        </Button>
        <Button
          id="reportDesigner-confirm-template-deletion-button"
          color="danger"
          size="large"
          onClick={() => {
            template && confirmDelete(template);
            closeModal();
          }}
        >
          {t`deleteTemplateDialog.confirmDelete`}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={confirmTitle}
      actions={confirmActions}
    >
      <p>
        <Trans
          ns="superAdmin"
          i18nKey="reportDesigner.deleteTemplateDialog.description"
          values={{ templateName: template?.name }}
        />
      </p>
    </Modal>
  );
};
