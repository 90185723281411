import { ColDef, StatusPanelDef, ValueSetterParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "src/client-ts/components/ErrorMessage";
import { Table } from "src/client-ts/components/Table";
import { ComponentGroupCreateForm } from "./ComponentGroupCreateForm";
import { DeleteSelectedGroupsButton } from "./DeleteSelectedGroupsButton";
import {
  ComponentGroup,
  useComponentGroupMutation,
  useComponentGroupsQuery,
} from "./useComponentGroups";

type GroupsEditProps = {
  projectId: number;
  groupsEdited: () => void;
};
export function ComponentGroupsEdit({
  projectId,
  groupsEdited,
}: GroupsEditProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });
  const { data: groups } = useComponentGroupsQuery(projectId);
  const { mutate: updateGroup } = useComponentGroupMutation<
    "UpdateClassificationGroup"
  >(projectId);

  const gridRef = useRef<AgGridReact<ComponentGroup>>(null);

  const [gridEditError, setGridEditError] = useState<string>("");

  const handleUpdate = (data: ComponentGroup) => {
    updateGroup({
      type: "UpdateClassificationGroup",
      value: data,
    });
    groupsEdited();
  };

  const validLength = (value: string, allowedLength: number) =>
    value.length < allowedLength;
  const validateFieldInput = (newValue: string, field: string): boolean => {
    if (newValue === "" || !newValue) {
      setGridEditError(t`errors.required`);
      return false;
    }
    const fieldMaxLength = field === "code" ? 20 : 50;
    const fieldValid = validLength(newValue, fieldMaxLength);
    if (fieldValid) {
      return true;
    }
    setGridEditError(
      t(`errors.maxLength`, {
        maxLength: fieldMaxLength,
        field:
          field === "code"
            ? `${t`codeLowerCase`}`
            : `${t`descriptionLowerCase`}`,
      })
    );
    return false;
  };

  const setField = (params: ValueSetterParams<ComponentGroup>) => {
    const { data, column, newValue } = params;
    if (!data) {
      return false;
    }

    const colId = column.getColId();
    if (!validateFieldInput(newValue, colId)) {
      return false;
    }

    handleUpdate({
      code: colId === "code" ? params.newValue : data.code,
      description: colId === "description" ? params.newValue : data.description,
      memo: data.memo,
      projectId: data.projectId,
      id: data.id,
    });
    //@ts-ignore
    data[params.column.getColId()] = newValue;

    setGridEditError("");
    return true;
  };
  const columnDefs: ColDef<ComponentGroup>[] = [
    {
      headerCheckboxSelectionFilteredOnly: true,
      flex: 0.15,
      filter: false,
    },
    {
      field: "code",
      headerName: t`code`,
      flex: 0.5,
      valueSetter: setField,
      editable: true,
      type: ["editable"],
    },
    {
      field: "description",
      headerName: t`description`,
      flex: 1,
      valueSetter: setField,
      editable: true,
      type: ["editable"],
    },
  ];

  const defaultColDef: ColDef<ComponentGroup> = {
    floatingFilter: true,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
  };

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: DeleteSelectedGroupsButton,
          statusPanelParams: {
            projectId,
            groupsEdited,
          },
        },
      ],
    };
  }, []);

  return (
    <>
      <div className={"h-[500px]"}>
        <Table<ComponentGroup>
          className={"h-[500px]"}
          rowData={groups}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridRef={gridRef}
          rowSelection={{ mode: "multiRow", checkboxes: true }}
          suppressRowClickSelection={true}
          getRowId={({ data: componentGroup }) => componentGroup.id.toString()}
          statusBar={statusBar}
        />
      </div>
      <ComponentGroupCreateForm
        projectId={projectId}
        toggleGroupsEdited={groupsEdited}
      />
      <ErrorMessage errorMessage={gridEditError} />
    </>
  );
}
