// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var ReportingComponentId = function (x) {
    return x;
};
var OrgPriceListId = function (x) {
    return x;
};
var EstimationResourceId = function (x) {
    return x;
};
var EstimationLocationId = function (x) {
    return x;
};
var RegularComponent = (function () {
    function RegularComponent() {

    };
    RegularComponent.value = new RegularComponent();
    return RegularComponent;
})();
var TopicComponent = (function () {
    function TopicComponent() {

    };
    TopicComponent.value = new TopicComponent();
    return TopicComponent;
})();
var UnknownComponent = (function () {
    function UnknownComponent(value0) {
        this.value0 = value0;
    };
    UnknownComponent.create = function (value0) {
        return new UnknownComponent(value0);
    };
    return UnknownComponent;
})();
var ComponentId = function (x) {
    return x;
};
var ReportingComponent = function (x) {
    return x;
};
var writeParamReportingComponentId = ReadParam.writeParamInt;
var writeParamOrgEstimationResourceId = ReadParam.writeParamString;
var writeParamEstimationResourceId = ReadParam.writeParamInt;
var writeParamEstimationLocationId = ReadParam.writeParamInt;
var writeParamComponentId = ReadParam.writeParamInt;
var tofuJSONReportingComponentId = TofuJSON.readInt;
var tofuJSONOrgEstimationResourceId = TofuJSON.readString;
var tofuJSONEstimationResourceId = TofuJSON.readInt;
var tofuJSONEstimationLocationId = TofuJSON.readInt;
var tofuJSONComponentId = TofuJSON.readInt;
var tofuJSONReportingComponent = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readMaybe(tofuJSONComponentId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONReportingComponentId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readMaybe(tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONReportingComponentId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()());
var showReportingComponentId = Data_Show.showInt;
var showOrgEstimationResourceId = Data_Show.showString;
var showEstimationResourceId = Data_Show.showInt;
var showEstimationLocationId = Data_Show.showInt;
var showComponentType = {
    show: function (v) {
        if (v instanceof TopicComponent) {
            return "TopicComponentType";
        };
        if (v instanceof RegularComponent) {
            return "RegularComponentType";
        };
        if (v instanceof UnknownComponent) {
            return "UnknownComponentType (" + (Data_Show.show(Data_Show.showInt)(v.value0) + ")");
        };
        throw new Error("Failed pattern match at Types.Component (line 407, column 1 - line 410, column 74): " + [ v.constructor.name ]);
    }
};
var showComponentId = Data_Show.showInt;
var readParamReportingComponentId = ReadParam.intReadParam;
var readParamOrgEstimationResourceId = ReadParam.stringReadParam;
var readParamEstimationResourceId = ReadParam.intReadParam;
var readParamEstimationLocationId = ReadParam.intReadParam;
var readParamComponentId = ReadParam.intReadParam;
var ordReportingComponentId = Data_Ord.ordInt;
var ordOrgEstimationResourceId = Data_Ord.ordString;
var ordEstimationResourceId = Data_Ord.ordInt;
var ordEstimationLocationId = Data_Ord.ordInt;
var ordComponentId = Data_Ord.ordInt;
var newtypeReportingComponentId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeReportingComponent = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeOrgEstimationResourceId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEstimationResourceId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEstimationLocationId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeComponentId = {
    Coercible0: function () {
        return undefined;
    }
};
var intToComponentType = function (v) {
    if (v === 0) {
        return RegularComponent.value;
    };
    if (v === 3) {
        return TopicComponent.value;
    };
    return new UnknownComponent(v);
};
var genericComponentType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return RegularComponent.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return TopicComponent.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new UnknownComponent(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Component (line 400, column 1 - line 400, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof RegularComponent) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof TopicComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof UnknownComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Component (line 400, column 1 - line 400, column 64): " + [ x.constructor.name ]);
    }
};
var tofuJSONComponentType = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })(TofuJSON.readInt)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })(TofuJSON.readInt)()(TofuJSON.nilWriteForeignVariant))))(genericComponentType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    }))({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericComponentType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    }))({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })(TofuJSON.readInt)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RegularComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TopicComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UnknownComponent";
        }
    })(TofuJSON.readInt)()(TofuJSON.nilWriteForeignVariant))))
};
var eqReportingComponentId = Data_Eq.eqInt;
var eqOrgEstimationResourceId = Data_Eq.eqString;
var eqEstimationResourceId = Data_Eq.eqInt;
var eqEstimationLocationId = Data_Eq.eqInt;
var eqComponentType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof RegularComponent && y instanceof RegularComponent) {
                return true;
            };
            if (x instanceof TopicComponent && y instanceof TopicComponent) {
                return true;
            };
            if (x instanceof UnknownComponent && y instanceof UnknownComponent) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var ordComponentType = {
    compare: function (x) {
        return function (y) {
            if (x instanceof RegularComponent && y instanceof RegularComponent) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof RegularComponent) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof RegularComponent) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TopicComponent && y instanceof TopicComponent) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TopicComponent) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TopicComponent) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UnknownComponent && y instanceof UnknownComponent) {
                return Data_Ord.compare(Data_Ord.ordInt)(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at Types.Component (line 399, column 1 - line 399, column 54): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqComponentType;
    }
};
var eqComponentId = Data_Eq.eqInt;
var eqReportingComponent = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqNumber))(x.amount)(y.amount) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.code)(y.code) && Data_Eq.eq(Data_Maybe.eqMaybe(eqComponentId))(x.componentId)(y.componentId) && x.description === y.description && Data_Eq.eq(eqReportingComponentId)(x.id)(y.id) && x.subprojectCode === y.subprojectCode && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.unit)(y.unit);
        };
    }
};
var ordReportingComponent = {
    compare: function (x) {
        return function (y) {
            var v = Data_Ord.compare(Data_Maybe.ordMaybe(Data_Ord.ordNumber))(x.amount)(y.amount);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v1 = Data_Ord.compare(Data_Maybe.ordMaybe(Data_Ord.ordString))(x.code)(y.code);
            if (v1 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v1 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v2 = Data_Ord.compare(Data_Maybe.ordMaybe(ordComponentId))(x.componentId)(y.componentId);
            if (v2 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v2 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v3 = Data_Ord.compare(Data_Ord.ordString)(x.description)(y.description);
            if (v3 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v3 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v4 = Data_Ord.compare(ordReportingComponentId)(x.id)(y.id);
            if (v4 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v4 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v5 = Data_Ord.compare(Data_Ord.ordString)(x.subprojectCode)(y.subprojectCode);
            if (v5 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v5 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Maybe.ordMaybe(Data_Ord.ordString))(x.unit)(y.unit);
        };
    },
    Eq0: function () {
        return eqReportingComponent;
    }
};
var componentTypeToInt = function (v) {
    if (v instanceof RegularComponent) {
        return 0;
    };
    if (v instanceof TopicComponent) {
        return 3;
    };
    if (v instanceof UnknownComponent) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Types.Component (line 36, column 22 - line 39, column 26): " + [ v.constructor.name ]);
};
var amountSourceIntToLabel = function (n) {
    if (n === 0) {
        return "M\xe4\xe4r\xe4";
    };
    if (n === 1) {
        return "M\xe4\xe4r\xe4laskenta";
    };
    if (n === 2) {
        return "Kaava";
    };
    if (n === 3) {
        return "Menekki";
    };
    return "???";
};
var amountSourceIntToDescription = function (n) {
    if (n === 0) {
        return "Sy\xf6tt\xf6";
    };
    if (n === 1) {
        return "M\xe4\xe4r\xe4laskenta";
    };
    if (n === 2) {
        return "Kaava";
    };
    if (n === 3) {
        return "Menekki";
    };
    if (n === 4) {
        return "BIM";
    };
    return "-";
};
module.exports = {
    ReportingComponentId: ReportingComponentId,
    ComponentId: ComponentId,
    EstimationResourceId: EstimationResourceId,
    OrgPriceListId: OrgPriceListId,
    EstimationLocationId: EstimationLocationId,
    RegularComponent: RegularComponent,
    TopicComponent: TopicComponent,
    UnknownComponent: UnknownComponent,
    componentTypeToInt: componentTypeToInt,
    intToComponentType: intToComponentType,
    ReportingComponent: ReportingComponent,
    amountSourceIntToDescription: amountSourceIntToDescription,
    amountSourceIntToLabel: amountSourceIntToLabel,
    eqReportingComponent: eqReportingComponent,
    ordReportingComponent: ordReportingComponent,
    newtypeEstimationLocationId: newtypeEstimationLocationId,
    readParamEstimationLocationId: readParamEstimationLocationId,
    writeParamEstimationLocationId: writeParamEstimationLocationId,
    tofuJSONEstimationLocationId: tofuJSONEstimationLocationId,
    showEstimationLocationId: showEstimationLocationId,
    eqEstimationLocationId: eqEstimationLocationId,
    ordEstimationLocationId: ordEstimationLocationId,
    newtypeEstimationResourceId: newtypeEstimationResourceId,
    readParamEstimationResourceId: readParamEstimationResourceId,
    writeParamEstimationResourceId: writeParamEstimationResourceId,
    tofuJSONEstimationResourceId: tofuJSONEstimationResourceId,
    showEstimationResourceId: showEstimationResourceId,
    eqEstimationResourceId: eqEstimationResourceId,
    ordEstimationResourceId: ordEstimationResourceId,
    newtypeOrgEstimationResourceId: newtypeOrgEstimationResourceId,
    readParamOrgEstimationResourceId: readParamOrgEstimationResourceId,
    writeParamOrgEstimationResourceId: writeParamOrgEstimationResourceId,
    tofuJSONOrgEstimationResourceId: tofuJSONOrgEstimationResourceId,
    showOrgEstimationResourceId: showOrgEstimationResourceId,
    eqOrgEstimationResourceId: eqOrgEstimationResourceId,
    ordOrgEstimationResourceId: ordOrgEstimationResourceId,
    newtypeComponentId: newtypeComponentId,
    readParamComponentId: readParamComponentId,
    writeParamComponentId: writeParamComponentId,
    tofuJSONComponentId: tofuJSONComponentId,
    showComponentId: showComponentId,
    eqComponentId: eqComponentId,
    ordComponentId: ordComponentId,
    newtypeReportingComponentId: newtypeReportingComponentId,
    readParamReportingComponentId: readParamReportingComponentId,
    writeParamReportingComponentId: writeParamReportingComponentId,
    tofuJSONReportingComponentId: tofuJSONReportingComponentId,
    showReportingComponentId: showReportingComponentId,
    eqReportingComponentId: eqReportingComponentId,
    ordReportingComponentId: ordReportingComponentId,
    eqComponentType: eqComponentType,
    ordComponentType: ordComponentType,
    genericComponentType: genericComponentType,
    tofuJSONComponentType: tofuJSONComponentType,
    tofuJSONReportingComponent: tofuJSONReportingComponent,
    newtypeReportingComponent: newtypeReportingComponent,
    showComponentType: showComponentType
};
