// @ts-ignore: Missing typings
import AppContainer from "../../../../output/AppContainer";
import {
  // @ts-ignore: Let's update aws libs some day
  withAuthenticator,
  // @ts-ignore: Let's update aws libs some day
  VerifyContact,
  // @ts-ignore: Let's update aws libs some day
  ConfirmSignIn,
  // @ts-ignore: Missing typings
} from "aws-amplify-react";
import "../../auth/Auth";
// @ts-ignore: Missing typings
import { CustomSignIn } from "../../auth/SignIn";
// @ts-ignore: Missing typings
import { CustomForgotPassword } from "../../auth/ForgotPassword";
// @ts-ignore: Missing typings
import { CustomRequireNewPassword } from "../../auth/RequireNewPassword";
import { getClientConfigVar } from "src/client/config";
import { AuthRedirect } from "src/client-ts/auth/AuthRedirect";
import { isSignedIn } from "src/client-ts/auth/oidc";
import ForceSsoRedirect from "src/client-ts/auth/ForceSsoRedirect";

const env = document.location.hostname;
const isProd = env === "app.tocoman.fi";
if (!isProd) {
  window.LOG_LEVEL = "info"; // or 'DEBUG'
}

export default () => {
  if (getClientConfigVar("CLIENT_AUTH_DISABLE") === "true") {
    return AppContainer.component;
  } else if (window.location.href.indexOf("sso-redirect") !== -1) {
    return ForceSsoRedirect;
  } else if (window.location.href.indexOf("oauth2/redirect") !== -1) {
    return AuthRedirect;
    // Is signed in with Admincom SSO
  } else if (isSignedIn()) {
    return AppContainer.component;
    // Is signed in with Cognito or is not signed in
  } else {
    return withAuthenticator(AppContainer.component, false, [
      <CustomSignIn key={1} />,
      <VerifyContact key={2} />,
      <CustomForgotPassword key={3} />,
      <CustomRequireNewPassword key={4} />,
      <ConfirmSignIn key={5} />,
    ]);
  }
};
