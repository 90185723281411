import { useTranslation } from "react-i18next";
import { Checkbox, Text } from "@tocoman/ui";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { useDefault } from "../../../hooks/useDefault";
import { Subproject } from "../../../../../ts-bindings/Subproject";
import { useCallback, useMemo } from "react";
import { Table } from "src/client-ts/components/Table";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { ColDef } from "ag-grid-community";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { useMassPriceUpdate } from "./hooks/useMassPriceUpdate";
import { SelectedComponent } from "./EditMultipleComponentsModal";
import { useModalActionButtons } from "./hooks/useModalActionButtons";

type MassPricingProps = {
  components: SelectedComponent[];
  projectId: number;
  closeModal: () => void;
  onSave: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};

type MassPricingValues = {
  massPricingProject: string;
  massPricingSubproject: string;
  massPriceOnlyNonPricedComponents: boolean;
};

export function MassPricing({
  components,
  projectId,
  closeModal,
  onSave,
  setActionButtons,
}: MassPricingProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const methods = useForm<MassPricingValues>({
    defaultValues: {
      massPriceOnlyNonPricedComponents: false,
    },
  });
  const { watch, control, handleSubmit, getValues } = methods;

  const { mutate: massPricingUpdate } = useMassPriceUpdate(onSave, projectId);

  const getNonPricedComponentIds = useCallback(() => {
    return components
      .filter((component) => component.totalPrice === 0)
      .map((component) => component.componentId);
  }, [components]);

  const handleSave = useCallback(
    // eslint-disable-next-line
    (form: any) => {
      massPricingUpdate({
        components: form.massPriceOnlyNonPricedComponents
          ? getNonPricedComponentIds()
          : components.map((component) => component.componentId),
        sourceProjectId: Number(form.massPricingProject),
        sourceSubprojectId: Number(form.massPricingSubproject),
      });
    },
    [components, getNonPricedComponentIds, massPricingUpdate]
  );

  const massPricingProject = watch("massPricingProject");

  const setModalButtons = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setModalButtons(true);
  });

  const projects = useDefault(useWorkerState("EstimationProjectsState"), []);

  const projectColDefs: ColDef<EstimationProjectSummary>[] = useMemo(
    () => [
      { flex: 0.5, filter: false, sortable: false },
      { field: "code", headerName: t`form.code`, flex: 1.5 },
      { field: "name", headerName: t`form.name`, flex: 3.5 },
      {
        field: "version",
        headerName: t`form.version`,
        comparator: (a, b) => Number(a) - Number(b),
      },
    ],
    [t]
  );
  const subprojectColDefs: ColDef<Subproject>[] = useMemo(
    () => [
      {
        width: 60,
        flex: 0.5,
        filter: false,
        sortable: false,
      },
      { field: "code", headerName: t`form.code`, flex: 1 },
      { field: "name", headerName: t`form.name`, flex: 2 },
    ],
    [t]
  );

  const findSubprojects = (projectId: number) =>
    projects.find((project) => project.id === projectId)?.subprojects;

  const subprojects = findSubprojects(Number(massPricingProject));

  return (
    <div className={"h-full"}>
      <FormProvider {...methods}>
        <Text text={t`massPricingInfo`}></Text>

        <Text text={t`form.project`} className={"mt-3"}></Text>
        <Controller
          control={control}
          name={"massPricingProject"}
          render={({ field: { onChange } }) => (
            <Table<EstimationProjectSummary>
              rowData={projects}
              columnDefs={projectColDefs}
              className="h-128 w-full mt-2 mb-2"
              defaultColDef={{
                sortable: true,
                filter: "agTextColumnFilter",
                floatingFilter: true,
                resizable: true,
                flex: 1,
              }}
              rowSelection={{ mode: "singleRow", checkboxes: true }}
              onSelectionChanged={(e) => {
                setModalButtons(
                  getValues("massPricingSubproject") === undefined
                );
                onChange(e.api.getSelectedRows()[0]?.id);
              }}
            />
          )}
        />

        <Text text={t`form.subproject`} className={"mt-3"}></Text>
        <Controller
          name={"massPricingSubproject"}
          render={({ field: { onChange } }) => (
            <Table<Subproject>
              rowData={subprojects ?? []}
              columnDefs={subprojectColDefs}
              className="h-64 w-full mt-2 mb-2"
              rowSelection={{ mode: "singleRow", checkboxes: true }}
              onSelectionChanged={(e) => {
                setModalButtons(e.api.getSelectedRows()[0]?.id === undefined);
                onChange(e.api.getSelectedRows()[0]?.id);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"massPriceOnlyNonPricedComponents"}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              className={"mt-3"}
              label={t`form.changePriceOnlyForNonPriced`}
              checked={value}
              setChecked={onChange}
            />
          )}
        />
      </FormProvider>
    </div>
  );
}
