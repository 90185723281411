import { useCallback, useMemo, useRef, useState } from "react";
import { useModalData } from "../../../hooks/useModalData";
import { Button, Checkbox, IconAddPlusV1, IconEditV1 } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { ConfirmEditSuperAdmin } from "../Modals/ConfirmEditSuperAdmin";
import { UserInformation } from "../Modals/UserInformation";
import { ConfirmUserDelete } from "../Modals/ConfirmUserDelete";
import { ColDef, ICellRendererParams, StatusPanelDef } from "ag-grid-community";
import { Table } from "src/client-ts/components/Table";
import { AgGridReact } from "ag-grid-react";
import { useSelectRowsAsync } from "../../../components/Table/useSelectRowsAsync";
import { useGetUsersInSuperAdmin, UserData } from "./useSuperAdminUsers";

export const UsersTab = () => {
  const userRows = useGetUsersInSuperAdmin();
  const { t } = useTranslation("superAdmin");
  const [editSuperAdminOpen, editSuperAdmin, setEditSuperAdmin] = useModalData<
    UserData
  >();
  const [editUserOpen, editUser, setEditUser] = useModalData<UserData>();
  const [usersToDelete, setUsersToDelete] = useState<UserData[] | null>(null);
  const gridRef = useRef<AgGridReact<UserData>>(null);
  const selectRowsAsync = useSelectRowsAsync(gridRef?.current?.api);
  const [gridReady, setGridReady] = useState(false);

  const handleEditClick = useCallback(
    (data: UserData | undefined) => {
      if (data !== undefined) {
        setEditUser(data);
      }
    },
    [setEditUser]
  );

  const deleteUsers = useCallback(() => {
    if (!gridRef.current) {
      return;
    }

    const api = gridRef.current.api;
    if (api.getSelectedRows().length === 0) {
      return;
    }

    setUsersToDelete(api.getSelectedRows());
  }, [setUsersToDelete]);

  const addUser = useCallback(() => {
    setEditUser({
      id: "",
      email: "",
      currentOrganization: "",
      organizations: [],
      createdAt: new Date(),
      expiresAt: null,
      isSuperAdmin: false,
      hasSSOLogin: false,
    });
  }, [setEditUser]);

  const columnDefs = useMemo<ColDef<UserData>[]>(
    () => [
      {
        field: "email",
        headerName: t`email`,
        showDisabledCheckboxes: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        field: "organizations",
        headerName: t`organizations`,
        enableRowGroup: true,
        cellDataType: "text",
        filter: "agSetColumnFilter",
      },
      {
        field: "createdAt",
        headerName: t`createdAt`,
        enableRowGroup: true,
        type: "isoDate",
        minWidth: 150,
      },
      {
        field: "expiresAt",
        headerName: t`expiresAt`,
        enableRowGroup: true,
        type: "isoDate",
        minWidth: 150,
      },
      {
        field: "isSuperAdmin",
        headerName: t`superAdmin`,
        filter: "agSetColumnFilter",
        cellRenderer: (cellData: ICellRendererParams<UserData, boolean>) => {
          if (!cellData.data) {
            return <></>;
          }
          const user = cellData.data;
          return (
            <div className={"flex h-full"}>
              <Checkbox
                testId={"superAdminCheckbox"}
                className={"self-center"}
                disabled={
                  ![
                    "@tocoman.com",
                    "@admicom.fi",
                    "@admicom.com",
                  ].some((email) => user.email?.endsWith(email))
                }
                checked={cellData.value ?? false}
                setChecked={() => setEditSuperAdmin(user)}
              />
            </div>
          );
        },
      },
      {
        field: "id",
        headerName: t`id`,
      },
      {
        colId: "edit",
        headerName: "",
        pinned: "right",
        width: 60,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        cellRenderer: (cellData: ICellRendererParams<UserData>) => {
          return (
            <div>
              {!cellData.node.group && (
                <>
                  <Button
                    testId={"editUserBtn"}
                    icon={IconEditV1}
                    onClick={() => handleEditClick(cellData.data ?? undefined)}
                    variant="text"
                  />
                </>
              )}
            </div>
          );
        },
      },
    ],
    [t, setEditSuperAdmin, handleEditClick]
  );

  const defaultColDef: ColDef<UserData> = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const actions = useCallback(
    () => (
      <Button
        testId={"addUsersBtn"}
        className={"my-3"}
        variant="text"
        label={t`addUsers`}
        onClick={() => addUser()}
        icon={IconAddPlusV1}
      />
    ),
    [addUser, t]
  );

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: actions,
        },
      ],
    };
  }, [actions]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: t`columns`,
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
          suppressValues: true,
          suppressRowGroups: true,
        },
      },
    ],
  };

  const onGridReady = () => {
    setGridReady(true);
  };

  return (
    <>
      <div className={"mb-5 h-[24px]"}>
        {gridReady && (
          <Button
            testId="deleteUsersBtn"
            color="danger"
            variant="primary"
            onClick={deleteUsers}
          >
            {t`deleteUsers`}
          </Button>
        )}
      </div>
      <Table<UserData>
        gridRef={gridRef}
        className="w-full h-full"
        columnDefs={columnDefs}
        autoGroupColumnDef={{
          filter: "agGroupColumnFilter",
          floatingFilter: true,
        }}
        defaultColDef={defaultColDef}
        rowData={userRows}
        rowGroupPanelShow={"always"}
        rowSelection={{ mode: "multiRow" }}
        statusBar={statusBar}
        sideBar={sideBar}
        onGridReady={onGridReady}
        suppressCopyRowsToClipboard={true}
        groupSelectsChildren={true}
        groupSelectsFiltered={true}
        getRowId={({ data: { id } }) => id}
      />

      {editSuperAdmin && (
        <ConfirmEditSuperAdmin
          setEditSuperAdmin={setEditSuperAdmin}
          editSuperAdminOpen={editSuperAdminOpen}
          editSuperAdmin={editSuperAdmin}
        />
      )}
      {editUser && (
        <UserInformation
          editUser={editUser}
          editUserOpen={editUserOpen}
          setEditUser={setEditUser}
          newRowData={(newUsers) => {
            selectRowsAsync(newUsers.map(({ email }) => email));
          }}
        />
      )}
      {usersToDelete && (
        <ConfirmUserDelete
          users={usersToDelete}
          onClose={() => setUsersToDelete(null)}
        />
      )}
    </>
  );
};
