/* eslint-disable i18next/no-literal-string */
import { SignIn } from "aws-amplify-react";
import React from "react";
import { signIn } from "src/client-ts/auth/oidc";
import {
  getClientConfigVar,
  getClientConfigVarOptional,
} from "src/client/config";
import bg from "/assets/Admicom-login-bg.svg";
import logo from "/assets/estima-logo-light.svg";
export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.onSubmit = this.onSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSsoLogin = this.handleSsoLogin.bind(this);
    this.getIdentityProvider = this.getIdentityProvider.bind(this);
    this.state = {
      showSsoLogin: false,
      ssoOrganization: "",
      ssoOrganizationInvalid: false,
      ssoOrganizationFetchError: false,
    };
  }

  // There's no render method because it's extending this component https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Auth/AuthPiece.jsx
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showComponent(theme) {
    return (
      <div
        className="login login-container"
        style={{
          backgroundImage: `url(${bg}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img alt="logo" src={logo} className="logo" />
        {this.showLoginForm()}
      </div>
    );
  }

  onSubmit(event) {
    event.preventDefault();
    this.signIn(event);
  }

  handleUsernameChange(evt) {
    this.inputs = this.inputs || {};
    const { value } = evt.target;
    this.inputs.username = value.toLowerCase();
  }

  getIdentityProvider(callback) {
    fetch(`/api/get-sso-organization-idp/${this.state.ssoOrganization}`)
      .then((x) => {
        if (x.status === 200) {
          return x.text();
        } else {
          throw `Error in response: ${x.status}`;
        }
      })
      .then((idpName) => {
        if (idpName) {
          callback(idpName);
        } else {
          this.setState({ ssoOrganizationInvalid: true });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({ ssoOrganizationFetchError: true });
      });
  }

  handleSsoLogin(event) {
    event.preventDefault();
    this.getIdentityProvider((identityProvider) => {
      if (identityProvider) {
        const SSO_BASE_URL = getClientConfigVar("CLIENT_SSO_BASE_URL");
        const SSO_REDIRECT_URI = getClientConfigVar("CLIENT_SSO_REDIRECT_URI");
        const CLIENT_ID = getClientConfigVar("CLIENT_USER_POOL_WEB_CLIENT_ID");

        const url = `${SSO_BASE_URL}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${SSO_REDIRECT_URI}&response_type=CODE&client_id=${CLIENT_ID}&scope=aws.cognito.signin.user.admin%20email%20openid`;
        console.log("url", url);
        window.location = url;
      }
    });
  }

  handleAdmicomSsoLogin() {
    signIn();
  }

  showLoginForm() {
    const ssoLoginModeToggle = (
      <div className="form-actions">
        <button
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showSsoLogin: true });
          }}
          className="btn login-btn-2"
        >
          SSO-kirjautuminen
        </button>
      </div>
    );

    const showPasswordLoginFieldsLink = (
      <div className="form-actions">
        <button
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showSsoLogin: false });
          }}
          className="btn login-btn-2"
        >
          Takaisin
        </button>
      </div>
    );

    const passwordLoginFields = (
      <div className="login-fields">
        <h1 className="header">Kirjaudu</h1>
        <label className="label">Sähköpostiosoite</label>
        <div className="text-field">
          <input
            autoFocus
            key="username"
            type="email"
            name="username"
            onChange={this.handleUsernameChange}
          />
        </div>
        <label>Salasana</label>
        <div className="text-field">
          <input
            key="password"
            type="password"
            name="password"
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );

    const organizationInputField = (
      <React.Fragment>
        <label>Organisaatio</label>
        <div className="text-field">
          <input
            autoFocus
            key="organization"
            type="text"
            name="organization"
            onChange={(e) => {
              e.preventDefault();
              this.setState({
                ssoOrganization: e.target.value,
                ssoOrganizationInvalid: false,
                ssoOrganizationFetchError: false,
              });
            }}
          />
        </div>
        <div className="text-field">
          <p className="error">
            {this.state.ssoOrganizationInvalid &&
              "Syöttämälläsi organisaatiolla ei ole SSO-kirjautuminen aktivoituna."}
          </p>
          <p className="error">
            {this.state.ssoOrganizationFetchError &&
              "Palvelimeen ei saatu yhteyttä."}
          </p>
        </div>
      </React.Fragment>
    );

    const passwordLoginButton = (
      <button onClick={this.onSubmit} className="btn login-btn" type="submit">
        Kirjaudu
      </button>
    );

    const ssoLoginButton = (
      <button
        onClick={this.handleSsoLogin}
        className="btn login-btn"
        type="submit"
        disabled={this.state.ssoOrganization ? false : true}
      >
        Kirjaudu yritystunnuksella
      </button>
    );

    return (
      <div className="login-page-wrap">
        <form className="login-panel">
          {this.state.showSsoLogin
            ? organizationInputField
            : passwordLoginFields}

          <div className="form-actions">
            {this.state.showSsoLogin ? ssoLoginButton : passwordLoginButton}
            {this.state.showSsoLogin
              ? showPasswordLoginFieldsLink
              : ssoLoginModeToggle}
            {!this.state.showSsoLogin &&
            getClientConfigVarOptional("CLIENT_ADMICOM_SSO_AUTHORITY") ? (
              <button
                type="button"
                onClick={this.handleAdmicomSsoLogin}
                className="btn login-btn-2"
              >
                Admicom SSO (Uusi)
              </button>
            ) : null}
          </div>

          <div className="reset-pw-panel" id="forget-pw-panel">
            <button
              type="button"
              className="reset-pw-button"
              onClick={() => this.changeState("forgotPassword")}
            >
              <p>Unohditko salasanasi?</p>
              <p>Ei hätää, klikkaa tästä. 😊</p>
            </button>
          </div>
        </form>
      </div>
    );
  }
}
