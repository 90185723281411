// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var FetchFile = require("../FetchFile/index.js");
var Milkis = require("../Milkis/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Classification = require("../Types.Classification/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Location = require("../Types.Location/index.js");
var Types_MergedEstimationResource = require("../Types.MergedEstimationResource/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var handleExcelExport = function (v) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(v.fetch))(function (response) {
        if (response instanceof Data_Either.Right) {
            var $38 = Milkis.statusCode(response.value0) === 200;
            if ($38) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_JSDate.now))(function (date) {
                    var dateString = Utils.lossyToHtmlDateString(date);
                    var _filename = v.mkFilename(dateString);
                    var filename = Data_String_Common.replaceAll(" ")("_")(_filename);
                    return FetchFile.createFileDownload(response.value0)(filename);
                });
            };
            return Effect_Class_Console.error(Effect_Aff.monadEffectAff)(v.errorMessage);
        };
        if (response instanceof Data_Either.Left) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger.logError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at FetchExcel (line 36, column 3 - line 51, column 16): " + [ response.constructor.name ]);
    });
};
var mkDownloadExcelHandler = function (v) {
    return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
        return handleExcelExport({
            fetch: RequestUtils.fetch()(Milkis.URL(v.url))({
                method: Milkis.postMethod,
                headers: headers,
                body: v.body
            }),
            mkFilename: v.mkFilename,
            errorMessage: v.errorMessage
        });
    }));
};
var downloadReportExcel = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetReportExcelRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "optionalTaxes";
                            }
                        })(TofuJSON.readMaybe(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpenses";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totals";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalsWithoutTaxes";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "optionalTaxes";
                            }
                        })(TofuJSON.readMaybe(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpenses";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totals";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalsWithoutTaxes";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupsItems";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "displayName";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "displayName";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costTotal";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateTimeString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "incomeInfoItems";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200IncomeInformationResult))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "incomeTotal";
                            }
                        })(Types_R200.tofuJSONR200IncomeInformationResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "optionalEntries";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resultMargin";
                            }
                        })(Types_R200.tofuJSONR200ResultMarginResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resultMarginPercentages";
                            }
                        })(Types_R200.tofuJSONR200ResultMarginResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRelatedEntries";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalWithoutTaxes";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "optionalTaxes";
                            }
                        })(TofuJSON.readMaybe(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpenses";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totals";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalsWithoutTaxes";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassResults";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "optionalTaxes";
                            }
                        })(TofuJSON.readMaybe(Types_R200.tofuJSONR200CostClassResult))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpenses";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totals";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalsWithoutTaxes";
                            }
                        })(Types_R200.tofuJSONR200CostClassResult)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupsItems";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "displayName";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "displayName";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costTotal";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateTimeString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "incomeInfoItems";
                            }
                        })(TofuJSON.readArray(Types_R200.tofuJSONR200IncomeInformationResult))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "incomeTotal";
                            }
                        })(Types_R200.tofuJSONR200IncomeInformationResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "optionalEntries";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resultMargin";
                            }
                        })(Types_R200.tofuJSONR200ResultMarginResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resultMarginPercentages";
                            }
                        })(Types_R200.tofuJSONR200ResultMarginResult)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRelatedEntries";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalWithoutTaxes";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "completionPercentage";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONPercentageOfZeroToOne))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "diffOfTargetToEstimateRatio";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimateRemaining";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(v1.value0.report)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.report.code) + ("-" + (v1.value0.report.name + ("-" + (dateString + ".xlsx"))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadPortfolioReportExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.PortfolioReportExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-portfolio-report-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projects";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projects";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return "raportointi-" + (dateString + ".xlsx");
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadPlanningProcumentExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetPlanningProcumentExport) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-procument-export"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "includeResources";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "planningCostGroups";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "includeResources";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "planningCostGroups";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + ("procument" + ("-" + (dateString + ".txt"))));
                    },
                    errorMessage: "XML report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadPlanningCostGroupsExportXML = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetPlanningCostGroupsExport) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/planningCostGroupsExport"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "includeResources";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "planningCostGroups";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "includeResources";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "planningCostGroups";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupClassificationId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + ("planning-cost-group" + ("-" + (dateString + ".xml"))));
                    },
                    errorMessage: "XML report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadOrgResourceSubGroupingExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetOrgResourceSubGroupingExcelExportRequest) {
            return mkDownloadExcelHandler({
                url: "/api/generate-org-resource-sub-grouping-excel",
                body: TofuJSON.writeJSON(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgPriceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourceGroupingId";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourceSubGroupingParentCode";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgPriceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourceGroupingId";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourceSubGroupingParentCode";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(v1.value0),
                mkFilename: function (dateString) {
                    return "panos-ala-ryhma-" + (dateString + ".xlsx");
                },
                errorMessage: "org resource sub grouping excel failed to generate: "
            });
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadOrgResourceExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetOrgResourceExcelExportRequest) {
            return mkDownloadExcelHandler({
                url: "/api/generate-org-resource-excel",
                body: TofuJSON.writeJSON(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "ownCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "price";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "priceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "taxRate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "user";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "ownCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "price";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "priceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "taxRate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "user";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(v1.value0),
                mkFilename: function (dateString) {
                    return "panos-hinnasto-" + (dateString + ".xlsx");
                },
                errorMessage: "org resource excel failed to generate: "
            });
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadOrgPriceListExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetOrgPriceListExcelExportRequest) {
            return mkDownloadExcelHandler({
                url: "/api/generate-org-price-list-excel",
                body: TofuJSON.writeJSON(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgPriceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgPriceListId";
                    }
                })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())))(v1.value0),
                mkFilename: function (dateString) {
                    return "panos-price-list-" + (dateString + ".xlsx");
                },
                errorMessage: "org price list excel failed to generate: "
            });
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadOfferPageExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetOfferPageExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-offer-page-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerRows";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "finalTotal";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourly_rate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "marginAmount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "marginPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "percentageOfFinalTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "percentageOfTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePriceWithMarginPercentage";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalForClassification";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalOverride";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalWithRiskAndMargin";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes_with_margin";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "finalTotal";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourly_rate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "marginAmount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "marginPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "percentageOfFinalTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "percentageOfTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePriceWithMarginPercentage";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalForClassification";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalOverride";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalWithRiskAndMargin";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes_with_margin";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerRows";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "finalTotal";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourly_rate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "marginAmount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "marginPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "percentageOfFinalTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "percentageOfTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePriceWithMarginPercentage";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalForClassification";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalOverride";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalWithRiskAndMargin";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes_with_margin";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "finalTotal";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourly_rate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "marginAmount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "marginPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "percentageOfFinalTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "percentageOfTotal";
                            }
                        })(Types_Unit.tofuJSONPercentageOfZeroToOne)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePriceWithMarginPercentage";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalForClassification";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalOverride";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalWithRiskAndMargin";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "total_price_by_cost_classes_with_margin";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "totalPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("tarjous-laskenta-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadInvoiceLinesExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetInvoiceLinesExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-invoiceLines"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(Types_CostGroup.tofuJSONCostGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "date";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Invoice.tofuJSONInvoiceRowId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "invoiceBunchId";
                            }
                        })(TofuJSON.readNullable(Types_Invoice.tofuJSONInvoiceBunchId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "invoiceId";
                            }
                        })(Types_Invoice.tofuJSONInvoiceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "invoiceImg";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "invoiceNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "lineDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "quantity";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensesPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "supplier";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "supplierBusinessId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "supplierInvoiceNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_Invoice.tofuJSONInvoiceType)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "value";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClass";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(Types_CostGroup.tofuJSONCostGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "date";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hours";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Invoice.tofuJSONInvoiceRowId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "invoiceBunchId";
                            }
                        })(TofuJSON.readNullable(Types_Invoice.tofuJSONInvoiceBunchId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "invoiceId";
                            }
                        })(Types_Invoice.tofuJSONInvoiceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "invoiceImg";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "invoiceNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "lineDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "quantity";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensesPercentage";
                            }
                        })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "supplier";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "supplierBusinessId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "supplierInvoiceNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_Invoice.tofuJSONInvoiceType)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "value";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(v1.value0.invoiceLines)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.projectCode) + ("-" + ("laskut-" + (dateString + ".xlsx")));
                    },
                    errorMessage: "invoices export failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationResourcesExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationResourcesExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-resources-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "mergedResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "key";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "key";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "useVisibleColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "visibleColumns";
                            }
                        })(TofuJSON.readArray(Types_MergedEstimationResource.tofuJSONEstimationResourceColumn))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "mergedResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "key";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationResources";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "component";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "amount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "checked";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costGroupDescription";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "description";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "flagged";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "subProjectName";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resource";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "changeDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contract";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currencyId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountGroupCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "discountPercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "eanCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimationComponentId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "group";
                            }
                        })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isCostClassPrice";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "sortingNumber";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unitConsumptionRate";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "wastePercentage";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "index";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "key";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "orgResourcePriceListId";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "pricePerUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "priceWithDiscount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "resourceClassificationGroup";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxPercentage";
                            }
                        })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "unit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "usagesCount";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "validDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendor";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorBatchSize";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "vendorUnit";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "useVisibleColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "visibleColumns";
                            }
                        })(TofuJSON.readArray(Types_MergedEstimationResource.tofuJSONEstimationResourceColumn))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("panosluettelo-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationQuantityExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationQuantityExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-quantity-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("m\xe4\xe4r\xe4luettelo-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationQuantityByLocationExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationQuantityByLocationExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-quantity-by-location-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectLocations";
                            }
                        })(TofuJSON.readArray(Types_Location.tofuJSONProjectLocation))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectLocations";
                            }
                        })(TofuJSON.readArray(Types_Location.tofuJSONProjectLocation))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("M\xe4\xe4r\xe4t sijainneittain" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationQuantityByCostClassExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationQuantityByCostClassExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-quantity-by-cost-class-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("Hinnat panoslajeittain" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources)))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "elementMeasures";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure)))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "elementsData";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_BuildingElementsView.jsonWasmElementsData)))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showGroups";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showTargetCostGroup";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showWorkAchievementColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources)))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "elementMeasures";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure)))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "elementsData";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readArray(Types_BuildingElementsView.jsonWasmElementsData)))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showGroups";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showTargetCostGroup";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showWorkAchievementColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("laskenta-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationElementsExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationElementsExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-elements-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "elementsData";
                            }
                        })(TofuJSON.readArray(Types_BuildingElementsView.jsonWasmElementsData))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "groupBy";
                            }
                        })(TofuJSON.readMaybe(Types_BuildingElementsView.tofuJSONElementsTableGroupBy))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "elementsData";
                            }
                        })(TofuJSON.readArray(Types_BuildingElementsView.jsonWasmElementsData))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "groupBy";
                            }
                        })(TofuJSON.readMaybe(Types_BuildingElementsView.tofuJSONElementsTableGroupBy))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("rakenneluettelo-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadEstimationComponentsExcelExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationComponentsExcelExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-estimation-components-excel"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "groupBy";
                            }
                        })(TofuJSON.readMaybe(Types_ComponentsView.tofuJSONECGroupByProperty))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassHoursPerUnitColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassTotalPriceColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showCostClassUnitPriceColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showGroups";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "showTargetCostGroup";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "addMemoToExcel";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "componentsWithResources";
                            }
                        })(TofuJSON.readArray(Types_CostEstimation.tofuEstimationComponentWithResources))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClasses";
                            }
                        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "costClassCode";
                            }
                        })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "hourlyPricing";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "multiplier";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectId";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForComponents";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostControlForTargets";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentageInCostEstimation";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "dateString";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "filterStrings";
                            }
                        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "groupBy";
                            }
                        })(TofuJSON.readMaybe(Types_ComponentsView.tofuJSONECGroupByProperty))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "project";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualDiffApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualIncomeApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "actualMarginApproved";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "address";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "area";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "city";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "classification";
                            }
                        })(Types_Classification.tofuJSONOrgClassificationCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "code";
                            }
                        })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "constructionType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractPrice";
                            }
                        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "contractType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "currency";
                            }
                        })(Types_Project.tofuJSONCurrency)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultInvoiceCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "defaultSalaryCostClass";
                            }
                        })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "endDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "estimatedMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "financingType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "isReportingProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "memo";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "name";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "offerDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownProject";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "ownTaxRate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "postalCode";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "programType";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectGroup";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectManager";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "projectType";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "startDate";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "state";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetCosts";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetDiff";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetIncome";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "targetMargin";
                            }
                        })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "taxRate";
                            }
                        })(TofuJSON.readMaybe(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "version";
                            }
                        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassHoursPerUnitColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassTotalPriceColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showCostClassUnitPriceColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showGroups";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showHoursColumns";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSocialExpensesInCosts";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showSubprojects";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "showTargetCostGroup";
                            }
                        })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "socialExpensePercentagesByCostClass";
                            }
                        })(TofuJSON.tofuJSONMap(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode)(Types_Unit.tofuJSONSocialExpensePercentage))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(v1.value0)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.project.code) + ("-" + (v1.value0.project.name + ("-" + ("suoriteluettelo-" + (dateString + ".xlsx")))));
                    },
                    errorMessage: "excel report failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var downloadCostGroupsExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetCostGroupsExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return handleExcelExport({
                    fetch: RequestUtils.fetch()(Milkis.URL("/api/generate-costgroups"))({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: TofuJSON.writeJSON(TofuJSON.readArray(Types_CostGroup.tofuJSONCostGroupUnit))(v1.value0.costGroupUnits)
                    }),
                    mkFilename: function (dateString) {
                        return Data_Newtype.un()(Types_Project.ProjectCode)(v1.value0.projectCode) + ("-litterat-" + (dateString + ".xlsx"));
                    },
                    errorMessage: "cost groups export failed to generate: "
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
module.exports = {
    handleExcelExport: handleExcelExport,
    downloadReportExcel: downloadReportExcel,
    downloadEstimationExcelExport: downloadEstimationExcelExport,
    downloadEstimationQuantityExcelExport: downloadEstimationQuantityExcelExport,
    downloadEstimationQuantityByCostClassExcelExport: downloadEstimationQuantityByCostClassExcelExport,
    downloadEstimationQuantityByLocationExcelExport: downloadEstimationQuantityByLocationExcelExport,
    downloadEstimationComponentsExcelExport: downloadEstimationComponentsExcelExport,
    downloadEstimationElementsExcelExport: downloadEstimationElementsExcelExport,
    downloadEstimationResourcesExcelExport: downloadEstimationResourcesExcelExport,
    downloadOfferPageExcelExport: downloadOfferPageExcelExport,
    downloadPortfolioReportExcelExport: downloadPortfolioReportExcelExport,
    downloadOrgResourceExcelExport: downloadOrgResourceExcelExport,
    downloadOrgPriceListExcelExport: downloadOrgPriceListExcelExport,
    downloadOrgResourceSubGroupingExcelExport: downloadOrgResourceSubGroupingExcelExport,
    mkDownloadExcelHandler: mkDownloadExcelHandler,
    downloadInvoiceLinesExport: downloadInvoiceLinesExport,
    downloadCostGroupsExport: downloadCostGroupsExport,
    downloadPlanningCostGroupsExportXML: downloadPlanningCostGroupsExportXML,
    downloadPlanningProcumentExport: downloadPlanningProcumentExport
};
