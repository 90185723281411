import { Button, IconAddPlusV1, IconFileUploadV1 } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useUserDataQuery } from "./useUserData";

type StatusBarButtonsProps = {
  openCreateProjectView: () => void;
  setTransportFileModalOpen: (value: boolean) => void;
};

export const StatusBarButtons = (props: StatusBarButtonsProps) => {
  const { t } = useTranslation("costControl", { keyPrefix: "projectList" });

  const userDataWithPermissions = useUserDataQuery();
  const createProjectPermission =
    userDataWithPermissions?.permissions.createProjectsPermission;

  return (
    <div className={"flex flex-row gap-2 py-3"}>
      <Button
        variant="text"
        label={t`tableActions.createNewProject`}
        onClick={props.openCreateProjectView}
        disabled={!createProjectPermission}
        icon={IconAddPlusV1}
        testId={"projectList.actions.newProject"}
      />
      <Button
        variant="text"
        label={t`tableActions.importTransportFile`}
        onClick={() => props.setTransportFileModalOpen(true)}
        disabled={!createProjectPermission}
        icon={IconFileUploadV1}
        testId={"projectList.actions.importTransportFile"}
      />
    </div>
  );
};
