import { useCreateOrUpdateUsersAgGridConfiguration } from "./useUsersAgGridConfigurations";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  DisplayedColumnsChangedEvent,
  DragStoppedEvent,
  GridColumnsChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback } from "react";

export function useSaveUsersAgGridConfiguration(
  gridRef: React.RefObject<AgGridReact>,
  agGridName: string,
  projectId?: number
) {
  const saveConfiguration = useCreateOrUpdateUsersAgGridConfiguration();
  return useCallback(
    (
      event:
        | DragStoppedEvent
        | ColumnResizedEvent
        | ColumnRowGroupChangedEvent
        | ColumnVisibleEvent
        | ColumnPinnedEvent
        | DisplayedColumnsChangedEvent
        | GridColumnsChangedEvent
    ) => {
      if (event) {
        const isColumnResized =
          "source" in event &&
          "finished" in event &&
          event.source === "uiColumnResized" &&
          event.finished;

        const isDragStopped = event.type === "dragStopped";

        const isGroupChangedFromToolPanel =
          event.type === "columnRowGroupChanged" &&
          event.source === "toolPanelUi";

        const isVisibilityChangedFromToolPanel =
          event.type === "columnVisible" && event.source === "toolPanelUi";

        const isPinnedFromContextOrColumnMenu =
          event.type === "columnPinned" &&
          (event.source === "contextMenu" || event.source === "columnMenu");

        const isDisplayedColumnsChangedFromContextOrColumnMenu =
          event.type === "displayedColumnsChanged" &&
          (event.source === "contextMenu" || event.source === "columnMenu");

        const isGridColunsChanged = event.type === "gridColumnsChanged";

        if (
          isColumnResized ||
          isDragStopped ||
          isGroupChangedFromToolPanel ||
          isVisibilityChangedFromToolPanel ||
          isPinnedFromContextOrColumnMenu ||
          isDisplayedColumnsChangedFromContextOrColumnMenu ||
          isGridColunsChanged
        ) {
          const modelData = {
            agGridName: agGridName,
            agGridConfiguration: JSON.stringify(
              gridRef.current?.api.getColumnState()
            ),
            projectId: projectId ?? null,
          };
          return saveConfiguration.mutate(modelData);
        }
      }
    },
    [agGridName, gridRef, projectId, saveConfiguration]
  );
}
