import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { useEffect, useMemo } from "react";
import { getClientConfigVarOptional } from "src/client/config";
import { useCurrentUser } from "../auth/useCurrentUser";
import { useGetUsersOrgsData } from "../containers/NavBar/useGetUsersOrgsDataQuery";
import { useUserDataQuery } from "../containers/Projects/useUserData";

export function PostHogWrapper({ children }: { children: React.ReactNode }) {
  const posthogTokenKey = getClientConfigVarOptional("CLIENT_POSTHOG_TOKEN");
  const posthogApiHostKey = getClientConfigVarOptional("CLIENT_POSTHOG_HOST");
  const stage = getClientConfigVarOptional("CLIENT_STAGE");
  const posthogDisabled =
    getClientConfigVarOptional("CLIENT_POSTHOG_DISABLE") === "true";

  if (posthogDisabled) {
    return <>{children}</>;
  }

  if (!posthogTokenKey || !posthogApiHostKey) {
    console.warn(
      "PostHog token or API host not set(CLIENT_POSTHOG_TOKEN, CLIENT_POSTHOG_HOST), not initializing PostHog SDK"
    );
    return <>{children}</>;
  }

  return (
    <PostHogTracking
      tokenKey={posthogTokenKey}
      apiKey={posthogApiHostKey}
      stage={stage}
    >
      {children}
    </PostHogTracking>
  );
}

export function PostHogTracking(props: {
  tokenKey: string;
  apiKey: string;
  stage: string;
  children: React.ReactNode;
}) {
  const { tokenKey, apiKey, stage, children } = props;
  const user = useCurrentUser();
  const data = useUserDataQuery();
  const language = data?.userData.language;

  // Init posthog and set product and environment variables for all events
  useEffect(() => {
    posthog.init(tokenKey, {
      api_host: apiKey,
    });
    posthog.register_once({
      product: "estima",
      environment: stage ?? "unknown",
    });
  }, [tokenKey, apiKey]);

  // Identify user with posthog
  useEffect(() => {
    posthog.identify(`estima:${user.userId}`, {
      email: user.email,
      lang: language,
    });
  }, [user, stage]);

  const userOrgsData = useGetUsersOrgsData();
  const currentOrg = useMemo(() => {
    if (!userOrgsData) {
      return;
    }
    return userOrgsData.organizations.find(
      (org) => org.name === userOrgsData.currentOrganization
    );
  }, [userOrgsData]);

  // Set tenant group to current organization
  useEffect(() => {
    if (!currentOrg) {
      return;
    }

    posthog.group("tenant", `estima:${currentOrg.name}`, {
      name: currentOrg.companyName || currentOrg.name,
    });
  }, [currentOrg]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
