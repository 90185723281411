import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Combobox, SelectItem } from "@tocoman/ui";
import { useComboboxFilter } from "../../../../components/useComboboxFilter";

export const ProjectSelector = ({
  projects,
}: {
  projects: SelectItem<string>[];
}) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const newProjectsFilterProps = useComboboxFilter(projects);

  return (
    <Controller
      name="project"
      render={({ field: { onChange, value } }) => (
        <Combobox
          label={t("project")}
          onValueChange={onChange}
          selected={value}
          {...newProjectsFilterProps}
        />
      )}
    />
  );
};
