import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ParameterValueMap } from "../../../Estimation/Report/types";
import { setParameterValues } from "../../../Estimation/Report/utils";
import { parameterSchema } from "../parameterSchema";

const createEmptyStringParameter = (Name: string): ARJS.ReportParameter =>
  parameterSchema[Name];

const addMissingParameters = (
  parameters: ARJS.ReportParameter[],
  parameterNames: string[]
) => [...parameters, ...parameterNames.map(createEmptyStringParameter)];

export const setParameterInitialValues = (
  parameters: ARJS.ReportParameter[],
  values: ParameterValueMap
) => {
  const missingParameters = Object.keys(values).filter(
    (parameterName) => !parameters?.some(({ Name }) => Name === parameterName)
  );

  const completedParameterList = addMissingParameters(
    parameters,
    missingParameters
  );

  return setParameterValues(completedParameterList, values);
};

export const getParamByName = (
  parameters: ARJS.ReportParameter[],
  name: string
): ARJS.ReportParameter | undefined =>
  parameters ? parameters.find(({ Name }) => Name === name) : undefined;

export const getParamsByNames = (
  parameters: ARJS.ReportParameter[] | undefined,
  names: string[]
): ARJS.ReportParameter[] =>
  names
    .map((name) => getParamByName(parameters || [], name))
    .filter((parameter): parameter is ARJS.ReportParameter => !!parameter);
