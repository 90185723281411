import { CostClass } from "../../../../../ts-bindings/CostClass";
import { TFunction } from "react-i18next";
import {
  ColDef,
  ColGroupDef,
  IAggFuncParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CostControlItem } from "../../../../../ts-bindings/CostControlItem";
import { CostControlPrintingRow } from "./Printing";
import {
  cellStyles,
  countValue,
  getTotalValueFromGroups,
} from "./printingFunctions";

export const costClassColumnDefinitions = (
  costClasses: CostClass[],
  t: TFunction<"costControl", "printing">
) => {
  return costClasses.map((costClass, costClassIndex) => {
    const costClassHeader = costClass.costClassCode + ": " + costClass.name;
    const costClassColGroup: ColGroupDef<CostControlPrintingRow> = {
      headerName: costClassHeader,
      children: [],
    };
    const defaultCostClassColumns: ColDef<CostControlPrintingRow>[] = [
      {
        colId: `costClasses.${costClassIndex}.value`,
        headerName: t`tableColumns.total`,
        type: ["money"],
        initialWidth: 140,
        aggFunc: (params) => costClassTargetValue(params, costClass),
        cellStyle: cellStyles,
        valueGetter: (params) => {
          if (params.node?.rowPinned) {
            return getTotalValueFromGroups(
              params,
              `costClasses.${costClassIndex}.value`
            );
          }
          const currentCostClass = getCurrentCostClass(params, costClass);
          return currentCostClass?.value ?? "";
        },
      },
    ];
    if (!costClass.hourlyPricing) {
      costClassColGroup.children = defaultCostClassColumns;
    } else {
      const hourCostClassColumns: ColDef<CostControlPrintingRow>[] = [
        {
          colId: `costClasses.${costClassIndex}.hours`,
          headerName: t`tableColumns.hours`,
          type: ["twoDecimal"],
          initialWidth: 120,
          cellStyle: cellStyles,
          valueGetter: (params) => {
            if (params.node?.rowPinned) {
              return getTotalValueFromGroups(
                params,
                `costClasses.${costClassIndex}.hours`
              );
            }
            const currentCostClass = getCurrentCostClass(params, costClass);
            return currentCostClass?.hours ?? "";
          },
        },
        {
          colId: `costClasses.${costClassIndex}.socialExpenses`,
          headerName: t`tableColumns.socialExpenses`,
          initialWidth: 160,
          valueGetter: (params) =>
            getCostClassSocialExpenses(params, costClassIndex),
          type: ["money"],
          aggFunc: (params) => socialExpensesFromTargetValue(params, costClass),
          cellStyle: cellStyles,
        },
      ];
      costClassColGroup.children = [
        ...costClassColGroup.children,
        ...defaultCostClassColumns,
        ...hourCostClassColumns,
      ];
    }
    return costClassColGroup;
  });
};

export const getCostClassSocialExpenses = (
  params: ValueGetterParams<CostControlPrintingRow>,
  costClassIndex: number
) => {
  if (params.node?.rowPinned) {
    const columnName = `costClasses.${costClassIndex}.socialExpenses`;
    return getTotalValueFromGroups(params, columnName);
  }
  if (!params.data?.costClasses) {
    return null;
  }
  const percentage =
    params.data.costClasses[costClassIndex]
      ?.socialExpensePercentageInCostControlForTargets ?? 0;
  return percentage * params.data.costClasses[costClassIndex]?.value;
};

export function countTargetValues(
  params: IAggFuncParams<CostControlPrintingRow>,
  costClass: CostClass
) {
  if (params.rowNode.field === "costGroup") {
    const groupCode = params.rowNode.allLeafChildren?.[0].data?.costGroup?.code;
    const targets: number[] = params.context.targets
      .filter(
        (target: CostControlItem) =>
          target.costGroupCode === groupCode &&
          target.costClassCode === costClass.costClassCode
      )
      .map((target: CostControlItem) => target.value);
    return targets.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }
  if (params.rowNode.field === "mainCostGroup") {
    return countValue(params.values);
  }
  return 0;
}

export function costClassTargetValue(
  params: IAggFuncParams<CostControlPrintingRow>,
  costClass: CostClass
) {
  if (!params.rowNode.allLeafChildren) {
    return;
  }
  if (params.rowNode.allLeafChildren.length > 0) {
    return countTargetValues(params, costClass);
  }
}

export function socialExpensesFromTargetValue(
  params: IAggFuncParams<CostControlPrintingRow>,
  costClass: CostClass
) {
  if (!params.rowNode.allLeafChildren) {
    return;
  }
  if (params.rowNode.allLeafChildren.length > 0) {
    const targetValue = countTargetValues(params, costClass);
    const socialExpensesPercentage =
      costClass.socialExpensePercentageInCostControlForTargets ?? 0;
    return targetValue * socialExpensesPercentage;
  }
}

export function getCurrentCostClass(
  params: ValueGetterParams,
  costClass: CostClass
) {
  const rowId = params.node?.id ?? "";
  const rowNode = params.api.getRowNode(rowId);
  return rowNode?.data?.costClasses?.find(
    (columnCostClass: CostClass) => columnCostClass.id === costClass.id
  );
}
