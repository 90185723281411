import {
  ColDef,
  ColGroupDef,
  IAggFuncParams,
  ValueGetterParams,
} from "ag-grid-community";
import { TotalPriceByCostClassCode } from "ts-bindings/TotalPriceByCostClassCode";
import { CostClass } from "ts-bindings/CostClass";
import { TFunction } from "react-i18next";
import {
  calculateTotalPinned,
  calculateTotalSocialExpenses,
  ComponentSummary,
} from "./Printing";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import { safeDivide } from "../../../utils/safeDivide";
import { getCurrencyToLocale } from "src/client-ts/utils/currency";

export const costClassColumnDefinitions = (
  costClasses: CostClass[],
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  t: TFunction<"estimation", "printing">
) => {
  const costClassColumns = costClasses.map((costClass, costClassIndex) => {
    const costClassHeader = costClass.costClassCode + ": " + costClass.name;
    const costClassColGroup: ColGroupDef<ComponentSummary> = {
      groupId: `costClasses.${costClass.costClassCode}`,
      headerName: costClassHeader,
      children: [],
    };
    if (costClass.hourlyPricing) {
      const hourCostClassColumns: ColDef<ComponentSummary>[] = [
        {
          field: `costClasses.${costClass.costClassCode}.hoursPerUnit`,
          headerName: t`tableColumns.hoursPerUnit`,
          type: ["twoDecimal"],
          initialWidth: 120,
          aggFunc: null,
          initialHide: isCostClassHidden(costClassIndex),
          valueGetter: getHoursPerUnit,
        },
        {
          field: `costClasses.${costClass.costClassCode}.hours`,
          headerName: t`tableColumns.costClassHours`,
          type: ["twoDecimal"],
          initialAggFunc: "sum",
          initialWidth: 120,
          initialHide: isCostClassHidden(costClassIndex),
          valueGetter: (params) =>
            getCostClassHours(params, gridRef, costClass),
        },
        {
          field: `costClasses.${costClass.costClassCode}.hourlyRate`,
          headerName: t`tableColumns.hourlyRate`,
          type: ["twoDecimal"],
          initialWidth: 120,
          initialHide: isCostClassHidden(costClassIndex),
          valueGetter: hourlyAverageGetter,
          aggFunc: "avgHourlyRate",
          initialAggFunc: "avgHourlyRate",
          allowedAggFuncs: ["avgHourlyRate"],
        },
      ];
      costClassColGroup.children = [...hourCostClassColumns];
    }
    const defaultCostClassColumns: ColDef<ComponentSummary>[] = [
      {
        field: `costClasses.${costClass.costClassCode}.pricePerUnit`,
        headerName: t("tableColumns.priceByUnit", {
          currency: getCurrencyToLocale(
            gridRef.current?.props.context.currency
          ),
        }),
        type: ["money"],
        initialWidth: 140,
        aggFunc: null,
        initialHide: isCostClassHidden(costClassIndex),
        valueGetter: getCostClassPricePerUnit,
      },
      {
        field: `costClasses.${costClass.costClassCode}.totalPriceWithoutSocialExpenses`,
        headerName: t`tableColumns.costClassTotalPrice`,
        type: ["money"],
        initialAggFunc: "sum",
        initialWidth: 140,
        initialHide: isCostClassHidden(costClassIndex),
        valueGetter: (params) =>
          getTotalWithoutSocial(params, gridRef, costClass),
      },
    ];

    // Columns only for costclass with hourly pricing enabled
    if (costClass.hourlyPricing) {
      const socialExpensesCostClassColumns: ColDef<ComponentSummary>[] = [
        {
          field: `costClasses.${costClass.costClassCode}.socialExpenses`,
          headerName: t`tableColumns.socialCost`,
          initialWidth: 160,
          valueGetter: (params) =>
            getCostClassSocialExpenses(params, gridRef, costClass),
          type: ["money"],
          initialHide: isCostClassHidden(costClassIndex),
          initialAggFunc: "sum",
        },
        {
          field: `costClasses.${costClass.costClassCode}.totalPrice`,
          headerName: t`tableColumns.workWithSocialExpenses`,
          initialWidth: 165,
          valueGetter: (params) =>
            getTotalAndSocialExpenses(params, gridRef, costClass),
          type: ["money"],
          initialHide: isCostClassHidden(costClassIndex),
          initialAggFunc: "sum",
        },
      ];

      costClassColGroup.children = [
        ...costClassColGroup.children,
        ...defaultCostClassColumns,
        ...socialExpensesCostClassColumns,
      ];
      return costClassColGroup;
    } else {
      costClassColGroup.children = [...defaultCostClassColumns];
      return costClassColGroup;
    }
  });

  // Column will show the sum of all the rest of costclass prices after the 4th costclass
  const restOfCostClassesSumGroup: ColGroupDef<ComponentSummary> = {
    headerName: t`tableColumns.restOfCostClasses`,
    groupId: "restOfCostClasses",
    children: [
      {
        colId: "restOfCostClassesSummed",
        headerName: t`tableColumns.costClassTotalPrice`,
        type: ["money"],
        initialWidth: 140,
        valueGetter: (params) => getSummedCostClassTotalPrice(params, gridRef),
        initialAggFunc: "sum",
      },
      {
        colId: "restOfCostClassesSummedSocialExpenses",
        headerName: t`tableColumns.socialCost`,
        type: ["money"],
        initialWidth: 140,
        valueGetter: (params) =>
          getSummedCostClassTotalSocialExpenses(params, gridRef),
        initialAggFunc: "sum",
      },
      {
        colId: "restOfCostClassesWithSocialExpensesSummed",
        headerName: t`tableColumns.workWithSocialExpenses`,
        type: ["money"],
        initialWidth: 140,
        valueGetter: (params) =>
          getSummedCostClassTotalWithSocialExpenses(params, gridRef),
        initialAggFunc: "sum",
      },
    ],
  };

  return costClassColumns.concat(restOfCostClassesSumGroup);
};

const isCostClassHidden = (index: number) => index > 3;

const parseCostClassCode = (field: string) =>
  field.substring(field.indexOf(".") + 1, field.lastIndexOf("."));

function getCostClassPricePerUnit(params: ValueGetterParams<ComponentSummary>) {
  if (!params.data || params.node?.rowPinned) {
    return null;
  }
  const { component, costClasses } = params.data;
  const costClassCode = parseCostClassCode(params.column.getId());

  return component.amount !== 0
    ? costClasses[costClassCode].totalPriceWithoutSocialExpenses /
        component.amount
    : 0;
}

function getHoursPerUnit(params: ValueGetterParams<ComponentSummary>) {
  if (!params.data || params.node?.rowPinned) {
    return null;
  }
  const { component, costClasses } = params.data;
  const costClassCode = parseCostClassCode(params.column.getId());
  return component.amount !== 0
    ? costClasses[costClassCode].hours / component.amount
    : 0;
}

export function getTotalAndSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return costClassCalculateTotalPriceSumPinned(gridRef, costClass);
  }
  const costClassPrice = params.data.costClasses[costClass.costClassCode];
  if (!costClass.socialExpensePercentageInCostEstimation || !costClassPrice) {
    return null;
  }
  return (
    costClassPrice.totalPriceWithoutSocialExpenses +
    costClass.socialExpensePercentageInCostEstimation *
      costClassPrice.totalPriceWithoutSocialExpenses
  );
}

function hourlyAverageGetter(params: ValueGetterParams<ComponentSummary>) {
  if (params.node?.group) {
    return;
  }
  if (!params.data || params.node?.rowPinned) {
    return null;
  }

  const costClassCode = parseCostClassCode(params.column.getId());
  const { costClasses, hours } = params.data;
  const price = costClasses[costClassCode].totalPriceWithoutSocialExpenses;

  return hours === 0 ? "" : safeDivide(price, hours);
}

export function getCostClassSocialCosts(
  params: ValueGetterParams<ComponentSummary>,
  costClass: CostClass
) {
  if (!params.data || params.node?.rowPinned) {
    return null;
  }
  const costClassPrice = params.data.costClasses[costClass.costClassCode];
  if (!costClass.socialExpensePercentageInCostEstimation || !costClassPrice) {
    return null;
  }
  return (
    costClass.socialExpensePercentageInCostEstimation *
    costClassPrice.totalPriceWithoutSocialExpenses
  );
}

export function avgHourlyRate(params: IAggFuncParams<ComponentSummary>) {
  const numberValues = params.values.filter((v) => Number(v));
  const amount = numberValues.length;
  if (amount === 0) {
    return 0;
  }
  const total = numberValues.reduce(
    (acc, currentValue) => acc + currentValue,
    0
  );
  return safeDivide(total, amount);
}

export function getSummedCostClassTotalPrice(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateCorrectPinnedRowOfRestOfCostClasses(
      params.node?.data?.id,
      gridRef
    );
  }
  const { costClasses } = params.data;
  const totalPriceByCostClasses = Object.values(costClasses);
  return totalPriceByCostClasses
    .slice(4)
    .reduce(
      (acc: number, currentValue: TotalPriceByCostClassCode) =>
        acc + currentValue.totalPriceWithoutSocialExpenses,
      0
    );
}

export function getSummedCostClassTotalSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateTotalPinned(
      gridRef,
      "restOfCostClassesSummedSocialExpenses"
    );
  }
  const costClasses: CostClass[] = params.context.costClasses;
  const rowCostClasses = Object.values(params.data.costClasses);
  const restOfCostClasses = costClasses.slice(4);
  const costClassesWithSocialExpenses = restOfCostClasses.map((costClass) => {
    const currentCostClass = rowCostClasses.find(
      (cc) => cc.costClassCode === costClass.costClassCode
    );
    if (costClass.hourlyPricing) {
      const socialExpenses =
        costClass.socialExpensePercentageInCostEstimation &&
        currentCostClass?.totalPriceWithoutSocialExpenses
          ? costClass.socialExpensePercentageInCostEstimation *
            currentCostClass?.totalPriceWithoutSocialExpenses
          : 0;
      return { ...currentCostClass, socialExpenses: socialExpenses };
    }
    return { ...currentCostClass, socialExpenses: null };
  });
  if (costClassesWithSocialExpenses) {
    return costClassesWithSocialExpenses.reduce((acc, currentValue) => {
      if (currentValue.socialExpenses) {
        return acc + currentValue.socialExpenses;
      }
      return acc;
    }, 0);
  }
  return null;
}

export function getSummedCostClassTotalWithSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateCorrectPinnedRowOfRestOfCostClasses(
      params.node?.data?.id,
      gridRef
    );
  }
  const costClasses: CostClass[] = params.context.costClasses;
  const rowCostClasses = Object.values(params.data.costClasses);
  const restOfCostClasses = costClasses.slice(4);
  const costClassesWithSocialExpenses = restOfCostClasses.map((costClass) => {
    const currentCostClass = rowCostClasses.find(
      (cc) => cc.costClassCode === costClass.costClassCode
    );
    if (costClass.hourlyPricing) {
      const totalPrice =
        currentCostClass?.totalPriceWithoutSocialExpenses &&
        costClass.socialExpensePercentageInCostEstimation
          ? currentCostClass.totalPriceWithoutSocialExpenses *
            (1 + costClass.socialExpensePercentageInCostEstimation)
          : currentCostClass?.totalPrice;
      return { ...currentCostClass, totalPrice: totalPrice };
    }
    return { ...currentCostClass };
  });
  return costClassesWithSocialExpenses.reduce((acc, currentValue) => {
    if (currentValue.totalPrice) {
      return acc + currentValue.totalPrice;
    }
    return acc;
  }, 0);
}

function calculateCorrectPinnedRow(
  pinnedRowId: string | undefined,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) {
  if (pinnedRowId === "totalRow") {
    return costClassCalculateTotalPriceWithoutSocialExpensesSumPinned(
      gridRef,
      costClass
    );
  }
  if (pinnedRowId === "totalRowWithSocial") {
    return costClassCalculateTotalPriceSumPinned(gridRef, costClass);
  }
}

function calculateCorrectPinnedRowOfRestOfCostClasses(
  pinnedRowId: string | undefined,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (pinnedRowId === "totalRow") {
    if (gridRef.current) {
      let sum = 0;
      gridRef.current.api.forEachNodeAfterFilter((node) => {
        if (!node.group) {
          const total = gridRef.current?.api.getCellValue({
            colKey: `restOfCostClassesSummed`,
            rowNode: node,
          });
          sum += total;
        }
      });
      return sum;
    }
    return null;
  }
  if (pinnedRowId === "totalRowWithSocial") {
    if (gridRef.current) {
      let totalPrice = 0;
      let socialExpenses = 0;
      gridRef.current.api.forEachNodeAfterFilter((node) => {
        if (!node.group) {
          const total = gridRef.current?.api.getCellValue({
            colKey: `restOfCostClassesSummed`,
            rowNode: node,
          });
          totalPrice += total;
          const social = gridRef.current?.api.getCellValue({
            colKey: `restOfCostClassesSummedSocialExpenses`,
            rowNode: node,
          });
          socialExpenses += social;
        }
      });
      const total = totalPrice + socialExpenses;
      return total ?? null;
    }
    return null;
  }
}

export function getCostClassHours(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) {
  if (params.node?.rowPinned) {
    return calculateTotalPinned(
      gridRef,
      `costClasses.${costClass.costClassCode}.hours`
    );
  }
  return params.data?.costClasses[costClass.costClassCode].hours;
}

export function getTotalWithoutSocial(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateCorrectPinnedRow(params.node.data?.id, gridRef, costClass);
  }
  return params.data?.costClasses[costClass.costClassCode]
    .totalPriceWithoutSocialExpenses;
}

export const costClassCalculateTotalPriceWithoutSocialExpensesSumPinned = (
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) => {
  if (gridRef.current) {
    let sum = 0;
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        const total = gridRef.current?.api.getCellValue({
          colKey: `costClasses.${costClass.costClassCode}.totalPriceWithoutSocialExpenses`,
          rowNode: node,
        });
        sum += total;
      }
    });
    return sum;
  }
  return null;
};

export const costClassCalculateTotalPriceSumPinned = (
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) => {
  if (gridRef.current) {
    let totalPrice = 0;
    let socialExpenses = 0;
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        const total = gridRef.current?.api.getCellValue({
          colKey: `costClasses.${costClass.costClassCode}.totalPriceWithoutSocialExpenses`,
          rowNode: node,
        });
        totalPrice += total;
        const social = gridRef.current?.api.getCellValue({
          colKey: `costClasses.${costClass.costClassCode}.socialExpenses`,
          rowNode: node,
        });
        socialExpenses += social;
      }
    });
    const total = totalPrice + socialExpenses;
    return total ?? null;
  }
  return null;
};

export function getCostClassSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  costClass: CostClass
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateTotalPinned(
      gridRef,
      `costClasses.${costClass.costClassCode}.socialExpenses`
    );
  }
  return calculateTotalSocialExpenses(
    params.data.costClasses,
    params.context.costClasses
  );
}
