import ExcelJS from "exceljs";
import { Button, IconFileDownloadV1 } from "@tocoman/ui";
import { TFunction, useTranslation } from "react-i18next";
import { ProjectId } from "../../../../../ts-bindings/ProjectId";
import { downloadBuffer } from "../../../utils/downloadBuffer";

import {
  getProjectDetails,
  getProjectLocations,
  getResourceDetails,
  LocationDto,
  MAX_PAGE_LIMIT,
  ProjectDto,
  ResourceDto,
  ResourceLocationDto,
} from "./resourcesRestApiClient";

type Props = {
  projectId: ProjectId;
  includeSocialExpenses: boolean;
};

type ResourceExcelBaseRow = {
  workItemGroup: string;
  costGroupCode: string;
  workItemCode: string;
  workItemDescription: string;
  workItemQuantity: string | number;
  workItemUnit: string;
  costType: string;
  resourceGroup: string;
  sequenceNumber: string | number;
  id: string | number;
  name: string;
  consumptionRate: string | number;
  wastePercentage: string | number;
  resourceQuantitity: string | number;
  resourceUnit: string;
  priceWithoutDiscount: string | number;
  pricePerUnit: string | number;
  discountPercentage: string | number;
  priceInclDiscount: string | number;
  totalPriceWithDiscount: string | number;
  currency: string;
  index: string | number;
  vatPercentage: string | number;
  eanCode: string;
  internalId: string | number;
  supplier: string;
  supplierCode: string;
  salesBatchUnit: string;
  salesBatchNetContent: string | number;
  projectCode: string;
  version: string;
  subProjectCode: string;
};

/**
 * Convenience function to get excel row values as array
 * since Object.values() does not promise static property order
 */
function getBaseRowAsOrderedArray(data: ResourceExcelBaseRow) {
  return [
    data.workItemGroup,
    data.costGroupCode,
    data.workItemCode,
    data.workItemDescription,
    data.workItemQuantity,
    data.workItemUnit,
    data.costType,
    data.resourceGroup,
    data.sequenceNumber,
    data.id,
    data.name,
    data.consumptionRate,
    data.wastePercentage,
    data.resourceQuantitity,
    data.resourceUnit,
    data.priceWithoutDiscount,
    data.pricePerUnit,
    data.discountPercentage,
    data.priceInclDiscount,
    data.totalPriceWithDiscount,
    data.currency,
    data.index,
    data.vatPercentage,
    data.eanCode,
    data.internalId,
    data.supplier,
    data.supplierCode,
    data.salesBatchUnit,
    data.salesBatchNetContent,
    data.projectCode,
    data.version,
    data.subProjectCode,
  ];
}

function getOrderedBaseHeaderRow(t: TFunction) {
  const headerRow: ResourceExcelBaseRow = {
    workItemGroup: t("workItemGroup"),
    costGroupCode: t("costGroupCode"),
    workItemCode: t("workItemCode"),
    workItemDescription: t("workItemDescription"),
    workItemQuantity: t("workItemQuantity"),
    workItemUnit: t("workItemUnit"),
    costType: t("RT"),
    resourceGroup: t("resourceGroup"),
    sequenceNumber: t("sequenceNumber"),
    id: t("id"),
    name: t("rtName"),
    consumptionRate: t("consumptionRate"),
    wastePercentage: t("wastePercentage"),
    resourceQuantitity: t("resourceQuantity"),
    resourceUnit: t("resourceUnit"),
    priceWithoutDiscount: t("priceWithoutDiscount"),
    pricePerUnit: t("pricePerUnit"),
    discountPercentage: t("discountPercentage"),
    priceInclDiscount: t("priceInclDiscount"),
    totalPriceWithDiscount: t("totalPriceWithDiscount"),
    currency: t("currency"),
    index: t("index"),
    vatPercentage: t("vatPercentage"),
    eanCode: t("eanCode"),
    internalId: t("internalId"),
    supplier: t("supplier"),
    supplierCode: t("supplierCode"),
    salesBatchUnit: t("salesBatchUnit"),
    salesBatchNetContent: t("salesBatchNetContent"),
    projectCode: t("project"),
    version: t("version"),
    subProjectCode: t("subproject"),
  };

  return getBaseRowAsOrderedArray(headerRow);
}

function getOrderedBaseRow(
  resource: ResourceDto,
  projectDetailsData: ProjectDto
) {
  const baseRow: ResourceExcelBaseRow = {
    workItemGroup: resource.group ?? "",
    costGroupCode: resource.costGroup ?? "",
    workItemCode: resource.workItemCode,
    workItemDescription: resource.workItemDescription ?? "",
    workItemQuantity: resource.calculations.workItemQuantity,
    workItemUnit: resource.workItemUnit ?? "",
    costType: resource.costType ?? "",
    resourceGroup: resource.resourceGroup ?? "",
    sequenceNumber: resource.sortingNumber ?? "",
    id: resource.id,
    name: resource.name ?? "",
    consumptionRate: resource.consumption ?? "",
    wastePercentage: resource.additionalPercentage ?? "",
    resourceQuantitity: resource.calculations.quantity,
    resourceUnit: resource.unit ?? "",
    priceWithoutDiscount: resource.calculations.price,
    pricePerUnit: resource.calculations.pricePerUnit,
    discountPercentage: resource.discountPercentage,
    priceInclDiscount: resource.calculations.pricePerQuantity,
    totalPriceWithDiscount: resource.calculations.totalPriceWithDiscount,
    currency: projectDetailsData.currency,
    index: resource.index ?? "",
    vatPercentage: resource.vatPercentage ?? "",
    eanCode: resource.eanCode ?? "",
    internalId: resource.internalPricelistId ?? "",
    supplier: resource.supplier ?? "",
    supplierCode: resource.supplierCode ?? "",
    salesBatchUnit: resource.salesBatchUnit ?? "",
    salesBatchNetContent: resource.salesBatchNetContent ?? "",
    projectCode: projectDetailsData.code,
    version: projectDetailsData.version,
    subProjectCode: resource.subProjectCode,
  };
  return getBaseRowAsOrderedArray(baseRow);
}

const exportResourcesByLocationsExcel = async (
  t: TFunction,
  includeSocialExpenses: boolean,
  projectId: number
) => {
  const projectDetailsData: ProjectDto = await getProjectDetails(projectId);
  const projectLocationsData: LocationDto[] = (
    await getProjectLocations(projectId, { limit: MAX_PAGE_LIMIT })
  ).results;
  const resourcesWithLocationsData = (
    await getResourceDetails(projectId, {
      limit: MAX_PAGE_LIMIT,
      includeSocialExpenses,
    })
  ).results;

  const workbook = new ExcelJS.Workbook();
  const quantityWorksheet = workbook.addWorksheet(
    t("resourcesQuantityByLocation")
  );
  const costsWorksheet = workbook.addWorksheet(t("resourcesCostsByLocation"));

  /** Add headers */
  quantityWorksheet.addRow([
    `${t("resourcesQuantityByLocation")} - ${new Date().toLocaleDateString(
      "fi-FI"
    )}`,
  ]);
  quantityWorksheet.addRow([
    `${projectDetailsData.name}, v. ${projectDetailsData.version}`,
  ]);
  quantityWorksheet.addRow([
    includeSocialExpenses
      ? t("includeSocialExpenses")
      : t("excludeSocialExpenses"),
  ]);
  quantityWorksheet.addRow([]);

  costsWorksheet.addRow([
    `${t("resourcesCostsByLocation")} - ${new Date().toLocaleDateString(
      "fi-FI"
    )}`,
  ]);
  costsWorksheet.addRow([
    `${projectDetailsData.name}, v. ${projectDetailsData.version}`,
  ]);
  costsWorksheet.addRow([
    includeSocialExpenses
      ? t("includeSocialExpenses")
      : t("excludeSocialExpenses"),
  ]);
  costsWorksheet.addRow([]);

  const headerRow = getOrderedBaseHeaderRow(t);

  const locationColumns = projectLocationsData.map((location: LocationDto) => {
    return location.code;
  });

  quantityWorksheet.addRow([...headerRow, ...locationColumns]);
  costsWorksheet.addRow([...headerRow, ...locationColumns]);

  /** Add data rows */
  resourcesWithLocationsData.forEach((resource: ResourceDto) => {
    const baseRow = getOrderedBaseRow(resource, projectDetailsData);

    const quantitiesByLocations = projectLocationsData.map(
      (location: LocationDto) => {
        const resourceLocation = resource.locations.find(
          (l: ResourceLocationDto) => l.code === location.code
        );
        return resourceLocation ? resourceLocation.quantity : 0;
      }
    );

    const pricesByLocations = projectLocationsData.map(
      (location: LocationDto) => {
        const resourceLocation = resource.locations.find(
          (l: ResourceLocationDto) => l.code === location.code
        );
        return resourceLocation ? resourceLocation.price : 0;
      }
    );

    quantityWorksheet.addRow([...baseRow, ...quantitiesByLocations]);
    costsWorksheet.addRow([...baseRow, ...pricesByLocations]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${projectDetailsData.name}-${
    projectDetailsData.version
  }-${t("resourcesByLocation")}`;
  downloadBuffer(buffer, filename);
};

const exportResourcesByLocationRowsExcel = async (
  t: TFunction,
  includeSocialExpenses: boolean,
  projectId: number
) => {
  const projectDetailsData: ProjectDto = await getProjectDetails(projectId);

  const resourcesWithLocationsData = (
    await getResourceDetails(projectId, {
      limit: MAX_PAGE_LIMIT,
      includeSocialExpenses,
    })
  ).results;

  const workbook = new ExcelJS.Workbook();
  const locationRowBasedWorksheet = workbook.addWorksheet(
    t("resourcesCostsByLocationRowBased")
  );

  locationRowBasedWorksheet.addRow([
    `${t("resourcesCostsByLocationRowBased")} - ${new Date().toLocaleDateString(
      "fi-FI"
    )}`,
  ]);
  locationRowBasedWorksheet.addRow([
    `${projectDetailsData.name}, v. ${projectDetailsData.version}`,
  ]);
  locationRowBasedWorksheet.addRow([
    includeSocialExpenses
      ? t("includeSocialExpenses")
      : t("excludeSocialExpenses"),
  ]);
  locationRowBasedWorksheet.addRow([]);

  const rowBasedHeaderRow = getOrderedBaseHeaderRow(t);

  locationRowBasedWorksheet.addRow([
    ...rowBasedHeaderRow,
    t("location"),
    t("costOfLocation"),
    t("quantityOfLocation"),
  ]);

  /** Add data rows */
  resourcesWithLocationsData.forEach((resource: ResourceDto) => {
    const baseRow = getOrderedBaseRow(resource, projectDetailsData);

    const locationRows = resource.locations.map((location) => {
      return [...baseRow, location.code, location.price, location.quantity];
    });

    locationRows.forEach((locationRow) => {
      locationRowBasedWorksheet.addRow([...locationRow]);
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${projectDetailsData.name}-${
    projectDetailsData.version
  }-${t("resourcesCostsByLocationRowBased")}`;
  downloadBuffer(buffer, filename);
};

export const ResourcesByLocationExportButton = ({
  projectId,
  includeSocialExpenses,
}: Props) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "resourcesByLocationsExcelExport",
  });

  const handleOnClick = () => {
    exportResourcesByLocationsExcel(t, includeSocialExpenses, projectId);
  };

  /** Use two buttons since single button with icon and text broke the button layout. This has probably something to do with surrounding purescript view. */
  return (
    <div onClick={handleOnClick} className="flex">
      <Button icon={IconFileDownloadV1} variant={"text"} />
      <Button variant={"text"}>{t("exportToExcel")}</Button>
    </div>
  );
};

export const ResourcesByLocationsRowBasedExportButton = ({
  projectId,
  includeSocialExpenses,
}: Props) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "resourcesByLocationsExcelExport",
  });

  const handleOnClick = () => {
    exportResourcesByLocationRowsExcel(t, includeSocialExpenses, projectId);
  };

  return (
    <div onClick={handleOnClick} className="flex">
      <Button icon={IconFileDownloadV1} variant={"text"} />
      <Button variant={"text"}>{t("exportToExcelRowBased")}</Button>
    </div>
  );
};
