import { useEffect, useRef, useState } from "react";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { DataSourceSelector } from "./DataSourceSelector";
import { ParameterSelector } from "./ParameterSelector";
import { ProjectSelector } from "./ProjectSelector";
import { AllReportParametersData } from "../../../Estimation/Report/types";
import { reportError } from "src/client-ts/utils/error";
import { useDefault } from "../../../../hooks/useDefault";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { Button, ToggleVisibilityButton, IconLtV1, Header } from "@tocoman/ui";
import { ParameterOption } from "../../../Estimation/Report/types";
import { useAllParameters } from "../../../Estimation/Report/hooks/useAllParameters";
import { getParameterValuesFromFormData } from "../../../Estimation/Report/utils";
import { ParameterValueMap } from "../../../Estimation/Report/types";
import { getDataSourceNames, getDataSetNames } from "../utils/dataSourceUtils";
import { dataSourceDefinitions, getDataSetDefinitions } from "../datasources";
import { ParameterForm } from "../../../Estimation/Report/ParameterForm";
import { SelectItem } from "@tocoman/ui";
import { useLocalStorage } from "usehooks-ts";

export type ReportDesignerForm = {
  project?: SelectItem<string>;
  dataSources?: string[];
  parameters?: ParameterOption[];
} & AllReportParametersData;

export type ReportDesignerSettingsProps = {
  report: ARJS.Report;
  name: string;
  updateReportTemplate: (
    dataSourceNameList: string[],
    parameters: ParameterOption[],
    parameterValues: ParameterValueMap
  ) => void;
};

export const ReportDesignerSettings = ({
  report,
  name,
  updateReportTemplate,
}: ReportDesignerSettingsProps) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const [projectFromStorage, setProjectToStorage] = useLocalStorage<
    string | null
  >("activeReportsDesignerProject", null);

  const parseStoredProject = () => {
    if (typeof projectFromStorage !== "string") {
      return null;
    }
    return JSON.parse(projectFromStorage);
  };

  const storedProject = parseStoredProject();

  const projects = useDefault(useWorkerState("EstimationProjectsState"), []);
  const allParameters = useAllParameters();
  const targetRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<ReportDesignerForm>({
    defaultValues: {
      project: storedProject?.value ? storedProject : undefined,
    },
  });
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [parametersLength, setParametersLength] = useState(0);
  const { handleSubmit, reset, watch } = methods;

  const dataSourceDefinitioNames = Object.keys(dataSourceDefinitions);
  const dataSetDefinitioNames = getDataSetDefinitions;

  const watchProject = watch("project");

  useEffect(() => {
    if (watchProject?.value) {
      setProjectToStorage(JSON.stringify(watchProject));
      setSelectedProject(watchProject.value);
    }
  }, [watchProject]);

  const watchParameters = watch("parameters");

  useEffect(() => {
    setParametersLength(watchParameters?.length || 0);
  }, [watchParameters]);

  const hasParameters = parametersLength > 0;

  const projectSelectItems: SelectItem<string>[] = projects.map(
    ({ name, id }) => ({
      key: id,
      value: id.toString(),
      label: name,
    })
  );

  useEffect(() => {
    // Use only dataSources, which exists in the data source definitions. Skip custom ones
    const activeDataSources = getDataSourceNames(
      report.DataSources
    ).filter((ds) => dataSourceDefinitioNames.some((d) => ds === d));

    const activeDataSets = getDataSetNames(report.DataSets).filter((ds) =>
      dataSetDefinitioNames.some((d) => ds === d)
    );

    const activeParameters = report.ReportParameters
      ? allParameters.filter(({ key }) =>
          report.ReportParameters?.some(({ Name }) => Name === key)
        )
      : [];

    // remove duplicates using `Set`
    const mergedDataSources = [
      ...new Set([...activeDataSources, ...activeDataSets]),
    ];

    const projectIdParameter = report?.ReportParameters?.find(
      ({ Name }) => Name === "projectId"
    );
    const activeProjectId = projectIdParameter
      ? projectIdParameter.DefaultValue?.Values?.toString()
      : null;
    const activeProjectSelectItem = projectSelectItems.find(
      ({ value }) => value === activeProjectId
    );

    reset({
      dataSources: mergedDataSources,
      parameters: activeParameters,
      project: activeProjectSelectItem,
    });
  }, [report]);

  const handlePreviewSubmit = (data: ReportDesignerForm) => {
    const newDataSources = data.dataSources || [];
    const newParameters = data.parameters || [];

    if (data.project) {
      const parameterValues: ParameterValueMap = {
        projectId: [data.project.value],
        ...getParameterValuesFromFormData(data),
      };

      updateReportTemplate(newDataSources, newParameters, parameterValues);
    } else {
      reportError(t("projectIdMissing"), new Error(t("projectIdMissing")));
    }
  };

  return (
    <div className="flex w-full h-full">
      <div
        className={"min-h-full max-h-full h-full overflow-y-auto"}
        ref={targetRef}
      >
        <div className="flex flex-row w-[600px] p-3 min-h-full gap-5">
          <FormProvider {...methods}>
            <div className="flex flex-col gap-3 w-1/2 min-h-full pt-3">
              <Header titleSize={"small"} title={name} />
              <ProjectSelector projects={projectSelectItems} />

              <DataSourceSelector />

              <ParameterSelector />

              <Button
                className="w-48 mt-3"
                onClick={handleSubmit((d) => handlePreviewSubmit(d))}
                label={t("setSettings")}
              />
            </div>
            <div className={"w-1/2"}>
              {hasParameters && selectedProject && (
                <ParameterForm
                  reportParameters={report.ReportParameters}
                  selectedParameters={watchParameters}
                  projectId={Number(selectedProject)}
                />
              )}
            </div>
          </FormProvider>
        </div>
      </div>
      <div className={"border-l border-l-light h-full z-50"}>
        <ToggleVisibilityButton
          icon={IconLtV1}
          targetRef={targetRef}
          className={"ml-[-12px] mt-3"}
          onClick={handleSubmit((d) => handlePreviewSubmit(d))}
        />
      </div>
    </div>
  );
};
