function getValueByIndex(array: Array<string | number>, index: number) {
  return array[index];
}

export const reportCustomCodes = [
  {
    name: "GetValueByIndex",
    body: getValueByIndex,
    info: {
      description: "Get value by index",
      example: "Code.getValueByIndex(Fields!values.Value, 1)",
      syntax: "Code.getValueByIndex(<Array>, <number>)",
    },
  },
];
