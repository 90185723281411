import { useCallback, useMemo, useState } from "react";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { Button, Header, IconEditV1 } from "@tocoman/ui";
import {
  AppPermission,
  EditUserRolesModal,
  UserRolesData,
} from "../EditUserRolesModal/EditUserRolesModal";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { AdminUserTableRow } from "ts-bindings/AdminUserTableRow";
import { User } from "ts-bindings/User";
import { useDefault } from "src/client-ts/hooks/useDefault";
import { Table } from "../../../components/Table";
import {
  ColDef,
  ICellRendererParams,
  ISetFilterParams,
  StatusPanelDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { BulkUserDeleteStatusPanelButton } from "../BulkUserDelete/BulkUserDeleteStatusPanelButton";
import {
  LicenseFeature,
  useLicenseFeatureEnabled,
} from "../../../LicenseFeatures/useLicenseFeature";
import { AddUsersButton } from "./AddUsersButton";
import {
  getUserRolesData,
  useGetUserRoleData,
} from "../EditUserRolesModal/useUserFormDefaultValues";

type Permissions = {
  allPermissions: boolean;
  costClassTargetEdit: boolean;
  createProjectsPermission: boolean;
  mainUser: boolean;
  costControl: AppPermission;
  estimation: AppPermission;
};
export type PermissionUser = {
  user: User;
  permissions: Permissions;
};

function mapToPermissionUsers(
  users: AdminUserTableRow[] | null,
  permissions: UserRolesData[]
): PermissionUser[] | null {
  if (!users) {
    return null;
  }
  return users
    .filter((user) => {
      const userPermissions = permissions.some(
        (permission) => permission.userId === user.user.id
      );
      if (!userPermissions) {
        return false;
      }
      return true;
    })
    .map((user) => {
      // We made sure that the permissions exist in the filter above, cannot be undefined
      const userPermissions = permissions.find(
        (permission) => permission.userId === user.user.id
      ) as UserRolesData;
      return {
        user: user.user,
        permissions: {
          allPermissions: userPermissions.allPermissions,
          costClassTargetEdit: userPermissions.costClassTargetEdit,
          createProjectsPermission: userPermissions.createProjectsPermission,
          mainUser: userPermissions.mainUser,
          costControl: {
            appPermission: userPermissions.costControl?.appPermission,
            readAll: userPermissions.costControl?.readAll,
            writeAll: userPermissions.costControl?.writeAll,
            project: userPermissions.costControl?.project,
            projectGroup: userPermissions.costControl?.projectGroup,
          },
          estimation: {
            appPermission: userPermissions.estimation?.appPermission,
            readAll: userPermissions.estimation?.readAll,
            writeAll: userPermissions.estimation?.writeAll,
            project: userPermissions.estimation?.project,
            projectGroup: userPermissions.estimation?.projectGroup,
          },
        },
      };
    });
}

export const AdminUsers = () => {
  const [userEditData, setUserEditData] = useState<User | null>(null);
  const [userEditOpen, setUserEditOpen] = useState(false);
  const closeUserEdit = () => setUserEditOpen(false);
  const { t } = useTranslation("admin", { keyPrefix: "users" });
  const adminUserTableData = useDefault(
    useWorkerState("AdminUserTableState"),
    []
  );
  const adminUsers = useWorkerState("AdminUsersState");

  const [
    roleIds,
    roleAssignments,
    costControlProjects,
    projectGroups,
    estimationProjects,
  ] = useGetUserRoleData();
  const roles = useMemo(() => {
    return adminUsers?.map((user) => {
      return getUserRolesData(
        user,
        roleIds,
        roleAssignments,
        costControlProjects,
        projectGroups,
        estimationProjects
      );
    });
  }, [
    adminUsers,
    roleIds,
    roleAssignments,
    costControlProjects,
    projectGroups,
    estimationProjects,
  ]);

  const usersPermissions = useMemo(() => {
    return mapToPermissionUsers(adminUserTableData, roles ?? []);
  }, [adminUserTableData, roles]);

  const adminEnabled = useLicenseFeatureEnabled(LicenseFeature.ADMIN);

  const handleEditUserClick = useCallback(
    (id: string) => {
      const user = adminUsers?.find((it) => it.id === id) ?? null;
      setUserEditData(user);
      setUserEditOpen(true);
    },
    [adminUsers]
  );

  const adminOrganizationInfoState = useWorkerState(
    "AdminOrganizationInfoState"
  );

  const booleanFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return params.value ? t`yes` : t`no`;
    },
    [t]
  );

  const booleanFilterFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return params.value === true ? t`yes` : t`no`;
    },
    [t]
  );

  const columnDefs: ColDef<PermissionUser>[] = useMemo(
    () => [
      {
        field: "user.userData.email",
        headerName: t`email`,
        enableRowGroup: true,
      },
      {
        field: "permissions.costControl.appPermission",
        headerName: t`cost_control`,
        enableRowGroup: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getAppPerms(params, t, "costControl");
        },
        hide: !adminEnabled,
        suppressColumnsToolPanel: !adminEnabled,
      },
      {
        field: "permissions.estimation.appPermission",
        headerName: t`cost_estimation`,
        enableRowGroup: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getAppPerms(params, t, "estimation");
        },
      },
      {
        field: "permissions.createProjectsPermission",
        headerName: t`create_project`,
        enableRowGroup: true,
        valueFormatter: booleanFormatter,
        filter: "agSetColumnFilter",
        cellDataType: "text",
        filterParams: {
          valueFormatter: booleanFilterFormatter,
        } as ISetFilterParams,
        hide: !adminEnabled,
        suppressColumnsToolPanel: !adminEnabled,
      },
      {
        colId: "estimationProjects",
        headerName: t`estimationProjects`,
        enableRowGroup: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getProjectPerms(params, t, "estimation");
        },
        hide: true,
      },
      {
        colId: "controlProjects",
        headerName: t`controlProjects`,
        enableRowGroup: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getProjectPerms(params, t, "costControl");
        },
        hide: true,
        suppressColumnsToolPanel: !adminEnabled,
      },
      {
        colId: "estimationProjectGroups",
        headerName: t`estimationProjectGroups`,
        enableRowGroup: true,
        hide: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getProjectGroupPerms(params, t, "estimation");
        },
        suppressColumnsToolPanel: !adminEnabled,
      },
      {
        colId: "costControlProjectGroups",
        headerName: t`costControlProjectGroups`,
        enableRowGroup: true,
        hide: true,
        valueGetter: (params: ValueGetterParams<PermissionUser>) => {
          return getProjectGroupPerms(params, t, "costControl");
        },
        suppressColumnsToolPanel: !adminEnabled,
      },
      {
        field: "permissions.mainUser",
        headerName: t`adminRights`,
        enableRowGroup: true,
        valueFormatter: booleanFormatter,
        cellDataType: "text",
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: booleanFilterFormatter,
        } as ISetFilterParams,
      },
      {
        field: "user.id",
        headerName: t`userId`,
        hide: true,
      },
      {
        colId: "edit",
        headerName: "",
        pinned: "right",
        width: 106,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        cellRenderer: (cellData: ICellRendererParams) => {
          return (
            <div className="flex items-center h-full">
              {!cellData.node.group && (
                <>
                  <Button
                    icon={IconEditV1}
                    onClick={() => handleEditUserClick(cellData.data.user.id)}
                    variant="text"
                  />
                </>
              )}
            </div>
          );
        },
      },
    ],
    [
      t,
      adminEnabled,
      booleanFormatter,
      booleanFilterFormatter,
      handleEditUserClick,
    ]
  );

  const defaultColDef: ColDef<PermissionUser> = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: AddUsersButton,
          statusPanelParams: {
            handleEditUserClick: handleEditUserClick,
          },
        },
        {
          statusPanel: BulkUserDeleteStatusPanelButton,
        },
      ],
    };
  }, [handleEditUserClick]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: t`columns`,
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
      ],
    };
  }, [t]);

  return (
    <>
      <Header title={t`title`}>
        <p>
          <Trans
            ns="admin"
            i18nKey="users.description"
            values={{ organization: adminOrganizationInfoState?.organization }}
          />
        </p>
      </Header>

      <Table<PermissionUser>
        className="w-full h-full"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={usersPermissions}
        rowSelection={{
          mode: "multiRow",
          enableClickSelection: false,
          checkboxes: true,
          selectAll: "filtered",
        }}
        rowGroupPanelShow={"always"}
        getRowId={(row) => row.data.user.id}
        statusBar={statusBar}
        sideBar={sideBar}
      />
      {userEditOpen ? (
        <EditUserRolesModal
          isOpen={userEditOpen}
          user={userEditData}
          closeFn={closeUserEdit}
        />
      ) : null}
    </>
  );
};

type PickAppPermissions<T> = {
  [K in keyof T]: T[K] extends AppPermission ? K : never;
}[keyof T];
type AppKeys = PickAppPermissions<Permissions>;

export function getProjectPerms(
  params: ValueGetterParams<PermissionUser>,
  t: TFunction<"admin", "users">,
  app: AppKeys
) {
  if (!params.data) {
    return null;
  }

  const { writeAll, readAll, project } = params.data.permissions[app];
  if (writeAll || readAll) {
    return t`allProjectNames`;
  }

  const projectsWithRead = project.filter((project) => project.readPermission);
  if (projectsWithRead.length === 0) {
    return "";
  }
  if (projectsWithRead.length === 1) {
    return "1 " + t`projectNames_one`;
  } else {
    return `${projectsWithRead.length} ${t("projectNames", {
      count: 2,
    })}`;
  }
}

export function getProjectGroupPerms(
  params: ValueGetterParams<PermissionUser>,
  t: TFunction<"admin", "users">,
  app: AppKeys
) {
  if (!params.data) {
    return null;
  }
  const { projectGroup } = params.data.permissions[app];
  const projectGroupsWithRead = projectGroup.filter((it) => it.readPermission);

  if (projectGroupsWithRead.length < 4) {
    return projectGroupsWithRead.map((it) => it.name).join(", ");
  } else {
    return `${projectGroupsWithRead.length} ${t("projectGroups", {
      count: 2,
    })}`;
  }
}

export function getAppPerms(
  params: ValueGetterParams<PermissionUser>,
  t: TFunction<"admin", "users">,
  app: AppKeys
) {
  if (!params.data) {
    return null;
  }
  const {
    appPermission,
    writeAll,
    readAll,
    project,
    projectGroup,
  } = params.data.permissions[app];
  if (!appPermission) {
    return t`no`;
  }
  if (writeAll) {
    return t`read` + ", " + t`write`;
  }
  const writePermFromProject = project.some((it) => it.writePermission);
  const writePermFromProjectGroup = projectGroup.some(
    (it) => it.writePermission
  );
  if (writePermFromProject || writePermFromProjectGroup) {
    return t`read` + ", " + t`write`;
  }
  if (readAll) {
    return t`read`;
  }
  const readPermFromProject = project.some((it) => it.readPermission);
  const readPermFromProjectGroup = projectGroup.some((it) => it.readPermission);
  if (readPermFromProject || readPermFromProjectGroup) {
    return t`read`;
  }
  return t`read`;
}
