// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Containers_DixonContainer_DixonFiles = require("../Containers.DixonContainer.DixonFiles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_ElementsEditView = require("../CostEstimation.ElementsEditView/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Dixon_EditorFFI = require("../Dixon.EditorFFI/index.js");
var DixonContainer_Actions = require("../DixonContainer.Actions/index.js");
var DixonMeasurementGroupComponents = require("../DixonMeasurementGroupComponents/index.js");
var DixonMeasurementGroupElements = require("../DixonMeasurementGroupElements/index.js");
var DixonMeasurementGroups = require("../DixonMeasurementGroups/index.js");
var DixonMeasurements = require("../DixonMeasurements/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var DixonToolbar = require("../DixonToolbar/index.js");
var EditView = require("../EditView/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var Viewport = require("../Viewport/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var Web_HTML_HTMLDivElement = require("../Web.HTML.HTMLDivElement/index.js");
var component = TofuHooks.mkHookComponent("DixonContainer")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var wasmModule = Client_WASM.getWasmModule(Data_Unit.unit);
        var elementsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Elements.value)();
        var v2 = UseLocalStorage.useLocalStorageJSON(TofuJSON.tofuJSONMap(Data_Ord.ordString)(TofuJSON.readString)(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "drawingId";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "fileId";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "measurementGroupId";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "drawingId";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "fileId";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "measurementGroupId";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))("ui:json:dixon_stored_url_params")(Data_Map_Internal.empty)(false)();
        var urlParamsKey = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Organization.Organization))(function (v3) {
            return v3.organization;
        }))(v1.value0)) + ("-" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Project.ProjectId)(v.projectId)));
        var organization = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Organization.Organization))(function (v3) {
            return v3.organization;
        }))(v1.value0));
        var currentUrlParams = Data_Map_Internal.lookup(Data_Ord.ordString)(urlParamsKey)(v2.value0);
        var mDrawingId = (function () {
            if (v.mDrawingId instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(v.mDrawingId.value0);
            };
            if (v.mDrawingId instanceof Data_Maybe.Nothing) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                    return v3.drawingId;
                })(currentUrlParams);
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 103, column 18 - line 105, column 50): " + [ v.mDrawingId.constructor.name ]);
        })();
        var mFileId = (function () {
            if (v.mFileId instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(v.mFileId.value0);
            };
            if (v.mFileId instanceof Data_Maybe.Nothing) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                    return v3.fileId;
                })(currentUrlParams);
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 99, column 15 - line 101, column 47): " + [ v.mFileId.constructor.name ]);
        })();
        var mMeasurementGroupId = (function () {
            if (v.mMeasurementGroupId instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(v.mMeasurementGroupId.value0);
            };
            if (v.mMeasurementGroupId instanceof Data_Maybe.Nothing) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                    return v3.measurementGroupId;
                })(currentUrlParams);
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 107, column 27 - line 109, column 59): " + [ v.mMeasurementGroupId.constructor.name ]);
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mFileId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mDrawingId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mMeasurementGroupId)), ReactHooksUtils.utf(urlParamsKey) ])(function __do() {
            (function () {
                if (mFileId instanceof Data_Maybe.Just && (mDrawingId instanceof Data_Maybe.Just && mMeasurementGroupId instanceof Data_Maybe.Just)) {
                    return v2.value1(Data_Map_Internal.insert(Data_Ord.ordString)(urlParamsKey)({
                        fileId: mFileId.value0,
                        drawingId: mDrawingId.value0,
                        measurementGroupId: mMeasurementGroupId.value0
                    }))();
                };
                return Data_Unit.unit;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v3 = TofuHooks.useRef(Data_Maybe.Nothing.value)();
        var v4 = TofuHooks.useRef(Data_Maybe.Nothing.value)();
        var v5 = TofuHooks.useRef(Data_Maybe.Nothing.value)();
        var updateStatuses = StateHooks.useSelector(function (v6) {
            return v6.updateStatuses;
        })();
        var v6 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v7 = TofuHooks.useState(new Data_Maybe.Just((function () {
            var $78 = Data_Maybe.isJust(mMeasurementGroupId);
            if ($78) {
                return "measurements";
            };
            return "measurement-groups";
        })()))();
        var v8 = TofuHooks.useState(new Data_Maybe.Just("components"))();
        var v9 = TofuHooks.useState(false)();
        Client_WASM.useSetActiveLibraryTab(v8.value0)();
        var dismissActiveDrawingScalePrompt = v6.value1(function (v10) {
            return Data_Maybe.Nothing.value;
        });
        var submitActiveDrawingScalePrompt = function __do() {
            var mEditor = React_Basic_Hooks.readRef(v3)();
            if (mEditor instanceof Data_Maybe.Just && v6.value0 instanceof Data_Maybe.Just) {
                mEditor.value0.set_active_drawing_scale(TofuJSON.write(DixonMeasurements_Types.tofuJSONShape)(v6.value0.value0.shape), v6.value0.value0.unscaledMeasurementValuePx, v6.value0.value0.newValue, v6.value0.value0.moveExistingMeasurements);
                return dismissActiveDrawingScalePrompt();
            };
            return Data_Unit.unit;
        };
        TofuHooks.useEffect([  ])(Control_Applicative.pure(Effect.applicativeEffect)(function __do() {
            var timeoutId = React_Basic_Hooks.readRef(v5)();
            Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(timeoutId)();
            var mEditor = React_Basic_Hooks.readRef(v3)();
            if (mEditor instanceof Data_Maybe.Just) {
                React_Basic_Hooks.writeRef(v3)(Data_Maybe.Nothing.value)();
                return mEditor.value0.free();
            };
            if (mEditor instanceof Data_Maybe.Nothing) {
                return Data_Unit.unit;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 151, column 7 - line 155, column 29): " + [ mEditor.constructor.name ]);
        }))();
        TofuHooks.useEffect([ ReactHooksUtils.utf(Data_Newtype.un()(Types_Project.ProjectId)(v.projectId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mFileId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mDrawingId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(0)(mMeasurementGroupId)) ])(function __do() {
            var mEditor = React_Basic_Hooks.readRef(v3)();
            (function () {
                if (mEditor instanceof Data_Maybe.Just && (mFileId instanceof Data_Maybe.Just && (mDrawingId instanceof Data_Maybe.Just && mMeasurementGroupId instanceof Data_Maybe.Just))) {
                    return mEditor.value0.set_query_params(mFileId.value0, mDrawingId.value0, mMeasurementGroupId.value0);
                };
                return Data_Unit.unit;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v10 = TofuHooks.useState(0)();
        var appDispatch = StateHooks.useDispatch();
        var v11 = TofuHooks.useRef(Data_Maybe.Nothing.value)();
        var getDeferredEstimationDataRequest = function (_$prime) {
            return function __do() {
                WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                    request_params: v.projectId
                }))();
                return React_Basic_Hooks.writeRef(v11)(Data_Maybe.Nothing.value)();
            };
        };
        var forceRedraw = function __do() {
            var timeoutId = Effect_Timer.setTimeout(0)(v10.value1(function (counter) {
                return counter + 1 | 0;
            }))();
            return React_Basic_Hooks.writeRef(v5)(new Data_Maybe.Just(timeoutId))();
        };
        var dispatch = function (actionJSON) {
            var v12 = TofuJSON.readJSON(DixonContainer_Actions.writeDixonAction)(actionJSON);
            if (v12 instanceof Data_Either.Right) {
                if (v12.value0 instanceof DixonContainer_Actions.DixonGetEstimationComponentsRequest) {
                    return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                        request_params: v.projectId
                    }));
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonGetEstimationElementsRequest) {
                    return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationElementsData({
                        request_params: v.projectId
                    }));
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonGetEstimationElementSpecsRequest) {
                    return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationElementSpecsData({
                        request_params: v.projectId
                    }));
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonGetEstimationDataRequest) {
                    return getDeferredEstimationDataRequest(v12.value0.value0.projectId);
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonGetDeferredEstimationDataRequest) {
                    return function __do() {
                        Effect_Timer.setTimeout(0)(function __do() {
                            var timeoutId = React_Basic_Hooks.readRef(v11)();
                            Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(timeoutId)();
                            var timeout = Effect_Timer.setTimeout(0)(getDeferredEstimationDataRequest(v12.value0.value0.projectId))();
                            return React_Basic_Hooks.writeRef(v11)(new Data_Maybe.Just(timeout))();
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonPromptActiveDrawingScale) {
                    return function __do() {
                        Effect_Timer.setTimeout(0)(v6.value1(function (v13) {
                            return new Data_Maybe.Just({
                                shape: v12.value0.value0.shape,
                                unscaledMeasurementValuePx: v12.value0.value0.unscaledMeasurementValuePx,
                                newValue: 0.0,
                                moveExistingMeasurements: false
                            });
                        }))();
                        return Data_Unit.unit;
                    };
                };
                if (v12.value0 instanceof DixonContainer_Actions.ForceRedraw) {
                    return forceRedraw;
                };
                if (v12.value0 instanceof DixonContainer_Actions.SetLoading) {
                    return function __do() {
                        Effect_Timer.setTimeout(0)(function __do() {
                            v9.value1(function (v13) {
                                return v12.value0.value0.b;
                            })();
                            return Data_Unit.unit;
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonAddInfoNotification) {
                    return function __do() {
                        Effect_Timer.setTimeout(0)(appDispatch(new Actions.AddAlert(Types_Alert.AlertInfo.value, v12.value0.value0.msg)))();
                        return Data_Unit.unit;
                    };
                };
                if (v12.value0 instanceof DixonContainer_Actions.DixonAddAlertNotification) {
                    return function __do() {
                        Effect_Timer.setTimeout(0)(appDispatch(new Actions.AddAlert(Types_Alert.AlertError.value, v12.value0.value0.msg)))();
                        return Data_Unit.unit;
                    };
                };
                throw new Error("Failed pattern match at Containers.DixonContainer (line 189, column 11 - line 223, column 24): " + [ v12.value0.constructor.name ]);
            };
            if (v12 instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 187, column 7 - line 225, column 20): " + [ v12.constructor.name ]);
        };
        var v12 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v12) {
            return v12.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var maybeLockedProject = (function () {
            if (v12.value0 instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(EstimationUtils.isProductionPlanning(v12.value0.value0.project) || EstimationUtils.isReporting(v12.value0.value0.project));
            };
            if (v12.value0 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 231, column 7 - line 233, column 27): " + [ v12.value0.constructor.name ]);
        })();
        var viewportRef = UseCallbackRef.useCallbackRef([ Data_Nullable.toNullable(maybeLockedProject) ])(function (node) {
            var maybeElement = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLDivElement.fromNode)(node);
            return function __do() {
                (function () {
                    if (maybeLockedProject instanceof Data_Maybe.Just && maybeElement instanceof Data_Maybe.Just) {
                        var dispatch$prime = Effect_Uncurried.mkEffectFn1(dispatch);
                        var init_active_measurement_group_id = Data_Nullable.toNullable(mMeasurementGroupId);
                        var init_active_file_id = Data_Nullable.toNullable(mFileId);
                        var init_active_drawing_id = Data_Nullable.toNullable(mDrawingId);
                        var editor = wasmModule["WasmDixonEditor"]["new"](dispatch$prime, {
                            project_id: v.projectId,
                            organization_id: organization,
                            init_active_file_id: init_active_file_id,
                            init_active_drawing_id: init_active_drawing_id,
                            init_active_measurement_group_id: init_active_measurement_group_id,
                            locked_project: maybeLockedProject.value0
                        });
                        React_Basic_Hooks.writeRef(v3)(new Data_Maybe.Just(editor))();
                        forceRedraw();
                        return Data_Unit.unit;
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Data_Monoid.monoidUnit);
            };
        })();
        var editorState = (function __do() {
            var mEditor = React_Basic_Hooks.readRef(v3)();
            if (mEditor instanceof Data_Maybe.Just) {
                var state = mEditor.value0.get_state();
                var v13 = TofuJSON.read(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active_drawing_preview_resolution";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active_drawing_print_dimensions";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "height_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "width_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "height_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "width_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active_drawing_rotation";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active_drawing_scale_measurement_meters";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "files";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "archived";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawings";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "folder";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "archived";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawings";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "folder";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "loading";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readBoolean))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_links";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "building_element_id";
                    }
                })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "component_id";
                    }
                })(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "element_spec_id";
                    }
                })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "from_type";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "target_field";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "building_element_id";
                    }
                })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "component_id";
                    }
                })(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "element_spec_id";
                    }
                })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "from_type";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "target_field";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_groups";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "color";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula_sum";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "linked_building_element_ids";
                    }
                })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "linked_component_ids";
                    }
                })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "color";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula_sum";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "linked_building_element_ids";
                    }
                })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "linked_component_ids";
                    }
                })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurements";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "mode";
                    }
                })(DixonMeasurements_Types.tofuJSONDixonEditorMode)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active_drawing_preview_resolution";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active_drawing_print_dimensions";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "height_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "width_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "height_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "width_meters";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active_drawing_rotation";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active_drawing_scale_measurement_meters";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "files";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "archived";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawings";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "folder";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "archived";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawings";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "scale";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "folder";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "loading";
                    }
                })(TofuJSON.readMaybe(TofuJSON.readBoolean))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_links";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "building_element_id";
                    }
                })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "component_id";
                    }
                })(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "element_spec_id";
                    }
                })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "from_type";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "target_field";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "building_element_id";
                    }
                })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "component_id";
                    }
                })(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "element_spec_id";
                    }
                })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "from_type";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "target_field";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_groups";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "color";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula_sum";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "linked_building_element_ids";
                    }
                })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "linked_component_ids";
                    }
                })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "color";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "default_location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_ids";
                    }
                })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula_sum";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "linked_building_element_ids";
                    }
                })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "linked_component_ids";
                    }
                })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurements";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "mode";
                    }
                })(DixonMeasurements_Types.tofuJSONDixonEditorMode)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(state);
                if (v13 instanceof Data_Either.Left) {
                    Effect_Class_Console.error(Effect_Class.monadEffectEffect)(v13.value0)();
                    return Dixon_EditorFFI.defaultEditorState;
                };
                if (v13 instanceof Data_Either.Right) {
                    return v13.value0;
                };
                throw new Error("Failed pattern match at Containers.DixonContainer (line 265, column 9 - line 269, column 47): " + [ v13.constructor.name ]);
            };
            return Dixon_EditorFFI.defaultEditorState;
        })();
        var mEditor = React_Basic_Hooks.readRef(v3)();
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.SmallMode.value,
            localStorageKey: "DixonContainer2"
        })();
        var componentPanelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            localStorageKey: "DixonComponentDetails"
        })();
        var elementPanelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            localStorageKey: "DixonElementDetails"
        })();
        TofuHooks.useEffect([ panelProps.detailsPanelMode ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.NormalMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(componentPanelProps.detailsPanelMode)(CostEstimationLayout_Types.MinimizedMode.value))(componentPanelProps.setDetailsPanelMode(function (v13) {
                return CostEstimationLayout_Types.SmallMode.value;
            }))();
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.SmallMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(componentPanelProps.detailsPanelMode)(CostEstimationLayout_Types.SmallMode.value))(componentPanelProps.setDetailsPanelMode(function (v13) {
                return CostEstimationLayout_Types.MinimizedMode.value;
            }))();
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.NormalMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(elementPanelProps.detailsPanelMode)(CostEstimationLayout_Types.MinimizedMode.value))(elementPanelProps.setDetailsPanelMode(function (v13) {
                return CostEstimationLayout_Types.NormalMode.value;
            }))();
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.SmallMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(elementPanelProps.detailsPanelMode)(CostEstimationLayout_Types.SmallMode.value))(elementPanelProps.setDetailsPanelMode(function (v13) {
                return CostEstimationLayout_Types.MinimizedMode.value;
            }))();
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.SmallMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(elementPanelProps.detailsPanelMode)(CostEstimationLayout_Types.NormalMode.value))(elementPanelProps.setDetailsPanelMode(function (v13) {
                return CostEstimationLayout_Types.MinimizedMode.value;
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v13 = TofuHooks.useState(Data_Set.empty)();
        var v14 = TofuHooks.useState(Data_Set.empty)();
        var v15 = TofuHooks.useState(Data_Set.empty)();
        var toolbar = React_Basic.fragment([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(function (editor) {
            return DixonToolbar.component({
                editor: editor,
                editorRef: v3,
                editorState: editorState
            });
        })(mEditor)) ]);
        var numMeasurements = Data_Array.length(editorState.measurements);
        var numMGroups = Data_Array.length(editorState.measurement_groups);
        var numFiles = Data_Array.length(editorState.files);
        var numActiveMGroups = Data_Array.length(Data_Array.filter(function (v16) {
            return v16.active;
        })(editorState.measurement_groups));
        var numActiveFiles = Data_Array.length(Data_Array.filter(function (v16) {
            return v16.active;
        })(editorState.files));
        var mgl_by_element_spec = function (group) {
            var v16 = (Data_Array_NonEmpty.head(group)).element_spec_id;
            if (v16 instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(Data_Tuple.Tuple.create(v16.value0)(Data_Array_NonEmpty.toArray(group)));
            };
            if (v16 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 335, column 33 - line 337, column 25): " + [ v16.constructor.name ]);
        };
        var mgl_by_element = function (group) {
            var v16 = (Data_Array_NonEmpty.head(group)).building_element_id;
            if (v16 instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(Data_Tuple.Tuple.create(v16.value0)(Data_Array_NonEmpty.toArray(group)));
            };
            if (v16 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 330, column 28 - line 332, column 25): " + [ v16.constructor.name ]);
        };
        var mgl_by_component = function (group) {
            var v16 = (Data_Array_NonEmpty.head(group)).component_id;
            if (v16 instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(Data_Tuple.Tuple.create(v16.value0)(Data_Array_NonEmpty.toArray(group)));
            };
            if (v16 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 325, column 30 - line 327, column 25): " + [ v16.constructor.name ]);
        };
        var element_spec_links = Data_Array.filter(function (mgl) {
            return Data_Maybe.isJust(mgl.element_spec_id);
        })(editorState.measurement_group_links);
        var measurement_group_links_by_element_spec = Data_Map_Internal.fromFoldable(Types_Newtypes.ordElementSpecId)(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(mgl_by_element_spec)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))(function (v16) {
            return v16.element_spec_id;
        }))(Data_Array.sortWith(Data_Maybe.ordMaybe(Types_Newtypes.ordElementSpecId))(function (v16) {
            return v16.element_spec_id;
        })(element_spec_links)))));
        var element_links = Data_Array.filter(function (mgl) {
            return Data_Maybe.isJust(mgl.building_element_id) && Data_Maybe.isNothing(mgl.element_spec_id);
        })(editorState.measurement_group_links);
        var measurement_group_links_by_element = Data_Map_Internal.fromFoldable(Types_Element.ordElementId)(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(mgl_by_element)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId)))(function (v16) {
            return v16.building_element_id;
        }))(Data_Array.sortWith(Data_Maybe.ordMaybe(Types_Element.ordElementId))(function (v16) {
            return v16.building_element_id;
        })(element_links)))));
        var component_links = Data_Array.filter(function (mgl) {
            return Data_Maybe.isJust(mgl.component_id) && Data_Maybe.isNothing(mgl.element_spec_id);
        })(editorState.measurement_group_links);
        var measurement_group_links_by_component = Data_Map_Internal.fromFoldable(Types_Component.ordComponentId)(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(mgl_by_component)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))(function (v16) {
            return v16.component_id;
        }))(Data_Array.sortWith(Data_Maybe.ordMaybe(Types_Component.ordComponentId))(function (v16) {
            return v16.component_id;
        })(component_links)))));
        var active_measurement_groups = Data_Array.filter(function (mg) {
            return mg.active;
        })(editorState.measurement_groups);
        var componentsTab = (function () {
            if (v12.value0 instanceof Data_Maybe.Nothing) {
                return TacoLoader.component()()({});
            };
            if (v12.value0 instanceof Data_Maybe.Just) {
                return EditView.component({
                    projectId: v.projectId,
                    project: v12.value0.value0.project,
                    initialSelectedComponent: Data_Maybe.Nothing.value,
                    updateStatuses: updateStatuses,
                    checkedComponents: new Data_Maybe.Just(v13.value0),
                    setCheckedComponents: new Data_Maybe.Just(v13.value1),
                    dixonOptions: new Data_Maybe.Just({
                        panelProps: componentPanelProps,
                        hideResources: true,
                        measurement_group_links_by_component: measurement_group_links_by_component,
                        active_measurement_groups: active_measurement_groups,
                        editorState: editorState
                    })
                });
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 370, column 21 - line 380, column 10): " + [ v12.value0.constructor.name ]);
        })();
        var elementsTab = (function () {
            if (v12.value0 instanceof Data_Maybe.Nothing) {
                return TacoLoader.component()()({});
            };
            if (v12.value0 instanceof Data_Maybe.Just) {
                return CostEstimation_ElementsEditView.component({
                    projectId: v.projectId,
                    project: v12.value0.value0.project,
                    initialSelectedElement: Data_Maybe.Nothing.value,
                    checkedElements: new Data_Maybe.Just(v14.value0),
                    setCheckedElements: new Data_Maybe.Just(v14.value1),
                    checkedSpecs: new Data_Maybe.Just(v15.value0),
                    setCheckedSpecs: new Data_Maybe.Just(v15.value1),
                    panelOptions: new Data_Maybe.Just({
                        setSelectedEditViewElement: Data_Maybe.Nothing.value,
                        selectedEditViewElementId: Data_Maybe.Nothing.value,
                        selectedEditViewElementSpecId: Data_Maybe.Nothing.value,
                        selectedEditViewElement: Data_Maybe.Nothing.value,
                        selectedEditViewElementMeasures: Data_Maybe.Nothing.value,
                        selectedEditViewComponentId: Data_Maybe.Nothing.value
                    }),
                    dixonOptions: new Data_Maybe.Just({
                        panelProps: elementPanelProps,
                        hideComponents: true,
                        measurement_group_links_by_element: measurement_group_links_by_element,
                        measurement_group_links_by_element_spec: measurement_group_links_by_element_spec,
                        active_measurement_groups: active_measurement_groups,
                        editorState: editorState
                    })
                });
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 342, column 19 - line 368, column 10): " + [ v12.value0.constructor.name ]);
        })();
        var v16 = TofuHooks.useState([  ])();
        var v17 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var showUploadFileHint = numFiles === 0;
        var showSetScaleHint = Data_Maybe.isNothing(editorState.active_drawing_scale_measurement_meters);
        var showSelectFileHint = numActiveFiles === 0;
        var showCreateMGroupHint = numMGroups === 0;
        var drawingScale = (function () {
            if (v6.value0 instanceof Data_Maybe.Just && (editorState.active_drawing_print_dimensions instanceof Data_Maybe.Just && editorState.active_drawing_preview_resolution instanceof Data_Maybe.Just)) {
                var scaleRatioOfWidth = v6.value0.value0.unscaledMeasurementValuePx / editorState.active_drawing_preview_resolution.value0;
                var max_dimension = Data_Ord.max(Data_Ord.ordNumber)(editorState.active_drawing_print_dimensions.value0.width_meters)(editorState.active_drawing_print_dimensions.value0.height_meters);
                var drawing_side_m = v6.value0.value0.newValue / scaleRatioOfWidth;
                var drawingScaleRatio = drawing_side_m / max_dimension;
                return new Data_Maybe.Just({
                    max_dimension: max_dimension,
                    scaleRatioOfWidth: scaleRatioOfWidth,
                    drawingScaleRatio: drawingScaleRatio
                });
            };
            return Data_Maybe.Nothing.value;
        })();
        var dixonFiles = React_Basic.fragment([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(function (editor) {
            return Containers_DixonContainer_DixonFiles.component({
                editor: editor,
                editorRef: v3,
                editorState: editorState,
                projectId: v.projectId,
                mFileId: mFileId
            });
        })(mEditor)) ]);
        var activeDrawings$prime = Data_Array.filter(function (v18) {
            return v18.active;
        })(Data_Array.concatMap(function (v18) {
            return v18.drawings;
        })(Data_Array.filter(function (v18) {
            return v18.active;
        })(editorState.files)));
        var activeDrawingScaleModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(dismissActiveDrawingScalePrompt),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(submitActiveDrawingScalePrompt),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "Aseta mittakaava"
        }) ];
        var main = React_Basic.fragment([ toolbar, dixonFiles, Viewport.component({
            viewportRef: viewportRef
        }), TacoModal.component()()({
            onClose: dismissActiveDrawingScalePrompt,
            isActive: Data_Maybe.isJust(v6.value0),
            heading: "Aseta mittakaava",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Sy\xf6t\xe4 mitatun janan pituus metrein\xe4:") ]), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                    value: Data_Maybe.fromMaybe(0.0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
                        return v18.newValue;
                    })(v6.value0)),
                    onChange: TacoInput.changeHandler(TacoInput.inputValueNumber)(function (newValue) {
                        return v6.value1(function (prompt) {
                            if (prompt instanceof Data_Maybe.Just) {
                                return Data_Maybe.Just.create({
                                    newValue: newValue,
                                    moveExistingMeasurements: prompt.value0.moveExistingMeasurements,
                                    shape: prompt.value0.shape,
                                    unscaledMeasurementValuePx: prompt.value0.unscaledMeasurementValuePx
                                });
                            };
                            if (prompt instanceof Data_Maybe.Nothing) {
                                return prompt;
                            };
                            throw new Error("Failed pattern match at Containers.DixonContainer (line 566, column 33 - line 568, column 52): " + [ prompt.constructor.name ]);
                        });
                    }),
                    precision: 3
                }), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("TAI sy\xf6t\xe4 piirustuksen mittakaavan murtoluku (esim 1:100)") ]), Box.box("ScaleInputWrapper")([ Box.FlexRow.value, Box.AlignCenter.value ])([ React_Basic_DOM_Generated.span_([ React_Basic_DOM.text("1 :") ]), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                    value: Data_Maybe.fromMaybe(0.0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
                        return v18.drawingScaleRatio;
                    })(drawingScale)),
                    onChange: TacoInput.changeHandler(TacoInput.inputValueNumber)(function (newScaleRatio) {
                        return v6.value1(function (prompt) {
                            if (prompt instanceof Data_Maybe.Just && drawingScale instanceof Data_Maybe.Just) {
                                var drawing_side_m = newScaleRatio * drawingScale.value0.max_dimension;
                                var newValue = drawing_side_m * drawingScale.value0.scaleRatioOfWidth;
                                return Data_Maybe.Just.create({
                                    newValue: newValue,
                                    moveExistingMeasurements: prompt.value0.moveExistingMeasurements,
                                    shape: prompt.value0.shape,
                                    unscaledMeasurementValuePx: prompt.value0.unscaledMeasurementValuePx
                                });
                            };
                            return prompt;
                        });
                    }),
                    precision: 0,
                    width: Data_Maybe.Just.create(new TacoConstants.CustomSize("100px"))
                }) ]), Data_Monoid.guard(React_Basic.monoidJSX)(!showSetScaleHint)(TacoCheckbox.component()()({
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Siirr\xe4 ja skaalaa mittaukset vastaamaan uutta mittakaavaa")),
                    isChecked: Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
                        return v18.moveExistingMeasurements;
                    })(v6.value0)),
                    onToggle: TacoCheckbox.NoEvent.create(v6.value1(function (prompt) {
                        if (prompt instanceof Data_Maybe.Just) {
                            return Data_Maybe.Just.create({
                                moveExistingMeasurements: !prompt.value0.moveExistingMeasurements,
                                newValue: prompt.value0.newValue,
                                shape: prompt.value0.shape,
                                unscaledMeasurementValuePx: prompt.value0.unscaledMeasurementValuePx
                            });
                        };
                        if (prompt instanceof Data_Maybe.Nothing) {
                            return prompt;
                        };
                        throw new Error("Failed pattern match at Containers.DixonContainer (line 597, column 31 - line 599, column 50): " + [ prompt.constructor.name ]);
                    }))
                })) ]
            }), TacoModal.modalActions({
                contents: activeDrawingScaleModalActions
            }) ]
        }) ]);
        var activeDrawing$prime = (function () {
            var $171 = Data_Array.length(activeDrawings$prime) > 1;
            if ($171) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeDrawings$prime);
        })();
        var copiedFromActiveDrawing = (function () {
            if (activeDrawing$prime instanceof Data_Maybe.Just) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(new Data_Maybe.Just(activeDrawing$prime.value0.id))(v17.value0);
            };
            if (activeDrawing$prime instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at Containers.DixonContainer (line 388, column 31 - line 390, column 23): " + [ activeDrawing$prime.constructor.name ]);
        })();
        var showSelectMGroupHint = numActiveMGroups !== 1 && (numMeasurements === 0 && copiedFromActiveDrawing);
        var measurementsList = Data_Functor.map(Data_Maybe.functorMaybe)(function (editor) {
            var measurementsTab = (function () {
                var $174 = showSelectFileHint || (showSetScaleHint || showSelectMGroupHint);
                if ($174) {
                    return Box.box("HintTextWrapper")([ new Box.Padding(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                        if (showSelectFileHint) {
                            return React_Basic_DOM.text("Aloita mittaaminen valitsemalla piirustuksen tiedosto");
                        };
                        if (showSetScaleHint) {
                            return React_Basic_DOM.text("Aseta seuraavaksi piirustuksen mittakaava. Klikkaa toimintovalikosta Aseta mittakaava -ty\xf6kalua, mittaa piirustuksesta jana, jonka pituuden tied\xe4t ja merkkaa janan pituus avautuvaan valikkoon metrein\xe4.");
                        };
                        return React_Basic_DOM.text("Aloita mittaaminen valitsemalla yksi mittausryhm\xe4 kerrallaan. Mittaukset tallentuvat automaattisesti valitun mittausryhm\xe4n alle.");
                    })()));
                };
                return DixonMeasurements.component({
                    editor: editor,
                    editorRef: v3,
                    editorState: editorState,
                    projectId: v.projectId,
                    setActiveMeasurementsTabId: v7.value1,
                    copiedMeasurements: v16.value0,
                    setCopiedMeasurements: v16.value1,
                    copiedMeasurementsDrawingId: v17.value0,
                    setCopiedMeasurementsDrawingId: v17.value1,
                    maybeLockedProject: maybeLockedProject
                });
            })();
            var measurementGroupsList = React_Basic.fragment([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(function (editor$prime) {
                return DixonMeasurementGroups.component({
                    editor: editor$prime,
                    editorRef: v3,
                    editorState: editorState,
                    projectId: v.projectId,
                    activeLibraryTabId: v8.value0,
                    checkedComponents: Data_Array.fromFoldable(Data_Set.foldableSet)(v13.value0),
                    setCheckedComponents: v13.value1,
                    checkedElements: Data_Array.fromFoldable(Data_Set.foldableSet)(v14.value0),
                    setCheckedElements: v14.value1,
                    checkedSpecs: Data_Array.fromFoldable(Data_Set.foldableSet)(v15.value0),
                    setCheckedSpecs: v15.value1,
                    setActiveMeasurementsTabId: v7.value1,
                    showCreateMGroupHint: showCreateMGroupHint,
                    showUploadFileHint: showUploadFileHint,
                    showSetScaleHint: showSetScaleHint,
                    showSelectFileHint: showSelectFileHint,
                    copiedMeasurements: v16.value0,
                    setLoading: v9.value1,
                    maybeLockedProject: maybeLockedProject
                });
            })(mEditor)) ]);
            var linkedElementsTab = DixonMeasurementGroupElements.component({
                editor: editor,
                editorRef: v3,
                editorState: editorState,
                projectId: v.projectId
            });
            var linkedComponentsTab = DixonMeasurementGroupComponents.component({
                editor: editor,
                editorRef: v3,
                editorState: editorState,
                projectId: v.projectId
            });
            var licenseLinkedElementsTab = Data_Monoid.guard(Data_Monoid.monoidArray)(elementsEnabled)([ new TacoTabs_Types.NormalTab({
                children: [ linkedElementsTab ],
                quantity: Data_Maybe.Nothing.value,
                title: "Linkitetyt rakenteet",
                id: "linked-elements",
                color: TacoConstants.elementColors.border
            }) ]);
            var licenseElementsTab = Data_Monoid.guard(Data_Monoid.monoidArray)(elementsEnabled)([ new TacoTabs_Types.NormalTab({
                children: [ elementsTab ],
                quantity: Data_Maybe.Nothing.value,
                title: "Rakenteet",
                id: "elements",
                color: TacoConstants.elementColors.border
            }) ]);
            return Box.box("DetailsContainer")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Height.create(new TacoConstants.CustomSize("100%")) ])([ Box.box("TabsContainer")([ new Box.FlexGrow(1), Box.MinWidth.create(new TacoConstants.CustomSize("0")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoTabs.controlled({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.MinimizedMode.value),
                activeTabId: v7.value0,
                setActiveTabId: v7.value1,
                tabs: [ new TacoTabs_Types.NormalTab({
                    children: [ TacoLoader.progressBar({
                        color: TacoConstants.GrayLight.value,
                        isLoading: v9.value0,
                        testId: "dixon_loaderbar"
                    }), measurementGroupsList ],
                    quantity: Data_Maybe.Nothing.value,
                    title: "Mittausryhm\xe4t",
                    id: "measurement-groups",
                    color: TacoConstants.componentColors.border
                }), new TacoTabs_Types.NormalTab({
                    children: [ measurementsTab ],
                    quantity: Data_Maybe.Nothing.value,
                    title: "Mittaukset",
                    id: "measurements",
                    color: TacoConstants.componentColors.border
                }) ]
            }))), Box.box("TabsContainer")([ new Box.FlexGrow(1), Box.MinWidth.create(new TacoConstants.CustomSize("0")), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
                borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
            })) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoTabs.controlled({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(panelProps.detailsPanelMode)(CostEstimationLayout_Types.MinimizedMode.value),
                activeTabId: v8.value0,
                setActiveTabId: v8.value1,
                tabs: Data_Semigroup.append(Data_Semigroup.semigroupArray)(licenseElementsTab)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new TacoTabs_Types.NormalTab({
                    children: [ componentsTab ],
                    quantity: Data_Maybe.Nothing.value,
                    title: "Suoritteet",
                    id: "components",
                    color: TacoConstants.componentColors.border
                }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(licenseLinkedElementsTab)([ new TacoTabs_Types.NormalTab({
                    children: [ linkedComponentsTab ],
                    quantity: Data_Maybe.Nothing.value,
                    title: "Linkitetyt suoritteet",
                    id: "linked-components",
                    color: TacoConstants.componentColors.border
                }), TacoTabs_Types.SeparatorTab.value, TacoTabs_Types.JsxTab.create(CostEstimation_Layout.panelModeButtons({
                    panelMode: panelProps.detailsPanelMode,
                    setPanelMode: panelProps.setDetailsPanelMode,
                    vertical: false,
                    smallMode: true,
                    testId: "dixon-panel-size"
                })) ])))
            }))) ]);
        })(mEditor);
        var activeMGroups = Data_Array.filter(function (g) {
            return g.active;
        })(editorState.measurement_groups);
        var activeMGroup = (function () {
            var $177 = Data_Array.length(activeMGroups) > 1;
            if ($177) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeMGroups);
        })();
        var activeMGroupId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
            return v18.id;
        })(activeMGroup);
        var activeFiles = Data_Array.filter(function (f) {
            return f.active;
        })(editorState.files);
        var activeFile = (function () {
            var $178 = Data_Array.length(activeFiles) > 1;
            if ($178) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeFiles);
        })();
        var activeFileId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
            return v18.id;
        })(activeFile);
        var activeDrawings = Data_Array.filter(function (d) {
            return d.active;
        })(Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
            return v18.drawings;
        })(activeFile)));
        var activeDrawing = (function () {
            var $179 = Data_Array.length(activeDrawings) > 1;
            if ($179) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeDrawings);
        })();
        var activeDrawingId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v18) {
            return v18.id;
        })(activeDrawing);
        TofuHooks.useEffect([ ReactHooksUtils.utf(Data_Maybe.fromMaybe(-1 | 0)(activeFileId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(-1 | 0)(activeDrawingId)), ReactHooksUtils.utf(Data_Maybe.fromMaybe(-1 | 0)(activeMGroupId)) ])((function () {
            var idsAreJust = Data_Maybe.isJust(activeFileId) && (Data_Maybe.isJust(activeDrawingId) && Data_Maybe.isJust(activeMGroupId));
            var idsHaveChanged = Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(activeFileId)(mFileId) || (Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(activeDrawingId)(mDrawingId) || Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(activeMGroupId)(mMeasurementGroupId));
            return function __do() {
                Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(idsAreJust && idsHaveChanged)(appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.DixonFERoute({
                    projectId: v.projectId,
                    mFileId: activeFileId,
                    mDrawingId: activeDrawingId,
                    mMeasurementGroupId: activeMGroupId
                }))))();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        return CostEstimation_Layout.component({
            controls: Data_Maybe.Nothing.value,
            controlsLoading: CostEstimationLayout_Types.LoadingFinished.value,
            leftPanel: Data_Maybe.Nothing.value,
            leftSidebar: Data_Maybe.Nothing.value,
            main: main,
            rightPanel: Data_Maybe.Nothing.value,
            rightSidebar: Data_Maybe.Nothing.value,
            details: measurementsList,
            panelProps: panelProps,
            leftPanelLoading: CostEstimationLayout_Types.LoadingFinished.value,
            mRefPanelMode: Data_Maybe.Nothing.value
        });
    };
});
module.exports = {
    component: component
};
