// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var EstimationProjectsContainer_Styles = require("../EstimationProjectsContainer.Styles/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("EstimationProjectsContainer")(function (v) {
    var projectListComponent = React_Basic.element($foreign.projectList)({});
    var projectListContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "new-project-list",
        css: EstimationProjectsContainer_Styles.newProjectTableStyles,
        children: [ projectListComponent ]
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(NarrowLayout.component()()({
        verticalPadding: true,
        typescriptChildComponent: true,
        fullWidth: true,
        children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__EstimationProjects",
            css: EstimationProjectsContainer_Styles.newEstimationProjectsContainerStyles,
            children: [ projectListContainer ]
        }) ]
    }));
});
module.exports = {
    component: component
};
