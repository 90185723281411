import {
  Checkbox,
  Header,
  Icon,
  IconCheck24Px,
  IconCloseCrossV1,
  Input,
} from "@tocoman/ui";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AllOrganizationData } from "./OrganizationInformation";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { RemoveOrganizationWritePermissionsModal } from "./RemoveOrganizationWritePermissionsModal";

const planSelectItems = [
  { value: "pro", label: "Pro" },
  { value: "premium", label: "Premium" },
];

type AdditionalFormProps = {
  organization?: string;
  expiresAt?: Date | null;
};

export function OrganizationAdditionalInformationForm({
  organization,
  expiresAt,
}: AdditionalFormProps) {
  const { t } = useTranslation("superAdmin");

  const { register, control, watch, setValue } = useFormContext<
    AllOrganizationData
  >();

  const mkInputProps = (
    field: keyof AllOrganizationData,
    registerOptions?: RegisterOptions<AllOrganizationData, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const cognitoIdentityProviderName = watch("cognitoIdentityProviderName");
  const ssoTenantId = watch("ssoTenantId");

  const dt = DateTime.fromISO(
    expiresAt ? expiresAt.toString() : "0000-00-00"
  ).toISODate();

  useEffect(() => {
    if (expiresAt) {
      setValue("expiresAt", (dt as unknown) as Date);
    }
  }, [expiresAt]);

  return (
    <>
      <div className={"flex flex-col"}>
        <Input className="w-full" {...mkInputProps("companyName")} />
        <Input className="w-full" {...mkInputProps("description")} />
        <Input
          className="w-full"
          {...mkInputProps("defaultVAT")}
          type={"number"}
          step={"any"}
        />
        <div className={"flex"}>
          <Input
            className="w-full"
            {...mkInputProps("cognitoIdentityProviderName")}
          />
          <div className={"flex flex-col ml-4"}>
            <span>SSO</span>
            <div className={"pt-3"}>
              {cognitoIdentityProviderName ? (
                <Icon icon={IconCheck24Px} className={"text-primary"} />
              ) : (
                <Icon icon={IconCloseCrossV1} />
              )}
            </div>
          </div>
        </div>
        <div className={"flex"}>
          <Input className="w-full" {...mkInputProps("ssoTenantId")} />
          <div className={"flex flex-col ml-4"}>
            <span>SSO</span>
            <div className={"pt-3"}>
              {ssoTenantId ? (
                <Icon icon={IconCheck24Px} className={"text-primary"} />
              ) : (
                <Icon icon={IconCloseCrossV1} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"flex mt-1 justify-between w-full"}>
        <div className={"w-1/2 flex flex-col space-y-2 mt-3"}>
          <Controller
            control={control}
            name={"priceListUpdate"}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value}
                setChecked={onChange}
                label={t`form.priceListUpdate`}
              />
            )}
          />
          <Controller
            control={control}
            name={"sendInvitation"}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value}
                setChecked={onChange}
                label={t`form.sendInvitation`}
              />
            )}
          />
          <Controller
            control={control}
            name={"integrationEnabled"}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value}
                setChecked={onChange}
                label={t`form.integrationEnabled`}
              />
            )}
          />
        </div>
        <div className={"w-1/2"}>
          <Controller
            name="plan"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth style={{ marginTop: "1rem" }}>
                <FormLabel htmlFor="plan-select">{t(`form.plan`)}</FormLabel>
                <RadioGroup
                  onChange={field.onChange}
                  value={field.value}
                  ref={field.ref}
                  className={"flex flex-1"}
                >
                  {planSelectItems.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Header titleSize="small" title={t`expiresAt`} />
          <Input
            type="date"
            {...register("expiresAt", {
              valueAsDate: true,
            })}
          />
        </div>
        <div className="flex flex-col">
          {organization && (
            <RemoveOrganizationWritePermissionsModal
              organization={organization}
            />
          )}
        </div>
      </div>
    </>
  );
}
