import { ConfirmationModal, Loader } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useRemoveWritePermissions } from "../Tabs/useSuperAdminOrganizations";

export function RemoveOrganizationWritePermissionsModal({
  organization,
}: {
  organization: string;
}) {
  const { t } = useTranslation("superAdmin");

  const {
    mutate: removeWritePermissions,
    isSuccess,
    isLoading,
  } = useRemoveWritePermissions(organization);

  return (
    <>
      <ConfirmationModal
        className="ml-7 mt-7"
        buttonProps={{ variant: "primary", color: "danger" }}
        buttonText={t`form.deleteWritePermission.button`}
        confirmText={t`form.deleteWritePermission.confirm`}
        cancelText={t`form.deleteWritePermission.cancel`}
        onConfirm={() => removeWritePermissions()}
        title={t`form.deleteWritePermission.title`}
        prompt={t`form.deleteWritePermission.prompt`}
      />
      {isLoading && <Loader />}
      {isSuccess && t`form.deleteWritePermission.success`}
    </>
  );
}
