// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var removeProjectFromRoute = function (route) {
    if (route instanceof Types_FrontEndRoutes.RootRoute) {
        return Types_FrontEndRoutes.RootRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.TsRoute) {
        return Types_FrontEndRoutes.TsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ReportingProjectListRoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationProjectsRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.PortfolioReportFERoute) {
        return Types_FrontEndRoutes.PortfolioReportFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.AddCostControlProjectFERoute) {
        return Types_FrontEndRoutes.AddCostControlProjectFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.AddEstimationProjectFERoute) {
        return Types_FrontEndRoutes.AddEstimationProjectFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ImportEstimationProjectFERoute) {
        return Types_FrontEndRoutes.ImportEstimationProjectFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ReportsFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.IncomeFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.CostControlFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.InvoicesFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.MonthlyReportFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.CostControlPrintingFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.RenameMainCostGroupsFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ProjectDetailsFERoute) {
        return Types_FrontEndRoutes.ReportingProjectListRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationProjectDetailsFERoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.OrganizationFERoute) {
        return Types_FrontEndRoutes.OrganizationFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationFERoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationReferenceRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ReferenceEstimationElementsRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationResourcesRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.NewEstimationResourcesRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.PrintingFERoute) {
        return new Types_FrontEndRoutes.PrintingFERoute(route.value0);
    };
    if (route instanceof Types_FrontEndRoutes.EstimationResourcePriceListRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationReportsRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.OfferPageRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationBuildingElementsRoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.LogsFERoute) {
        return Types_FrontEndRoutes.LogsFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.NotificationsFERoute) {
        return Types_FrontEndRoutes.NotificationsFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.UIDemoFERoute) {
        return new Types_FrontEndRoutes.UIDemoFERoute(route.value0);
    };
    if (route instanceof Types_FrontEndRoutes.AdminFERoute) {
        return Types_FrontEndRoutes.AdminFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.SuperAdminFERoute) {
        return Types_FrontEndRoutes.SuperAdminFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.IntegrationFERoute) {
        return Types_FrontEndRoutes.IntegrationFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.ReleaseNotesFERoute) {
        return Types_FrontEndRoutes.ReleaseNotesFERoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.DixonFERoute) {
        return Types_FrontEndRoutes.EstimationProjectsRoute.value;
    };
    if (route instanceof Types_FrontEndRoutes.NotFoundFERoute) {
        return Types_FrontEndRoutes.NotFoundFERoute.value;
    };
    throw new Error("Failed pattern match at RouteUtils (line 70, column 32 - line 107, column 37): " + [ route.constructor.name ]);
};
var fromProjectId = function (v) {
    return Data_Show.show(Data_Show.showInt)(v);
};
var routeToUrl = function (route) {
    if (route instanceof Types_FrontEndRoutes.RootRoute) {
        return "/";
    };
    if (route instanceof Types_FrontEndRoutes.TsRoute) {
        return "/new";
    };
    if (route instanceof Types_FrontEndRoutes.ReportingProjectListRoute) {
        return "/" + Types_FrontEndRoutes["fragments"]["cost-control"];
    };
    if (route instanceof Types_FrontEndRoutes.EstimationProjectsRoute) {
        return "/" + Types_FrontEndRoutes.fragments.estimation;
    };
    if (route instanceof Types_FrontEndRoutes.PortfolioReportFERoute) {
        return "/" + Types_FrontEndRoutes["fragments"]["portfolio-report"];
    };
    if (route instanceof Types_FrontEndRoutes.AddCostControlProjectFERoute) {
        return "/add-project";
    };
    if (route instanceof Types_FrontEndRoutes.AddEstimationProjectFERoute) {
        return "/add-estimation-project";
    };
    if (route instanceof Types_FrontEndRoutes.ImportEstimationProjectFERoute) {
        return "/import-estimation-project";
    };
    if (route instanceof Types_FrontEndRoutes.ReportsFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes.fragments.reports));
    };
    if (route instanceof Types_FrontEndRoutes.IncomeFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes.fragments.income));
    };
    if (route instanceof Types_FrontEndRoutes.CostControlFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["cost-control"]));
    };
    if (route instanceof Types_FrontEndRoutes.InvoicesFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes.fragments.invoices));
    };
    if (route instanceof Types_FrontEndRoutes.MonthlyReportFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["monthly-report"]));
    };
    if (route instanceof Types_FrontEndRoutes.CostControlPrintingFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["cost-control-printing"]));
    };
    if (route instanceof Types_FrontEndRoutes.RenameMainCostGroupsFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["rename-main-cost-groups"]));
    };
    if (route instanceof Types_FrontEndRoutes.ProjectDetailsFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["project-details"]));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationProjectDetailsFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-project-details"]));
    };
    if (route instanceof Types_FrontEndRoutes.OrganizationFERoute) {
        return "/" + Types_FrontEndRoutes.fragments.organization;
    };
    if (route instanceof Types_FrontEndRoutes.EstimationFERoute) {
        var optionalComponentIdQuery = (function () {
            if (route.value0.initialSelectedComponent instanceof Data_Maybe.Nothing) {
                return "";
            };
            if (route.value0.initialSelectedComponent instanceof Data_Maybe.Just) {
                return "?componentId=" + Data_Show.show(Data_Show.showInt)(route.value0.initialSelectedComponent.value0);
            };
            throw new Error("Failed pattern match at RouteUtils (line 32, column 34 - line 34, column 60): " + [ route.value0.initialSelectedComponent.constructor.name ]);
        })();
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + (Types_FrontEndRoutes.fragments.estimation + optionalComponentIdQuery)));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationReferenceRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-reference"]));
    };
    if (route instanceof Types_FrontEndRoutes.ReferenceEstimationElementsRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-reference-elements"]));
    };
    if (route instanceof Types_FrontEndRoutes.NewEstimationResourcesRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + (Types_FrontEndRoutes["fragments"]["estimation-resources"] + "/new")));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationResourcesRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-resources"]));
    };
    if (route instanceof Types_FrontEndRoutes.PrintingFERoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes.fragments.printing));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationResourcePriceListRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-resource-price-list"]));
    };
    if (route instanceof Types_FrontEndRoutes.OfferPageRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes.fragments.offer));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationBuildingElementsRoute) {
        var optionalElementIdQuery = (function () {
            if (route.value0.initialSelectedElement instanceof Data_Maybe.Nothing) {
                return "";
            };
            if (route.value0.initialSelectedElement instanceof Data_Maybe.Just) {
                return "?elementId=" + Data_Show.show(Data_Show.showInt)(route.value0.initialSelectedElement.value0);
            };
            throw new Error("Failed pattern match at RouteUtils (line 45, column 32 - line 47, column 56): " + [ route.value0.initialSelectedElement.constructor.name ]);
        })();
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + (Types_FrontEndRoutes["fragments"]["estimation-building-elements"] + optionalElementIdQuery)));
    };
    if (route instanceof Types_FrontEndRoutes.EstimationReportsRoute) {
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + Types_FrontEndRoutes["fragments"]["estimation-reports"]));
    };
    if (route instanceof Types_FrontEndRoutes.LogsFERoute) {
        return "/logs";
    };
    if (route instanceof Types_FrontEndRoutes.NotificationsFERoute) {
        return "/notifications";
    };
    if (route instanceof Types_FrontEndRoutes.UIDemoFERoute && route.value0.componentId instanceof Data_Maybe.Just) {
        return "/" + (Types_FrontEndRoutes.fragments.uiDemo + ("/" + route.value0.componentId.value0));
    };
    if (route instanceof Types_FrontEndRoutes.UIDemoFERoute && route.value0.componentId instanceof Data_Maybe.Nothing) {
        return "/" + Types_FrontEndRoutes.fragments.uiDemo;
    };
    if (route instanceof Types_FrontEndRoutes.AdminFERoute) {
        return "/admin";
    };
    if (route instanceof Types_FrontEndRoutes.SuperAdminFERoute) {
        return "/superadmin";
    };
    if (route instanceof Types_FrontEndRoutes.IntegrationFERoute) {
        return "/integration";
    };
    if (route instanceof Types_FrontEndRoutes.ReleaseNotesFERoute) {
        return "/" + Types_FrontEndRoutes["fragments"]["release-notes"];
    };
    if (route instanceof Types_FrontEndRoutes.DixonFERoute) {
        var optionalQuery = (function () {
            if (route.value0.mFileId instanceof Data_Maybe.Just && (route.value0.mDrawingId instanceof Data_Maybe.Just && route.value0.mMeasurementGroupId instanceof Data_Maybe.Just)) {
                return "?fileId=" + (Data_Show.show(Data_Show.showInt)(route.value0.mFileId.value0) + ("&drawingId=" + (Data_Show.show(Data_Show.showInt)(route.value0.mDrawingId.value0) + ("&measurementGroupId=" + Data_Show.show(Data_Show.showInt)(route.value0.mMeasurementGroupId.value0)))));
            };
            return "";
        })();
        return "/" + (fromProjectId(route.value0.projectId) + ("/" + (Types_FrontEndRoutes.fragments.measurements + optionalQuery)));
    };
    if (route instanceof Types_FrontEndRoutes.NotFoundFERoute) {
        return "/not-found";
    };
    throw new Error("Failed pattern match at RouteUtils (line 11, column 20 - line 64, column 34): " + [ route.constructor.name ]);
};
module.exports = {
    routeToUrl: routeToUrl,
    fromProjectId: fromProjectId,
    removeProjectFromRoute: removeProjectFromRoute
};
