import { Button, Input, Modal } from "@tocoman/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateReportTemplateInfo } from "./hooks/useReportTemplates";
import { ReportTemplate } from "@prisma/client";

type EditTemplateModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  template: ReportTemplate;
};

export const EditTemplateModal = ({
  isOpen,
  closeModal,
  template,
}: EditTemplateModalProps) => {
  const { t } = useTranslation("superAdmin", {
    keyPrefix: "reportDesigner.editTemplateDialog",
  });
  const updateReportInfo = useUpdateReportTemplateInfo();
  const [newTemplateName, setNewTemplateName] = useState<string>(template.name);

  const handleSave = () => {
    if (newTemplateName && newTemplateName !== template.name) {
      updateReportInfo.mutate({ name: newTemplateName, id: template.id });
    }
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  const actions = [
    <>
      <Button
        onClick={handleCancel}
        variant={"text"}
        id="reportDesigner-cancel-template-info-edit"
      >
        {t("cancel")}
      </Button>
      <Button onClick={handleSave} id="reportDesigner-save-template-info-edit">
        {t("save")}
      </Button>
    </>,
  ];

  return (
    <Modal
      title={t("editTemplate")}
      actions={actions}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <>
        <Input
          label={t("name")}
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
        />
      </>
    </Modal>
  );
};
