// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Foreign = require("../Foreign/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var UserId = function (x) {
    return x;
};
var SSOTenantId = function (x) {
    return x;
};
var Pro = (function () {
    function Pro() {

    };
    Pro.value = new Pro();
    return Pro;
})();
var Premium = (function () {
    function Premium() {

    };
    Premium.value = new Premium();
    return Premium;
})();
var Organization = function (x) {
    return x;
};
var OrgQuotaUsage = (function () {
    function OrgQuotaUsage(value0) {
        this.value0 = value0;
    };
    OrgQuotaUsage.create = function (value0) {
        return new OrgQuotaUsage(value0);
    };
    return OrgQuotaUsage;
})();
var CognitoIdentityProviderName = function (x) {
    return x;
};
var AdminetTenantId = function (x) {
    return x;
};
var writePlan = function (v) {
    if (v instanceof Pro) {
        return "pro";
    };
    if (v instanceof Premium) {
        return "premium";
    };
    throw new Error("Failed pattern match at Types.Organization (line 70, column 1 - line 70, column 28): " + [ v.constructor.name ]);
};
var writeParamUserId = ReadParam.writeParamString;
var writeParamSSOTenantId = ReadParam.writeParamString;
var writeParamOrganization = ReadParam.writeParamString;
var writeParamCognitoIdentityProviderName = ReadParam.writeParamString;
var writeParamAdminetTenantId = ReadParam.writeParamString;
var tofuJSONUserId = TofuJSON.readString;
var tofuJSONSSOTenantId = TofuJSON.readString;
var tofuJSONOrganization = TofuJSON.readString;
var tofuJSONCognitoIdentityProviderName = TofuJSON.readString;
var tofuJSONAdminetTenantId = TofuJSON.readString;
var showUserId = Data_Show.showString;
var showSSOTenantId = Data_Show.showString;
var showOrganization = Data_Show.showString;
var showCognitoIdentityProviderName = Data_Show.showString;
var showAdminetTenantId = Data_Show.showString;
var readPlan = function (v) {
    if (v === "pro") {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Pro.value);
    };
    if (v === "premium") {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Premium.value);
    };
    return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.ForeignError("Invalid plan code"));
};
var tofuJSONPlan = {
    readImpl: function (f) {
        return Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readPlan)(TofuJSON.readImpl(TofuJSON.readString)(f));
    },
    writeImpl: (function () {
        var $28 = TofuJSON.writeImpl(TofuJSON.readString);
        return function ($29) {
            return $28(writePlan($29));
        };
    })()
};
var readParamUserId = ReadParam.stringReadParam;
var readParamSSOTenantId = ReadParam.stringReadParam;
var readParamOrganization = ReadParam.stringReadParam;
var readParamCognitoIdentityProviderName = ReadParam.stringReadParam;
var readParamAdminetTenantId = ReadParam.stringReadParam;
var quotaToPrime = function (v) {
    return {
        "cost-control-read": v.value0.costControlRead,
        "cost-control-write": v.value0.costControlWrite,
        "estimation-read": v.value0.estimationRead,
        "estimation-write": v.value0.estimationWrite
    };
};
var primeToQuota = function (q$prime) {
    return new OrgQuotaUsage({
        costControlRead: q$prime["cost-control-read"],
        costControlWrite: q$prime["cost-control-write"],
        estimationRead: q$prime["estimation-read"],
        estimationWrite: q$prime["estimation-write"]
    });
};
var tofuJSONQuotaUsage = {
    readImpl: function (f) {
        return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(primeToQuota)(TofuJSON.readImpl(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "cost-control-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "cost-control-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimation-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimation-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "cost-control-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "cost-control-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimation-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimation-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))(f));
    },
    writeImpl: (function () {
        var $30 = TofuJSON.writeImpl(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "cost-control-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "cost-control-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimation-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimation-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "cost-control-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "cost-control-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimation-read";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimation-write";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "assigned";
            }
        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()));
        return function ($31) {
            return $30(quotaToPrime($31));
        };
    })()
};
var orgQuotaUsageEq = {
    eq: function (x) {
        return function (y) {
            return x.value0.costControlRead.assigned === y.value0.costControlRead.assigned && x.value0.costControlRead.total === y.value0.costControlRead.total && (x.value0.costControlWrite.assigned === y.value0.costControlWrite.assigned && x.value0.costControlWrite.total === y.value0.costControlWrite.total) && (x.value0.estimationRead.assigned === y.value0.estimationRead.assigned && x.value0.estimationRead.total === y.value0.estimationRead.total) && (x.value0.estimationWrite.assigned === y.value0.estimationWrite.assigned && x.value0.estimationWrite.total === y.value0.estimationWrite.total);
        };
    }
};
var ordUserId = Data_Ord.ordString;
var ordSSOTenantId = Data_Ord.ordString;
var ordOrganization = Data_Ord.ordString;
var ordCognitoIdentityProviderName = Data_Ord.ordString;
var ordAdminetTenantId = Data_Ord.ordString;
var newtypeUserId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeOrganization = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCognitoIdentityProviderName = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeAdminetTenantId = {
    Coercible0: function () {
        return undefined;
    }
};
var newTypeSSOTenantId = {
    Coercible0: function () {
        return undefined;
    }
};
var genericPlan = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Pro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return Premium.value;
        };
        throw new Error("Failed pattern match at Types.Organization (line 64, column 1 - line 64, column 46): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Pro) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Premium) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Organization (line 64, column 1 - line 64, column 46): " + [ x.constructor.name ]);
    }
};
var showPlan = {
    show: Data_Show_Generic.genericShow(genericPlan)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "Pro";
        }
    }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "Premium";
        }
    })))
};
var eqUserId = Data_Eq.eqString;
var eqSSOTenantId = Data_Eq.eqString;
var eqPlan = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Pro && y instanceof Pro) {
                return true;
            };
            if (x instanceof Premium && y instanceof Premium) {
                return true;
            };
            return false;
        };
    }
};
var ordPlan = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Pro && y instanceof Pro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Pro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Pro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Premium && y instanceof Premium) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Organization (line 63, column 1 - line 63, column 36): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqPlan;
    }
};
var eqOrganization = Data_Eq.eqString;
var eqCognitoIdentityProviderName = Data_Eq.eqString;
var eqAdminetTenantId = Data_Eq.eqString;
var emptyQuotaRows = {
    costControlRead: {
        total: 0,
        assigned: 0
    },
    costControlWrite: {
        total: 0,
        assigned: 0
    },
    estimationRead: {
        total: 0,
        assigned: 0
    },
    estimationWrite: {
        total: 0,
        assigned: 0
    }
};
module.exports = {
    Organization: Organization,
    UserId: UserId,
    CognitoIdentityProviderName: CognitoIdentityProviderName,
    AdminetTenantId: AdminetTenantId,
    SSOTenantId: SSOTenantId,
    Pro: Pro,
    Premium: Premium,
    writePlan: writePlan,
    readPlan: readPlan,
    OrgQuotaUsage: OrgQuotaUsage,
    emptyQuotaRows: emptyQuotaRows,
    quotaToPrime: quotaToPrime,
    primeToQuota: primeToQuota,
    newtypeUserId: newtypeUserId,
    tofuJSONUserId: tofuJSONUserId,
    readParamUserId: readParamUserId,
    writeParamUserId: writeParamUserId,
    showUserId: showUserId,
    eqUserId: eqUserId,
    ordUserId: ordUserId,
    newtypeOrganization: newtypeOrganization,
    tofuJSONOrganization: tofuJSONOrganization,
    readParamOrganization: readParamOrganization,
    writeParamOrganization: writeParamOrganization,
    showOrganization: showOrganization,
    eqOrganization: eqOrganization,
    ordOrganization: ordOrganization,
    newtypeCognitoIdentityProviderName: newtypeCognitoIdentityProviderName,
    tofuJSONCognitoIdentityProviderName: tofuJSONCognitoIdentityProviderName,
    readParamCognitoIdentityProviderName: readParamCognitoIdentityProviderName,
    writeParamCognitoIdentityProviderName: writeParamCognitoIdentityProviderName,
    showCognitoIdentityProviderName: showCognitoIdentityProviderName,
    eqCognitoIdentityProviderName: eqCognitoIdentityProviderName,
    ordCognitoIdentityProviderName: ordCognitoIdentityProviderName,
    newtypeAdminetTenantId: newtypeAdminetTenantId,
    tofuJSONAdminetTenantId: tofuJSONAdminetTenantId,
    readParamAdminetTenantId: readParamAdminetTenantId,
    writeParamAdminetTenantId: writeParamAdminetTenantId,
    showAdminetTenantId: showAdminetTenantId,
    eqAdminetTenantId: eqAdminetTenantId,
    ordAdminetTenantId: ordAdminetTenantId,
    newTypeSSOTenantId: newTypeSSOTenantId,
    tofuJSONSSOTenantId: tofuJSONSSOTenantId,
    readParamSSOTenantId: readParamSSOTenantId,
    writeParamSSOTenantId: writeParamSSOTenantId,
    showSSOTenantId: showSSOTenantId,
    eqSSOTenantId: eqSSOTenantId,
    ordSSOTenantId: ordSSOTenantId,
    eqPlan: eqPlan,
    ordPlan: ordPlan,
    genericPlan: genericPlan,
    showPlan: showPlan,
    tofuJSONPlan: tofuJSONPlan,
    orgQuotaUsageEq: orgQuotaUsageEq,
    tofuJSONQuotaUsage: tofuJSONQuotaUsage
};
