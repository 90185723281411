var Amplify = require("aws-amplify");
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");
var { getClientConfigVar } = require("src/client/config");
const {
  isSignedIn,
  getAccessToken: getAdmicomSsoAccessToken,
} = require("src/client-ts/auth/oidc");

var Auth = Amplify.Auth;

function configureAmplify() {
  Amplify.default.configure({
    Auth: {
      region: getClientConfigVar("CLIENT_REGION"),
      userPoolId: getClientConfigVar("CLIENT_USER_POOL_ID"),
      userPoolWebClientId: getClientConfigVar("CLIENT_USER_POOL_WEB_CLIENT_ID"),
      mandatorySignIn: false,
    },
  });
}

function getAccessToken() {
  // The whole thing is wrapped in an extra promise to make wasm-bindgen happy
  return new Promise((resolve, reject) => {
    if (isSignedIn()) {
      // Admicom SSO (keycloak)
      return getAdmicomSsoAccessToken()
        .then((token) => resolve(token))
        .catch((err) => {
          if (getClientConfigVar("CLIENT_AUTH_DISABLE") !== "true") {
            console.error("Admicom SSO - getAccessToken()", err);
            return reject(err);
          }

          return resolve(null);
        });
    } else {
      // Cognito
      return Auth.currentSession()
        .then(function (session) {
          const token = session.getIdToken().getJwtToken();
          return resolve(token);
        })
        .catch((err) => {
          if (getClientConfigVar("CLIENT_AUTH_DISABLE") !== "true") {
            console.error("Cognito - getAccessToken()", err);
            return reject(err);
          }

          return resolve(null);
        });
    }
  }).catch(() => {
    window.location.href = "/";
  });
}

function getEmail() {
  return Auth.currentAuthenticatedUser()
    .then(function (user) {
      return user.attributes.email;
    })
    .catch(function (err) {
      if (getClientConfigVar("CLIENT_AUTH_DISABLE") !== "true") {
        console.error("getEmail()", err);
      }
      return err;
    });
}

function getUserId() {
  return Auth.currentAuthenticatedUser()
    .then(function (user) {
      return user.attributes.sub;
    })
    .catch(function (err) {
      console.error("getUserId()", err);
      return err;
    });
}

function signOutWithoutReload() {
  return Auth.signOut();
}

function signOut() {
  return Auth.signOut()
    .then(function () {
      window.location.reload(true);
    })
    .catch(function (err) {
      console.error("Error when signing out", err);
    });
}

function oAuthLogin() {
  const STAGE = getClientConfigVar("CLIENT_STAGE");
  const IS_DEV = STAGE === "local" || STAGE === "dev";

  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get("code");
  if (!code) {
    Promise.reject("Missing code in window location for oauth login flow");
  } else {
    const formData = new URLSearchParams({
      grant_type: "authorization_code",
      code,
      client_id: getClientConfigVar("CLIENT_USER_POOL_WEB_CLIENT_ID"),
      redirect_uri: IS_DEV
        ? "https://dev.tocoman.fi/oauth2/redirect"
        : "https://app.tocoman.fi/oauth2/redirect",
    });

    return fetch(
      IS_DEV
        ? "https://tocoman-dev.auth.eu-central-1.amazoncognito.com/oauth2/token"
        : "https://tocoman-app.auth.eu-central-1.amazoncognito.com/oauth2/token",
      { method: "POST", body: formData }
    )
      .then((resp) => {
        // we will need this for debugging at some point
        console.log("handleAuthResponse response:", resp);
        return resp.json();
      })
      .then((json) => {
        // we will need this for debugging at some point
        console.log("handleAuthResponse json:", json);

        const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
          AccessToken: json.access_token,
        });
        const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
          IdToken: json.id_token,
        });
        const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
          RefreshToken: json.refresh_token,
        });

        const session = new AmazonCognitoIdentity.CognitoUserSession({
          IdToken,
          AccessToken,
          RefreshToken,
        });

        // manually hook up the session to Amplify
        // https://docs.aws.amazon.com/cognito/latest/developerguide/authentication.html
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: getClientConfigVar("CLIENT_USER_POOL_ID"),
          ClientId: getClientConfigVar("CLIENT_USER_POOL_WEB_CLIENT_ID"),
        });
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: AccessToken.payload.username, // could source username in another way, maybe
          Pool: userPool,
        });

        cognitoUser.setSignInUserSession(session);

        // now that the user session is set, we should be done here.
        return true;
      })
      .catch(function (err) {
        console.error("Error from oAuthLogin", err);
        throw err;
      });
  }
}

exports.oAuthLogin = oAuthLogin;
exports.getAccessToken = getAccessToken;
exports.getEmail = getEmail;
exports.getUserId = getUserId;
exports.signOut = signOut;
exports.signOutWithoutReload = signOutWithoutReload;
exports.configureAmplify = configureAmplify;
