import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MultivalueStringCombobox } from "../../../../components/MultivalueStringCombobox";
import { dataSourceDefinitions } from "../datasources";

export const DataSourceSelector = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const dataSourceNames = Object.keys(dataSourceDefinitions);

  return (
    <Controller
      name={"dataSources"}
      render={({ field: { onChange, value = [] } }) => (
        <MultivalueStringCombobox
          label={t("dataSources")}
          initialValue={value}
          items={dataSourceNames}
          onValueChange={onChange}
        />
      )}
    />
  );
};
