import { AxiosError } from "axios";
import { useQuery } from "react-query";
import axiosRestApiClient from "src/client-ts/axiosRestApiConfig";
import { reportError } from "src/client-ts/utils/error";

export function useProjectDetailsQuery(projectId: number) {
  const queryUrl = `/api/v1/estima/projects/${projectId}`;
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosRestApiClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching project details from REST API", error);
    },
  });
}

export function useProjectLocationsQuery(projectId: number) {
  const queryUrl = `/api/v1/estima/projects/${projectId}/locations?limit=10000`;
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosRestApiClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching locations from REST API", error);
    },
  });
}
