import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import axiosClient from "../../../axiosConfig";
import { OrgWithAdminetTenants } from "../../../../server-ts/super-admin/organizations/SuperAdminOrganizationQueries";
import { reportError } from "../../../utils/error";
import { AxiosError } from "axios";

const baseUrl = "/super-admin/organizations";

export function useGetOrganizations(): OrgWithAdminetTenants[] | undefined {
  const { data } = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<OrgWithAdminetTenants[]>(baseUrl);
      return data;
    },
  });
  return data;
}
export function useUpdateOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: OrgWithAdminetTenants) => {
      const { data } = await axiosClient.put<OrgWithAdminetTenants>(
        `${baseUrl}/${organization.name}`,
        organization
      );
      return data;
    },
    onSettled: () => {
      return invalidateOrganizations(queryClient);
    },
  });
}

export function useCreateOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: OrgWithAdminetTenants) => {
      const { data } = await axiosClient.post<OrgWithAdminetTenants>(
        `${baseUrl}`,
        organization
      );
      return data;
    },
    onSettled: () => {
      return invalidateOrganizations(queryClient);
    },
    onError: (e) => {
      if (e instanceof AxiosError && e.response?.status === 409) {
        reportError(e.response?.data?.message, e as Error);
      } else {
        reportError(
          "An error occurred while creating the organization",
          e as Error
        );
      }
    },
  });
}

function invalidateOrganizations(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [baseUrl],
  });
}

export function useRemoveWritePermissions(organization: string) {
  return useMutation({
    mutationFn: async () => {
      const response = await axiosClient.delete(
        `${baseUrl}/write-permissions/${organization}`
      );
      return response.data;
    },
    onError: (e) => {
      if (e instanceof AxiosError && e.response?.status === 409) {
        reportError(e.response?.data?.message, e as Error);
      } else {
        reportError(
          "An error occurred while removing write permissions",
          e as Error
        );
      }
    },
  });
}
