import { Auth } from "aws-amplify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { getClientConfigVar } from "../../client/config";
import { useWorkerState } from "../hooks/useWorkerState";
import { getUser } from "./oidc";

export interface AuthenticatedUser {
  userId: string;
  email: string;
  organization?: string;
}

const anonymousUser: AuthenticatedUser = {
  userId: "anonymous",
  email: "anonymous",
};

export const CurrentUserContext = createContext<AuthenticatedUser>(
  anonymousUser
);

export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const currentOrganization = useWorkerState("GetCurrentOrganizationState");

  const [currentUser, setCurrentUser] = useState<AuthenticatedUser | undefined>(
    undefined
  );

  useEffect(() => {
    const getOidcUserAsAuthenticatedUser = async (): Promise<AuthenticatedUser | null> => {
      try {
        const user = await getUser();

        return user && user.profile.email
          ? {
              // NOTE: This is not actually the user id, it's the sub from the oidc token which
              // is not the same as the user id in case of Admicom SSO. But since this is only used
              // to identify the user in posthog and datadog it should be fine for now.
              // TODO: Get the actual user id using useUserData hook if nedeed or refactor the code
              // to use that hook and deprecate this one.
              userId: user.profile.sub,
              email: user.profile.email,
              organization: currentOrganization?.organization,
            }
          : null;
      } catch (err) {
        console.error("Error getting Admincom SSO user:", err);

        return null;
      }
    };

    const getCognitoUserAsAuthenticatedUser = async (): Promise<AuthenticatedUser | null> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        return cognitoUser
          ? {
              userId: cognitoUser.getUsername(),
              email: cognitoUser.getSignInUserSession()?.getIdToken().payload
                .email,
              organization: currentOrganization?.organization,
            }
          : null;
      } catch (err) {
        // Error getting Cognito user is not fatal, just return null

        return null;
      }
    };

    getOidcUserAsAuthenticatedUser().then((oidcUser) => {
      if (oidcUser) {
        setCurrentUser(oidcUser);
      } else {
        getCognitoUserAsAuthenticatedUser().then((cognitoUser) => {
          if (cognitoUser) {
            setCurrentUser(cognitoUser);
          } else if (getClientConfigVar("CLIENT_AUTH_DISABLE") === "true") {
            setCurrentUser(anonymousUser);
          }
        });
      }
    });
  }, [currentOrganization]);

  // Don't load the UI until we know the current user
  if (currentUser === undefined) return <></>;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
}
