import { ColDef } from "ag-grid-enterprise";
import { useTranslation } from "react-i18next";
import { Table } from "src/client-ts/components/Table";
import {
  OrganizationStatus,
  usePricelistOrganizations,
} from "./usePricelistOrganizations";
type PricelistUpdateStatusProps = {
  updateStarted: boolean;
  onOrgSelectionChange: (selectedOrgs: string[]) => void;
};
export function PricelistUpdateStatus({
  updateStarted,
  onOrgSelectionChange,
}: PricelistUpdateStatusProps) {
  const { t } = useTranslation("superAdmin", { keyPrefix: "pricelist" });

  const organizations = usePricelistOrganizations(updateStarted);

  const columnDefs: ColDef<OrganizationStatus>[] = [
    {
      headerCheckboxSelection: true,
      flex: 0.25,
      sortable: false,
    },
    {
      field: "organizationName",
      headerName: t`organization`,
      flex: 1,
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t`status`,
      filter: "agSetColumnFilter",
      flex: 1,
      cellStyle: (params) => {
        if (!params.data?.status) return;
        if (
          params.data.status === "SCHEDULED" ||
          params.data.status === "IN_PROGRESS"
        ) {
          return { color: "#8B8000" };
        } else if (params.data.status === "SUCCESSFUL") {
          return { color: "green" };
        } else if (params.data.status === "FAILED") {
          return { color: "red" };
        }
      },
    },
    {
      field: "successfulAt",
      headerName: t`successfulAt`,
      flex: 1,
      filter: "agDateColumnFilter",
      valueGetter: (params) => {
        return params.data?.successfulAt
          ? new Date(params.data.successfulAt).toLocaleString("FI-fi")
          : "";
      },
    },
  ];

  return (
    <div className="h-full w-2/3 mr-3">
      <Table
        className="h-full w-full"
        columnDefs={columnDefs}
        rowData={organizations}
        rowSelection={{ mode: "multiRow" }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          floatingFilter: true,
        }}
        suppressRowClickSelection
        getRowId={(row) => row.data.organizationName}
        onSelectionChanged={(e) => {
          const selectedRows = e.api.getSelectedRows();
          onOrgSelectionChange(selectedRows.map((row) => row.organizationName));
        }}
      />
    </div>
  );
}
