// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Constants = require("../Constants/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var FileUpload_Styles = require("../FileUpload.Styles/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Index = require("../Foreign.Index/index.js");
var Locale = require("../Locale/index.js");
var Milkis = require("../Milkis/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var Types_TypeString = require("../Types.TypeString/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var Utils = require("../Utils/index.js");
var CostGroupsUpload = (function () {
    function CostGroupsUpload() {

    };
    CostGroupsUpload.value = new CostGroupsUpload();
    return CostGroupsUpload;
})();
var InstallmentsUpload = (function () {
    function InstallmentsUpload() {

    };
    InstallmentsUpload.value = new InstallmentsUpload();
    return InstallmentsUpload;
})();
var InvoicesUpload = (function () {
    function InvoicesUpload() {

    };
    InvoicesUpload.value = new InvoicesUpload();
    return InvoicesUpload;
})();
var SAPSalariesUpload = (function () {
    function SAPSalariesUpload() {

    };
    SAPSalariesUpload.value = new SAPSalariesUpload();
    return SAPSalariesUpload;
})();
var SalariesUpload = (function () {
    function SalariesUpload() {

    };
    SalariesUpload.value = new SalariesUpload();
    return SalariesUpload;
})();
var EstimationComponentsUpload = (function () {
    function EstimationComponentsUpload() {

    };
    EstimationComponentsUpload.value = new EstimationComponentsUpload();
    return EstimationComponentsUpload;
})();
var EstimationQuantityByCostClassUpload = (function () {
    function EstimationQuantityByCostClassUpload() {

    };
    EstimationQuantityByCostClassUpload.value = new EstimationQuantityByCostClassUpload();
    return EstimationQuantityByCostClassUpload;
})();
var EstimationQuantityByLocationUpload = (function () {
    function EstimationQuantityByLocationUpload() {

    };
    EstimationQuantityByLocationUpload.value = new EstimationQuantityByLocationUpload();
    return EstimationQuantityByLocationUpload;
})();
var OrgResourcesUpload = (function () {
    function OrgResourcesUpload() {

    };
    OrgResourcesUpload.value = new OrgResourcesUpload();
    return OrgResourcesUpload;
})();
var OrgPriceListUpload = (function () {
    function OrgPriceListUpload() {

    };
    OrgPriceListUpload.value = new OrgPriceListUpload();
    return OrgPriceListUpload;
})();
var OrgResourceSubGroupingUpload = (function () {
    function OrgResourceSubGroupingUpload() {

    };
    OrgResourceSubGroupingUpload.value = new OrgResourceSubGroupingUpload();
    return OrgResourceSubGroupingUpload;
})();
var FieldTooLong = (function () {
    function FieldTooLong() {

    };
    FieldTooLong.value = new FieldTooLong();
    return FieldTooLong;
})();
var ValidationError = (function () {
    function ValidationError(value0) {
        this.value0 = value0;
    };
    ValidationError.create = function (value0) {
        return new ValidationError(value0);
    };
    return ValidationError;
})();
var UnknownUploadError = (function () {
    function UnknownUploadError() {

    };
    UnknownUploadError.value = new UnknownUploadError();
    return UnknownUploadError;
})();
var uploadStatusToJSX = function (v) {
    if (v instanceof Types_UploadStatus.Standby) {
        return React_Basic.empty;
    };
    if (v instanceof Types_UploadStatus.Uploading) {
        return TacoLoader.component()()({
            size: "1.15rem"
        });
    };
    if (v instanceof Types_UploadStatus.Done) {
        return React_Basic_DOM.text(Locale.lookup_("done"));
    };
    if (v instanceof Types_UploadStatus["Error"]) {
        return React_Basic_DOM.text(Locale.lookup_("error"));
    };
    throw new Error("Failed pattern match at FileUpload (line 114, column 1 - line 114, column 44): " + [ v.constructor.name ]);
};
var uploadFileLabel = function (v) {
    var uploadButton = TacoButton.component()()({
        onClick: new Data_Maybe.Just(DOMUtils.triggerClick(v.uniqueId)),
        className: v.className,
        text: Data_Maybe.fromMaybe(v.label)(v.filename),
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileUploadV1.value),
        align: TacoButton_Types.Left.value,
        testId: v.uniqueId
    });
    return React_Basic_DOM_Generated.label()({
        htmlFor: v.uniqueId,
        className: "upload-file-label",
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(uploadButton)
    });
};
var readUploadError = function (v) {
    var v1 = function (v2) {
        var v3 = function (v4) {
            var v5 = function (v6) {
                var v7 = function (v8) {
                    return UnknownUploadError.value;
                };
                var $30 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "msg";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(Types_TypeString.tofuJSON({
                    reflectSymbol: function () {
                        return "OrgResourceDatabaseTriggersError";
                    }
                }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(Types_TypeString.tofuJSON({
                    reflectSymbol: function () {
                        return "OrgResourceDatabaseTriggersError";
                    }
                }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "msg";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(Types_TypeString.tofuJSON({
                    reflectSymbol: function () {
                        return "OrgResourceDatabaseTriggersError";
                    }
                }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(Types_TypeString.tofuJSON({
                    reflectSymbol: function () {
                        return "OrgResourceDatabaseTriggersError";
                    }
                }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(v);
                if ($30 instanceof Data_Either.Right) {
                    return ValidationError.create("Sis\xe4inen tietokantavirhe YlePanosAlaRyhma tallennuksessa. Ongelman toistuessa ole yhteydess\xe4 tukeen.");
                };
                return v7(true);
            };
            var $33 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "invalidOrgResourceGroupIds";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantUploadWithoutExistingOrgResourceGroupError";
                }
            }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "invalidOrgResourceGroupIds";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantUploadWithoutExistingOrgResourceGroupError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "invalidOrgResourceGroupIds";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantUploadWithoutExistingOrgResourceGroupError";
                }
            }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "invalidOrgResourceGroupIds";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "groupId";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceListId";
                }
            })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantUploadWithoutExistingOrgResourceGroupError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()()))(v);
            if ($33 instanceof Data_Either.Right) {
                var messages = Data_Functor.map(Data_Functor.functorArray)(function (v6) {
                    return v6.groupId + (" (hinnastotunnus " + (Data_Show.show(Types_Component.showOrgEstimationResourceId)(v6.priceListId) + ")"));
                })($33.value0.msg.invalidOrgResourceGroupIds);
                return ValidationError.create("Panoshinnaston panoslaji puuttuu: " + Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(", ")(messages));
            };
            return v5(true);
        };
        var $39 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_TypeString.tofuJSON({
            reflectSymbol: function () {
                return "ExcelUploadValidationError";
            }
        }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_TypeString.tofuJSON({
            reflectSymbol: function () {
                return "ExcelUploadValidationError";
            }
        }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_TypeString.tofuJSON({
            reflectSymbol: function () {
                return "ExcelUploadValidationError";
            }
        }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_TypeString.tofuJSON({
            reflectSymbol: function () {
                return "ExcelUploadValidationError";
            }
        }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()()))(v);
        if ($39 instanceof Data_Either.Right) {
            return new ValidationError($39.value0.msg.msg);
        };
        return v3(true);
    };
    var $42 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "msg";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "type";
        }
    })(Types_TypeString.tofuJSON({
        reflectSymbol: function () {
            return "ExcelUploadFieldTooLongError";
        }
    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "type";
        }
    })(Types_TypeString.tofuJSON({
        reflectSymbol: function () {
            return "ExcelUploadFieldTooLongError";
        }
    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "msg";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "type";
        }
    })(Types_TypeString.tofuJSON({
        reflectSymbol: function () {
            return "ExcelUploadFieldTooLongError";
        }
    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "type";
        }
    })(Types_TypeString.tofuJSON({
        reflectSymbol: function () {
            return "ExcelUploadFieldTooLongError";
        }
    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(v);
    if ($42 instanceof Data_Either.Right) {
        return FieldTooLong.value;
    };
    return v1(true);
};
var mkUploadExcelPath = function (mProjectId) {
    return function (endpoint) {
        if (mProjectId instanceof Data_Maybe.Just) {
            return "/api/project/" + (Data_Show.show(Types_Project.showProjectId)(mProjectId.value0) + endpoint);
        };
        if (mProjectId instanceof Data_Maybe.Nothing) {
            return endpoint;
        };
        throw new Error("Failed pattern match at FileUpload (line 122, column 3 - line 126, column 15): " + [ mProjectId.constructor.name ]);
    };
};
var excelErrorMessage = function (uploadType) {
    var exampleLink = (function () {
        if (uploadType instanceof CostGroupsUpload) {
            return Constants.costGroupsUploadTemplate;
        };
        if (uploadType instanceof InstallmentsUpload) {
            return Constants.installmentsUploadTemplate;
        };
        if (uploadType instanceof InvoicesUpload) {
            return Constants.invoicesUploadTemplate;
        };
        if (uploadType instanceof SAPSalariesUpload) {
            return Constants.sapSalariesUploadTemplate;
        };
        if (uploadType instanceof SalariesUpload) {
            return Constants.salariesUploadTemplate;
        };
        if (uploadType instanceof EstimationComponentsUpload) {
            return Constants.estimationComponentsUploadTemplateFi;
        };
        if (uploadType instanceof EstimationQuantityByCostClassUpload) {
            return Constants.estimationQuantityByCostClassUploadTemplate;
        };
        if (uploadType instanceof EstimationQuantityByLocationUpload) {
            return Constants.estimationQuantityByLocationUploadTemplate;
        };
        if (uploadType instanceof OrgResourcesUpload) {
            return Constants.orgResourcesUploadTemplate;
        };
        if (uploadType instanceof OrgPriceListUpload) {
            return Constants.orgPriceListUploadTemplate;
        };
        if (uploadType instanceof OrgResourceSubGroupingUpload) {
            return Constants.orgResourceSubGroupingUploadTemplate;
        };
        throw new Error("Failed pattern match at FileUpload (line 202, column 19 - line 213, column 75): " + [ uploadType.constructor.name ]);
    })();
    var exampleLinkClause = (function () {
        var $47 = exampleLink === "TODOMAKETEMPLATE";
        if ($47) {
            return "";
        };
        return "\x0aLataa esimerkkitiedosto: " + exampleLink;
    })();
    return "Excel-tiedostossa on virheit\xe4." + (exampleLinkClause + "\x0a");
};
var uploadExcel = function (uploadType) {
    return function (path) {
        return function (dispatch) {
            return function (file) {
                return function (setUploadStatus) {
                    return function (onSuccess) {
                        return Effect_Aff.launchAff_(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(setUploadStatus(function (v) {
                            return Types_UploadStatus.Uploading.value;
                        })))(function () {
                            var dispatch$prime = (function () {
                                var $65 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                                return function ($66) {
                                    return $65(dispatch($66));
                                };
                            })();
                            return Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.getAuthorization)(function (authorization) {
                                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)($foreign["_prepareFormData"](file)))(function (content) {
                                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.fetch()(Milkis.URL(path))({
                                        method: Milkis.postMethod,
                                        headers: Milkis.makeHeaders()({
                                            Authorization: authorization
                                        }),
                                        body: Types_ReasonableCoerce.reasonableCoerce("formData is a valid body content for fetch")(content)
                                    })))(function (response) {
                                        if (response instanceof Data_Either.Right) {
                                            return Control_Bind.bind(Effect_Aff.bindAff)(Milkis.text(response.value0))(function (value) {
                                                var $49 = Milkis.statusCode(response.value0) === 200;
                                                if ($49) {
                                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(setUploadStatus(function (v) {
                                                        return Types_UploadStatus.Done.value;
                                                    })))(function () {
                                                        return onSuccess;
                                                    });
                                                };
                                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(setUploadStatus(function (v) {
                                                    return Types_UploadStatus["Error"].value;
                                                })))(function () {
                                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("failed to upload file: " + value))(function () {
                                                        var v = readUploadError(value);
                                                        if (v instanceof FieldTooLong) {
                                                            return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_invalid_excel_payload_field_too_long")));
                                                        };
                                                        if (v instanceof ValidationError) {
                                                            return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(excelErrorMessage(uploadType) + v.value0));
                                                        };
                                                        if (v instanceof UnknownUploadError) {
                                                            var v1 = Milkis.statusCode(response.value0);
                                                            if (v1 === 400) {
                                                                return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_component_code_must_be_unique")));
                                                            };
                                                            return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")));
                                                        };
                                                        throw new Error("Failed pattern match at FileUpload (line 160, column 11 - line 174, column 79): " + [ v.constructor.name ]);
                                                    });
                                                });
                                            });
                                        };
                                        if (response instanceof Data_Either.Left) {
                                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(setUploadStatus(function (v) {
                                                return Types_UploadStatus["Error"].value;
                                            })))(function () {
                                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                                                    return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")));
                                                });
                                            });
                                        };
                                        throw new Error("Failed pattern match at FileUpload (line 150, column 3 - line 179, column 67): " + [ response.constructor.name ]);
                                    });
                                });
                            });
                        }));
                    };
                };
            };
        };
    };
};
var mkFileUpload = TofuHooks.mkHookComponent("FileUpload")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var handleTarget = function (target) {
            var v = function (v1) {
                if (Data_Boolean.otherwise) {
                    return function __do() {
                        Effect_Class_Console.error(Effect_Class.monadEffectEffect)("error reading files from event")();
                        return appDispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")))();
                    };
                };
                throw new Error("Failed pattern match at FileUpload (line 55, column 1 - line 55, column 32): " + [ target.constructor.name ]);
            };
            var $57 = TofuJSON.read(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "files";
                }
            })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "files";
                }
            })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()()))(Foreign.unsafeToForeign(target));
            if ($57 instanceof Data_Either.Right) {
                var $58 = Control_Monad_Except.runExcept(Foreign_Index.readIndex(Data_Identity.monadIdentity)(0)($57.value0.files));
                if ($58 instanceof Data_Either.Right) {
                    var $59 = TofuJSON.read(TofuJSON.readMaybe(TofuJSON.tofuJSONForeign))($58.value0);
                    if ($59 instanceof Data_Either.Right) {
                        if ($59.value0 instanceof Data_Maybe.Just) {
                            return function __do() {
                                props.setFilename(function (v1) {
                                    return new Data_Maybe.Just((Types_ReasonableCoerce.reasonableCoerce("file always has name")($59.value0.value0)).name);
                                })();
                                var path = mkUploadExcelPath(props.projectId)(props.endpointUrl);
                                return uploadExcel(props.uploadType)(path)(appDispatch)($59.value0.value0)(props.setUploadStatus)(props.onSuccess)();
                            };
                        };
                        if ($59.value0 instanceof Data_Maybe.Nothing) {
                            return function __do() {
                                props.setUploadStatus(function (v1) {
                                    return Types_UploadStatus.Standby.value;
                                })();
                                return props.setFilename(function (v1) {
                                    return Data_Maybe.Nothing.value;
                                })();
                            };
                        };
                        throw new Error("Failed pattern match at FileUpload (line 65, column 11 - line 72, column 46): " + [ $59.value0.constructor.name ]);
                    };
                    return v(true);
                };
                return v(true);
            };
            return v(true);
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["input'"]())({
            type: "file",
            accept: ".xlsx",
            className: "file-upload-input",
            css: FileUpload_Styles.fileUploadContainerStyles,
            id: props.uniqueId,
            onChange: React_Basic_Events.handler(React_Basic_DOM_Events.target)(handleTarget)
        });
    };
});
module.exports = {
    mkFileUpload: mkFileUpload,
    uploadFileLabel: uploadFileLabel,
    uploadStatusToJSX: uploadStatusToJSX,
    mkUploadExcelPath: mkUploadExcelPath,
    uploadExcel: uploadExcel,
    CostGroupsUpload: CostGroupsUpload,
    InstallmentsUpload: InstallmentsUpload,
    InvoicesUpload: InvoicesUpload,
    SAPSalariesUpload: SAPSalariesUpload,
    SalariesUpload: SalariesUpload,
    EstimationComponentsUpload: EstimationComponentsUpload,
    EstimationQuantityByCostClassUpload: EstimationQuantityByCostClassUpload,
    EstimationQuantityByLocationUpload: EstimationQuantityByLocationUpload,
    OrgResourcesUpload: OrgResourcesUpload,
    OrgPriceListUpload: OrgPriceListUpload,
    OrgResourceSubGroupingUpload: OrgResourceSubGroupingUpload,
    excelErrorMessage: excelErrorMessage,
    FieldTooLong: FieldTooLong,
    ValidationError: ValidationError,
    UnknownUploadError: UnknownUploadError,
    readUploadError: readUploadError,
    "_prepareFormData": $foreign["_prepareFormData"]
};
