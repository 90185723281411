import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { getClientConfigVarOptional } from "../../../../../client/config";
import { dataSourceDefinitions } from "../datasources";
import { ParameterValueMap } from "../../../Estimation/Report/types";
import { ComponentPropsMap } from "../../../Estimation/Report/AllReportParameters";
import { parameterSchema } from "../parameterSchema";

const authHeader = `Header$Authorization=Bearer " & Parameters!token.Value`;

const createDataSource = (
  baseUrl: string,
  name: string,
  url = ""
): ARJS.DataSource => ({
  Name: name,
  ConnectionProperties: {
    DataProvider: "JSON",
    ConnectString: `="endpoint=${baseUrl}${url};${authHeader}`,
  },
});

const createDataSets = (
  name: string,
  parameters: ParameterValueMap
): ARJS.DataSet[] => {
  const notDataParams = [
    "projectId",
    "token",
    "reportHeader",
    "reportSubHeader",
    "limit",
  ];
  const { dataSets } = dataSourceDefinitions[name];
  return dataSets
    ? dataSets.map(({ Fields, Query, Params }) => {
        const params = Object.entries(parameters)
          .filter(
            ([key]) =>
              !notDataParams.includes(key) &&
              Params?.includes(key as keyof ComponentPropsMap)
          )
          .map(([key]) => {
            if (parameterSchema[key]?.MultiValue === true) {
              return `&${key}=" & Join(Parameters!${key}.Value, ",") &"`;
            }
            return `&${key}=" & Parameters!${key}.Value &"`;
          });
        const commandTextWithParams = Query.CommandText?.replace(
          ";jpath",
          params.join("") + ";jpath"
        );
        return {
          Name: name,
          Fields,
          Query: { ...Query, CommandText: commandTextWithParams },
        };
      })
    : [];
};

export const getDataSources = (
  dataSourceNameList: string[]
): ARJS.DataSource[] => {
  const baseUrl = getClientConfigVarOptional("CLIENT_REST_API_URL");

  const dataSourcesWithUrl = dataSourceNameList.filter((dataSourceName) => {
    return dataSourceDefinitions[dataSourceName]?.url;
  });

  return dataSourcesWithUrl.map((dataSourceName) =>
    createDataSource(
      baseUrl,
      dataSourceName,
      dataSourceDefinitions[dataSourceName].url
    )
  );
};

export const getDataSourceDataSets = (
  dataSourceNameList: string[],
  parameters: ParameterValueMap,
  dataSets: ARJS.DataSet[] | undefined
): ARJS.DataSet[] => {
  // Using the scopeInformations in report needs ScopeInformations dataSet, this ensures that it is always included even if user does not select it
  const dataSourceNames =
    Object.keys(parameters).includes("scopeInformations") &&
    !dataSourceNameList.includes("ScopeInformations")
      ? dataSourceNameList.concat("ScopeInformations")
      : dataSourceNameList;
  const selectedSets = dataSourceNames.flatMap((name) =>
    createDataSets(name, parameters)
  );
  const datasetsWithFields = selectedSets
    .map((set) => {
      const dataSet = dataSets?.find(({ Name }) => Name === set.Name);
      return dataSet?.Fields ? { ...set, Fields: dataSet.Fields } : dataSet;
    })
    .filter((d): d is ARJS.DataSet => !!d);
  const subDataSets: ARJS.DataSet[] = datasetsWithFields
    .flatMap((set) => {
      return dataSets?.filter((d) =>
        d.Query?.DataSourceName.includes(set.Name)
      );
    })
    .filter((d): d is ARJS.DataSet => !!d);

  const templatesDataSets = [...datasetsWithFields, ...subDataSets];

  const newDataSets = selectedSets.filter(
    (set) => !templatesDataSets.some((oldSet) => oldSet.Name === set.Name)
  );

  return [...templatesDataSets, ...newDataSets];
};

export const getDataSetDependencies = (dataSets: ARJS.DataSet[]): string[] =>
  dataSets
    .map(({ Query }) => Query?.DataSourceName)
    .filter((d): d is string => !!d);

export const getDataSourceNames = (
  dataSources: undefined | ARJS.DataSource[]
): string[] =>
  dataSources
    ? dataSources.map(({ Name }) => Name).filter((n): n is string => !!n)
    : [];

export const getDataSetNames = (
  dataSources: undefined | ARJS.DataSet[]
): string[] =>
  dataSources
    ? dataSources.map(({ Name }) => Name).filter((n): n is string => !!n)
    : [];
