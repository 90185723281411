export const IconVisible = () => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0727 13.8941C26.6333 9.95255 21.8256 7.64287 16.792 7.40438C16.5284 7.37927 16.2523 7.35416 15.9887 7.35416C15.7251 7.35416 15.4489 7.36672 15.1853 7.40438C10.1517 7.63032 5.34407 9.95255 1.90466 13.8815C1.08874 14.8104 1.08874 16.2163 1.90466 17.1452C5.53236 21.3001 10.6664 23.6725 15.9887 23.6725C21.311 23.6725 26.445 21.2875 30.0727 17.1452C30.8886 16.2163 30.8886 14.8104 30.0727 13.8815V13.8941ZM21.0097 14.8983C21.0097 17.6724 18.7628 19.9193 15.9887 19.9193C13.2145 19.9193 10.9676 17.6724 10.9676 14.8983C10.9676 12.1241 13.2145 9.87724 15.9887 9.87724C18.7628 9.87724 21.0097 12.1241 21.0097 14.8983ZM3.78755 15.551C5.39428 13.7183 7.27717 12.2999 9.34835 11.3333C8.77093 12.4003 8.44456 13.6054 8.44456 14.8983C8.44456 16.9946 9.31069 18.9025 10.704 20.2708C8.09309 19.3544 5.72065 17.7477 3.78755 15.551ZM21.2733 20.2582C22.6666 18.89 23.5202 16.9946 23.5202 14.8983C23.5202 13.6054 23.1939 12.3878 22.6164 11.3208C24.6876 12.2748 26.5831 13.6807 28.1898 15.5008C26.2441 17.7226 23.8843 19.3293 21.2733 20.2582ZM13.4782 14.8983H15.9887V12.3878C17.3695 12.3878 18.4992 13.5175 18.4992 14.8983C18.4992 16.2791 17.3695 17.4088 15.9887 17.4088C14.6079 17.4088 13.4782 16.2791 13.4782 14.8983Z"
      fill="#3C4B4C"
    />
  </svg>
);
