import { User, UserManager } from "oidc-client-ts";
import {
  getClientConfigVar,
  getClientConfigVarOptional,
} from "src/client/config";

let oidcUserManager: UserManager | null = null;

export const setupOidcUserManager = () => {
  const config = {
    scopes: ["openid", "profile", "email"],
    authority: getClientConfigVarOptional("CLIENT_ADMICOM_SSO_AUTHORITY"),
    clientId: getClientConfigVarOptional("CLIENT_ADMICOM_SSO_CLIENT_ID"),
    redirectUri: getClientConfigVarOptional("CLIENT_ADMICOM_SSO_REDIRECT_URI"),
  };

  oidcUserManager = new UserManager({
    authority: config.authority,
    client_id: config.clientId,
    redirect_uri: config.redirectUri,
    scope: config.scopes.join(" "),
  });
};

export const getOidcUserManager = () => {
  if (!oidcUserManager) {
    throw new Error("OIDC client not initialized");
  }

  return oidcUserManager;
};

export const signIn = async () => {
  const client = getOidcUserManager();
  await client.signinRedirect();
};

export const signOut = async () => {
  const client = getOidcUserManager();
  await client.signoutRedirect();
};

// NOTE: getUser could be used for this, but this version works synchronously and
// is used in app initializer
export const isSignedIn = () => {
  const json = window.sessionStorage.getItem(
    `oidc.user:${getClientConfigVarOptional(
      "CLIENT_ADMICOM_SSO_AUTHORITY"
    )}:${getClientConfigVarOptional("CLIENT_ADMICOM_SSO_CLIENT_ID")}`
  );

  const tokens = json ? JSON.parse(json) : null;

  return tokens && tokens.id_token !== null;
};

export const getUser = async () => {
  const client = getOidcUserManager();
  const user = await client.getUser();
  return user;
};

export const removeUser = async () => {
  const client = getOidcUserManager();
  await client.removeUser();
};

export const isAccessTokenAboutToExpire = async () => {
  const user = await getUser();
  return user?.expires_in && user.expires_in < 30;
};

let refreshPromise: Promise<User | null> | null = null;

export const getAccessToken = async () => {
  if (getClientConfigVar("CLIENT_AUTH_DISABLE") === "true") {
    return "";
  }

  const client = getOidcUserManager();

  try {
    const user = await client.getUser();
    const expired = await isAccessTokenAboutToExpire();

    if (user?.access_token && !expired) {
      return user.access_token;
    } else if (user) {
      // NOTE: Keycloack invalidates all the tokens if refresh token is used twice.
      if (!refreshPromise) {
        console.log("Refreshing tokens");
        refreshPromise = client.signinSilent();
      }

      const newUser = await refreshPromise;

      if (newUser?.access_token) {
        return newUser.access_token;
      } else {
        throw new Error("User not found after refresh");
      }
    } else {
      throw new Error("User not found");
    }
  } catch (err) {
    console.error(err);

    client.removeUser();
    window.location.href = "/";
  } finally {
    refreshPromise = null;
  }
};
