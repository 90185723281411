import {
  CellClassParams,
  IAggFuncParams,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { CostControlPrintingRow } from "./Printing";
import { CostControlPrintingItem } from "./mapCostControlPrintingRowData";
import { pinnedTotalRowRenderer } from "../../Estimation/Printing/useSetPinnedRow";
import { TFunction } from "i18next";
import { CostControlItem } from "../../../../../ts-bindings/CostControlItem";

export type CostControlPrintingGroup = CostControlPrintingItem &
  CostControlPrintingKeys;
export type CostControlPrintingKeys = {
  targets?: CostControlPrintingItem[] | null;
  estimates?: CostControlPrintingItem[] | null;
  invoices?: CostControlPrintingItem[] | null;
};

export const getMainCostGroup = (params: ValueGetterParams) => {
  const groupHeader = params.data?.mainCostGroup?.name
    ? `${params.data.mainCostGroup.code} - ${params.data.mainCostGroup.name}`
    : params.data?.mainCostGroup?.code;
  return params.data?.mainCostGroup ? groupHeader : null;
};

export const getCostGroup = (params: ValueGetterParams) => {
  return params.data?.costGroup
    ? `${params.data.costGroup.code} - ${params.data.costGroup.name}`
    : null;
};

export function cellStyles(params: CellClassParams) {
  const pivotMode = params.api.getGridOption("pivotMode");
  if (pivotMode) {
    return { fontWeight: "normal" };
  }
  if (params.node.footer || params.node.group || params.node.rowPinned) {
    return { fontWeight: 700 };
  }
}

export function differenceStyle(cellClassParams: CellClassParams) {
  switch (true) {
    case cellClassParams.value > 0:
      return { color: "green", fontWeight: 700, textAlign: "right" };
    case cellClassParams.value < 0:
      return { color: "red", fontWeight: 700, textAlign: "right" };
    case cellClassParams.value === 0:
      return { color: "darkGray", fontWeight: 700, textAlign: "right" };
  }
}

export function reduceEstimateFromTarget(
  params: IAggFuncParams<CostControlPrintingRow>
) {
  if (params.rowNode.level === 0) {
    return countValue(params.values);
  }
  if (params.rowNode.level === 1) {
    const firstChild =
      params.rowNode?.allLeafChildren?.[0]?.data?.costGroup ?? null;
    const target = firstChild?.target ?? 0;
    const estimate = firstChild?.estimate ?? 0;
    return target - estimate;
  }
}

export function getTotalValueFromGroups(
  params: ValueGetterParams,
  column: string
) {
  if (params.node?.rowPinned) {
    const groupSums: number[] = [];
    const componentSums: number[] = [];
    params.api.forEachNodeAfterFilterAndSort((row) => {
      if (row.group && row.level === 0) {
        const rowValue = params.api.getCellValue({
          colKey: column,
          rowNode: row,
        });
        groupSums.push(rowValue);
      }
      if (!row.group) {
        const rowValue = params.api.getCellValue({
          colKey: column,
          rowNode: row,
        });
        componentSums.push(rowValue);
      }
    });
    const groupTotal = groupSums.reduce((prev, curr) => prev + curr, 0);
    const componentTotal = componentSums.reduce((prev, curr) => prev + curr, 0);
    return groupSums.length === 0 ? componentTotal : groupTotal;
  }
}

export function countValue(list: number[]) {
  return list.reduce((prev, curr) => prev + curr, 0);
}

export function sumValues(params: IAggFuncParams, column: string) {
  const columnName = column.split(".")[1];
  if (params.rowNode.field === "mainCostGroup") {
    const values =
      params.rowNode.childrenAfterFilter?.map((child) => {
        if (child.aggData) {
          return child.aggData[column];
        }
        if (!child.data) {
          const leafValues =
            child.allLeafChildren?.map((leaf) => {
              return leaf.data.costGroup[columnName];
            }) ?? [];
          return leafValues;
        } else {
          return child.data.costGroup[columnName];
        }
      }) ?? [];
    return countValue(values);
  }
  if (params.rowNode.field === "costGroup") {
    const columnName = column.split(".")[1];
    return params.rowNode.allLeafChildren?.[0]?.data.costGroup[columnName];
  }
}

export function valueOnlyInGroups(params: ICellRendererParams) {
  if (params.node.group || params.node.rowPinned) {
    return params.valueFormatted;
  }
  return null;
}

export function countValueWithSocialExpenses(
  group: CostControlPrintingGroup,
  column: keyof CostControlPrintingKeys
) {
  if (!group[column]) {
    return 0;
  }
  const groupValues = group[column]?.map((col) => col.value ?? 0) ?? [];
  const value = countValue(groupValues);
  const socials = group[column]?.map((col) => col.socialExpenses ?? 0) ?? [];
  const socialSum = countValue(socials);
  return value + socialSum;
}
export function countSocialExpenses(
  group: CostControlPrintingGroup,
  costClasses: CostClass[],
  column: keyof CostControlPrintingKeys
) {
  if (!group[column] || group[column] === null) {
    return null;
  }
  return group[column]?.map((col) => {
    const socialExpensesCostClass = costClasses.find(
      (costClass) => costClass.costClassCode === col.costClassCode
    );
    if (
      socialExpensesCostClass?.socialExpensePercentageInCostControlForTargets !==
        undefined &&
      socialExpensesCostClass?.socialExpensePercentageInCostControlForEstimatesAndActuals !==
        undefined &&
      col.value
    ) {
      const percentage =
        column === "targets"
          ? socialExpensesCostClass.socialExpensePercentageInCostControlForTargets ??
            0
          : socialExpensesCostClass.socialExpensePercentageInCostControlForEstimatesAndActuals ??
            0;
      const socialExpenses = col.value * percentage;
      return { ...col, socialExpenses: socialExpenses };
    }
    return { ...col, socialExpenses: 0 };
  });
}

export const groupCellRendererSelector = (
  params: ICellRendererParams,
  t: TFunction
) => {
  if (params.node.rowPinned) {
    return {
      component: (p: ICellRendererParams) => pinnedTotalRowRenderer(p, t),
      params: {
        style: { fontWeight: "bold" },
      },
    };
  }
  if (params.node?.field === "mainCostGroup") {
    return { component: "agGroupCellRenderer" };
  }
  if (params.node?.allLeafChildren && params.node?.allLeafChildren.length > 0) {
    if (params.node.allLeafChildren[0]?.data?.component) {
      return { component: "agGroupCellRenderer" };
    }
    if (!params.node.allLeafChildren[0].data.component) {
      return undefined;
    }
  }
  return undefined;
};

export const getComponentsTotalValue = (params: ValueGetterParams) => {
  return params.context.costClasses.reduce(
    (acc: number, currentClass: CostClass, index: number) => {
      const col = `costClasses.${index}.value`;
      if (params.node) {
        const value = params.api.getCellValue({
          colKey: col,
          rowNode: params.node,
        });
        acc += value;

        if (currentClass.hourlyPricing) {
          const percentage =
            currentClass.socialExpensePercentageInCostControlForTargets ?? 0;
          acc += value * percentage;
        }
      }

      return acc;
    },
    0
  );
};

export const sumAllCostClasses = (params: IAggFuncParams) => {
  if (params.rowNode.field === "costGroup") {
    const costClasses: CostClass[] = params.context.costClasses;
    return costClasses.reduce((accumulator, costClass) => {
      const groupCode: string =
        params.rowNode.allLeafChildren?.[0].data.costGroup.code;
      const targets = params.context.targets
        .filter(
          (target: CostControlItem) =>
            target.costGroupCode === groupCode &&
            target.costClassCode === costClass.costClassCode
        )
        .map((target: CostControlItem) => target.value);
      const value = countValue(targets);
      accumulator += value;

      if (costClass.hourlyPricing) {
        const expensesPercentage =
          costClass.socialExpensePercentageInCostControlForTargets ?? 0;
        accumulator += value * expensesPercentage;
      }
      return accumulator;
    }, 0);
  }
  return params.rowNode.field === "mainCostGroup"
    ? countValue(params.values)
    : 0;
};
