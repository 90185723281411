import { Rdl as ARJS } from "@mescius/activereportsjs/core";

export const parameterSchema: Record<string, ARJS.ReportParameter> = {
  token: {
    Name: "token",
    DataType: "String",
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  projectId: {
    Name: "projectId",
    DataType: "String",
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  limit: {
    Name: "limit",
    DataType: "String",
    Hidden: true,
    DefaultValue: {
      Values: ["10000"],
    },
  },
  reportHeader: {
    Name: "reportHeader",
    DataType: "String",
    AllowBlank: true,
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  reportSubHeader: {
    Name: "reportSubHeader",
    DataType: "String",
    AllowBlank: true,
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  includeSocialExpenses: {
    Name: "includeSocialExpenses",
    DataType: "Boolean",
    Nullable: true,
    Hidden: true,
    AllowBlank: true,
    DefaultValue: {
      Values: ["False"],
    },
  },
  subprojectId: {
    Name: "subprojectId",
    DataType: "String",
    AllowBlank: true,
    Nullable: true,
    Hidden: true,
    MultiValue: true,
    DefaultValue: {
      Values: [""],
    },
  },
  locationCodes: {
    Name: "locationCodes",
    DataType: "String",
    AllowBlank: true,
    Hidden: true,
    MultiValue: true,
    DefaultValue: {
      Values: [""],
    },
  },
  showMemo: {
    Name: "showMemo",
    DataType: "Boolean",
    Nullable: true,
    Hidden: true,
    AllowBlank: true,
    DefaultValue: {
      Values: ["False"],
    },
  },
  buildingComponentCodes: {
    Name: "buildingComponentCodes",
    DataType: "String",
    AllowBlank: true,
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  buildingComponentClasses: {
    Name: "buildingComponentClasses",
    DataType: "String",
    AllowBlank: true,
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
  scopeInformations: {
    Name: "scopeInformations",
    DataType: "String",
    AllowBlank: true,
    Nullable: true,
    Hidden: true,
    MultiValue: true,
    DefaultValue: {
      Values: [""],
    },
  },
  scopeInformationDecimals: {
    Name: "scopeInformationDecimals",
    DataType: "Integer",
    AllowBlank: true,
    Nullable: true,
    Hidden: true,
    DefaultValue: {
      Values: [""],
    },
  },
};

export const parameterDefaultValues = Object.keys(parameterSchema).reduce(
  (acc, key) => ({ ...acc, [key]: parameterSchema[key].DefaultValue?.Values }),
  {}
);

export const mandatoryParamNames = ["token", "projectId", "limit"];
