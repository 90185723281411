/* eslint-disable i18next/no-literal-string */
import { useEffect } from "react";
// @ts-ignore
import { signOutWithoutReload } from "../../client/auth/Auth";
import { signIn } from "./oidc";

const REDIRECT_AFTER_MS = 5000;

export default function ForceSsoRedirect() {
  useEffect(() => {
    signOutWithoutReload();

    const timeout = setTimeout(() => {
      signIn();
    }, REDIRECT_AFTER_MS);
    return () => clearTimeout(timeout);
  }, []);

  // TODO: Refactor to use i18n
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p>
        Kirjaudu Admicom SSO:lla. Sinut ohjataan kirjautumiseen automaattisesti
        viiden sekunnin kuluttua. Voit myös jatkaa painamalla{" "}
        <a className="text-blue-500 cursor-pointer" onClick={() => signIn()}>
          tästä.
        </a>
      </p>
    </div>
  );
}
