import { ReportTemplate } from "@prisma/client";
import { Loader } from "@tocoman/ui";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateReportTemplateDialog from "./components/CreateReportTemplateDialog";
import {
  ARJSReportTemplateWithUser,
  useGetReportTemplates,
} from "./hooks/useReportTemplates";
import { ReportTemplateActionsButton } from "./components/ReportTemplateActionsButton";
import { ConfirmDeleteReportTemplateModal } from "./components/ConfirmDeleteReportTemplateModal";
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-enterprise";
import { Table } from "src/client-ts/components/Table";
import { IconVisible } from "src/client-ts/components/Icon/IconVisible";
import { IconHidden } from "src/client-ts/components/Icon/IconHidden";
import { EditTemplateModal } from "./EditTemplateModal";

type ReportModal = {
  open: boolean;
  template?: ReportTemplate;
};

export const ReportTemplateList = () => {
  const { t } = useTranslation("superAdmin", { keyPrefix: "reportDesigner" });
  const { data: templates, isLoading, isError } = useGetReportTemplates();

  const [deleteReportTemplate, setDeleteReportTemplate] = useState<ReportModal>(
    {
      open: false,
    }
  );

  const [editReportInfo, setEditReportInfo] = useState<ReportModal>({
    open: false,
  });

  const openDeleteReportTemplateView = useCallback(
    (template: ReportTemplate) => {
      setDeleteReportTemplate({
        open: true,
        template: template,
      });
    },
    [setDeleteReportTemplate]
  );

  const openEditReportInfoView = useCallback(
    (template: ReportTemplate) => {
      setEditReportInfo({
        open: true,
        template: template,
      });
    },
    [setEditReportInfo]
  );

  const columnDefs:
    | ColDef<ARJSReportTemplateWithUser>[]
    | ColGroupDef<ARJSReportTemplateWithUser>[] = useMemo(() => {
    return [
      {
        flex: 0.4,
        headerName: "",
        field: "isPublic",
        filter: true,
        cellRenderer: (
          params: ICellRendererParams<ARJSReportTemplateWithUser>
        ) => {
          if (!params.data || !params.data.isPublic) {
            return <IconHidden />;
          }
          return <IconVisible />;
        },
      },
      {
        headerName: t("country"),
        field: "country",
        flex: 0.5,
        filter: "agSetColumnFilter",
      },
      {
        headerName: t("language"),
        field: "language",
        flex: 0.5,
        filter: "agSetColumnFilter",
      },
      {
        headerName: t("name"),
        field: "name",
        filter: "agTextColumnFilter",
      },
      {
        headerName: t("lastEditedBy"),
        field: "lastEditedBy.email",
        flex: 0.75,
        filter: "agTextColumnFilter",
      },
      {
        headerName: t("lastEditedAt"),
        field: "updatedAt",
        flex: 0.75,
        filter: "agDateColumnFilter",
        valueFormatter: (
          params: ValueFormatterParams<ARJSReportTemplateWithUser>
        ) => {
          if (!params.data || !params.data.updatedAt) {
            return "";
          }
          return format(params.data.updatedAt, "DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "organizations",
        headerName: t("organizations"),
        filter: "agSetColumnFilter",
        valueFormatter: (
          params: ValueFormatterParams<ARJSReportTemplateWithUser>
        ) => {
          if (!params.data || !params.data.organizations) {
            return "";
          }
          return params.data.organizations.join(", ");
        },
      },
      {
        field: "plans",
        headerName: t("plans"),
        filter: "agSetColumnFilter",
        valueFormatter: (
          params: ValueFormatterParams<ARJSReportTemplateWithUser>
        ) => {
          if (!params.data || !params.data.plans) {
            return "";
          }
          return params.data.plans.join(", ");
        },
        flex: 0.75,
      },
      {
        flex: 0.5,
        pinned: "right",
        initialWidth: 125,
        cellRenderer: (
          params: ICellRendererParams<ARJSReportTemplateWithUser>
        ) => {
          if (!params.data || !params.data.template) {
            return null;
          }
          return (
            <>
              <ReportTemplateActionsButton
                template={params.data}
                onTemplateDelete={openDeleteReportTemplateView}
                onEditReportInfo={openEditReportInfoView}
              />
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full py-2">
        <CreateReportTemplateDialog />
      </div>

      {isError && (
        <div className="text-red-500 text-sm">{t`errorLoadingTemplates`}</div>
      )}

      {isLoading && <Loader isLoading={true} kind="spinner" />}

      <div className="h-[500px]">
        <Table<ARJSReportTemplateWithUser>
          className={"w-full h-full"}
          rowData={templates}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            floatingFilter: true,
          }}
        />
      </div>
      {deleteReportTemplate.open && (
        <ConfirmDeleteReportTemplateModal
          isOpen={deleteReportTemplate.open}
          closeModal={() => {
            setDeleteReportTemplate((current) => ({ ...current, open: false }));
          }}
          template={deleteReportTemplate.template}
        />
      )}
      {editReportInfo.open && editReportInfo.template && (
        <EditTemplateModal
          isOpen={editReportInfo.open}
          closeModal={() => {
            setEditReportInfo(() => ({ open: false }));
          }}
          template={editReportInfo.template}
        />
      )}
    </div>
  );
};
