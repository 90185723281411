import axiosRestApiClient from "src/client-ts/axiosRestApiConfig";
import { reportError } from "src/client-ts/utils/error";

export const MAX_PAGE_LIMIT = 10000;

export type ResourceDto = {
  id: number;
  workItemId: number;
  workItemCode: string;
  workItemDescription: string | null;
  workItemUnit: string | null;
  subProjectCode: string;
  name: string | null;
  unit: string | null;
  costType: string | null;
  price: number | null;
  consumption: number | null;
  additionalPercentage: number | null;
  discountPercentage: number;
  index: number | null;
  vatPercentage: number | null;
  group: string | null;
  resourceGroup: string | null;
  sortingNumber: string | null;
  supplier: string | null;
  supplierCode: string | null;
  internalPricelistId: number | null;
  costGroup: string | null;
  calculations: ResourceCalculationsDto;
  locations: ResourceLocationDto[];
  eanCode: string | null;
  salesBatchUnit: string | null;
  salesBatchNetContent: number | null;
};

export type ResourceCalculationsDto = {
  workItemQuantity: number;
  quantity: number;
  price: number;
  pricePerUnit: number;
  pricePerQuantity: number;
  productionRate: number | undefined;
  totalPrice: number;
  totalPriceWithDiscount: number;
};

export type LocationDto = {
  code: string;
  description: string | null;
};

export type ResourceLocationDto = {
  code: string;
  quantity: number;
  price: number;
};

export type ProjectDto = {
  code: string;
  name: string;
  version: string;
  currency: string;
};

export type PagedResponse<T> = {
  results: T[];
};

export async function getResourceDetails(
  projectId: number,
  options: {
    includeSocialExpenses: boolean;
    limit: number;
  }
): Promise<PagedResponse<ResourceDto>> {
  const queryUrl = `/api/v1/estima/projects/${projectId}/resources?include=locations&include=calculations&includeSocialExpenses=${options.includeSocialExpenses}&limit=${options.limit}`;
  return getFromRestApi(queryUrl);
}

export async function getProjectDetails(
  projectId: number
): Promise<ProjectDto> {
  const queryUrl = `/api/v1/estima/projects/${projectId}`;
  return getFromRestApi(queryUrl);
}

export async function getProjectLocations(
  projectId: number,
  options: {
    limit: number;
  }
): Promise<PagedResponse<LocationDto>> {
  const queryUrl = `/api/v1/estima/projects/${projectId}/locations?limit=${options.limit}`;
  return getFromRestApi(queryUrl);
}

async function getFromRestApi(queryUrl: string) {
  const responsedata = axiosRestApiClient
    .get(queryUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      reportError("Error while fetching project details from REST API", error);
    });
  return responsedata;
}
