import { GetLocaleTextParams } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { ColDef, themeAlpine } from "ag-grid-community";
import { percentageColDef } from "./percentageColDef";
import { Ref, useCallback, useMemo, useState } from "react";
import { moneyColDef } from "./moneyColDef";
import { twoDecimalColDef } from "./decimalColDef";
import { isoDateColDef } from "./isoDateDef";
import { differenceColDef } from "./differenceColDef";
import { percentageWithZeroValueColDef } from "./percentageWithZeroValueColDef";
import { editableCellColDef } from "./editableCellColDef";
import { isoDateWithTimeColDef } from "./isoDateWithTimeDef";
import "./alpineTheme.css";

type Props<T> = {
  className?: string;
  gridRef?: Ref<AgGridReact<T>>;
} & AgGridReactProps<T>;

export const Table = <T,>({
  className,
  gridRef,
  onGridReady,
  ...props
}: Props<T>) => {
  const { t } = useTranslation("agGrid");
  const columnTypes: {
    [key: string]: ColDef<T>;
  } = useMemo(() => {
    return {
      percentage: percentageColDef,
      percentageWithZeroValue: percentageWithZeroValueColDef,
      money: moneyColDef,
      twoDecimal: twoDecimalColDef,
      isoDate: isoDateColDef,
      isoDateWithTime: isoDateWithTimeColDef,
      difference: differenceColDef,
      editable: editableCellColDef,
      ...props.columnTypes,
    };
  }, [props.columnTypes]);

  const [gridReady, setGridReady] = useState(false);

  const handleGridReady = useCallback(
    // eslint-disable-next-line
    (event: any) => {
      setGridReady(true);

      if (onGridReady !== undefined) {
        onGridReady(event);
      }
    },
    [onGridReady]
  );

  return (
    <div
      className={`ag-theme-alpine ag-grid tabular-nums ${className ?? ""}`}
      data-testid={"ag-grid-table"}
    >
      {!gridReady && (
        <div className={"hidden"} data-testid="__ag-grid-not-ready" />
      )}
      <AgGridReact<T>
        {...props}
        theme={themeAlpine}
        ref={gridRef}
        columnTypes={columnTypes}
        animateRows={true}
        getLocaleText={(value: GetLocaleTextParams) => t(value.key)}
        suppressColumnVirtualisation={process.env.NODE_ENV === "test"}
        onGridReady={handleGridReady}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};
