import { Button } from "@tocoman/ui";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EstimationProjectSummary } from "ts-bindings/EstimationProjectSummary";
import { useWorkerState } from "../../hooks/useWorkerState";
import { useDefault } from "../../hooks/useDefault";
import { ProjectDetailsModal } from "./ProjectDetailsModal/ProjectDetailsModal";
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  ITooltipParams,
  StatusPanelDef,
  ValueGetterParams,
} from "ag-grid-community";
import { Table } from "src/client-ts/components/Table";
import { FeatureFlags } from "../../split/FeatureFlags";
import { useFeatureEnabled } from "src/client-ts/split/useFeatureEnabled";
import { ProjectActionsButton } from "./ProjectActions/ProjectActionsButton";
import { ConfirmDeleteProjectModal } from "./ProjectActions/ConfirmDeleteProjectModal";
import { AgGridReact } from "ag-grid-react";
import { TransportFileUploadModal } from "./ProjectActions/FileUpload/TransportFileUploadModal";
import { useSelectRowsAsync } from "../../components/Table/useSelectRowsAsync";
import {
  useLicenseFeatureEnabled,
  LicenseFeature,
} from "../../LicenseFeatures/useLicenseFeature";
import { StatusBarButtons } from "./StatusBarButtons";
import { useUsersAgGridConfiguration } from "../../components/Table/saveAgGridConfigurations/useUsersAgGridConfiguration";
import { useFrontEndNavigation } from "src/client-ts/FrontEndNavigation/useFrontEndNavigation";

type EditProject = {
  newProject: boolean;
  open: boolean;
  project?: EstimationProjectSummary;
};

type DeleteProject = {
  open: boolean;
  project?: EstimationProjectSummary;
};

type ProjectRowData = EstimationProjectSummary & {
  groupName: string;
};

/**
 * List of projects
 */
export const ProjectList = () => {
  const [transportFileModalOpen, setTransportFileModalOpen] = useState(false);
  const [editProject, setEditProject] = useState<EditProject>({
    newProject: false,
    open: false,
  });
  const [deleteProject, setDeleteProject] = useState<DeleteProject>({
    open: false,
  });
  const { t } = useTranslation("costControl", { keyPrefix: "projectList" });

  const projects = useDefault(useWorkerState("EstimationProjectsState"), []);

  const editDialogFeatureFlag = FeatureFlags.PROJECT_NEW_EDIT_DIALOG;
  const isEditDialogEnabled = useFeatureEnabled(editDialogFeatureFlag);

  const projectGroupsEnabled = useLicenseFeatureEnabled(
    LicenseFeature.PROJECT_GROUPS
  );

  const navigate = useFrontEndNavigation();

  const gridRef = useRef<AgGridReact<ProjectRowData>>(null);

  const [gridReady, setGridReady] = useState(false);

  const openCreateProjectView = useCallback(() => {
    if (isEditDialogEnabled) {
      setEditProject({ newProject: true, open: true });
      return;
    }
    window.location.href = "/add-estimation-project";
  }, [isEditDialogEnabled]);

  const openEditProjectView = useCallback(
    (project: EstimationProjectSummary) => {
      setEditProject({
        newProject: false,
        open: true,
        project: project,
      });
    },
    [setEditProject]
  );

  const selectRowsAsync = useSelectRowsAsync(gridRef.current?.api);

  const openDeleteProjectView = useCallback(
    (project: EstimationProjectSummary) => {
      setDeleteProject({
        open: true,
        project: project,
      });
    },
    [setDeleteProject]
  );

  const rowData: ProjectRowData[] = useMemo<ProjectRowData[]>(() => {
    return projects.map((project) => {
      return {
        ...project,
        groupName: project.projectGroup ?? "",
      };
    });
  }, [projects]);

  const projectsTooltip = (props: ITooltipParams) => {
    const data = props.api.getDisplayedRowAtIndex(
      props.rowIndex ? props.rowIndex : 0
    )?.data;

    return (
      <div className={"bg-[rgba(0,0,0,0.9)] p-3 text-white rounded"}>
        <p>
          <span>{data.name}</span>
        </p>
      </div>
    );
  };

  const getPublishedState = useCallback(
    (params: ValueGetterParams) => {
      if (!params.data) {
        return null;
      }

      if (params.data.isReportingProject) {
        return t`publishedState.reporting`;
      }
      // Documentation from src/common/types/Project.purs:
      // -- OhjelmaTyyppi (programType):
      // -- 4: "Tuotannonsuunnittelu", in production planning
      // -- else: "Laskenta", in estimation
      return params.data.programType === 4
        ? t`publishedState.prodPlanning`
        : t`publishedState.estimation`;
    },
    [t]
  );

  const projectEditCellRenderer = useCallback(
    (cellData: ICellRendererParams<EstimationProjectSummary>) => {
      const project = cellData.data;
      if (!project) {
        return <></>;
      }
      return (
        <div className="flex items-center h-full">
          {!cellData.node.group && (
            <>
              {isEditDialogEnabled && (
                <ProjectActionsButton
                  project={project}
                  onProjectEdit={openEditProjectView}
                  onProjectDelete={openDeleteProjectView}
                  cellData={cellData}
                />
              )}
              {!isEditDialogEnabled && (
                <Button
                  label={t`tableActions.openProject`}
                  className={"ml-2 mr-2"}
                  onClick={() =>
                    navigate("EstimationProjectDetailsFERoute", {
                      projectId: project.id,
                    })
                  }
                />
              )}
            </>
          )}
        </div>
      );
    },
    [t, isEditDialogEnabled, openEditProjectView, openDeleteProjectView]
  );

  const columnDefs: ColDef<ProjectRowData>[] = useMemo(
    () => [
      {
        field: "id",
        headerName: t`tableColumns.projectId`,
        initialHide: true,
        initialWidth: 110,
      },
      {
        field: "code",
        headerName: t`tableColumns.identifier`,
        enableRowGroup: true,
        initialWidth: 140,
      },
      {
        field: "name",
        headerName: t`tableColumns.name`,
        enableRowGroup: true,
        initialWidth: 300,
        flex: 2,
        tooltipField: "name",
      },
      {
        field: "version",
        headerName: t`tableColumns.version`,
        enableRowGroup: true,
        initialWidth: 80,
      },
      {
        field: "createdDate",
        headerName: t`tableColumns.createdDate`,
        enableRowGroup: true,
        sort: "desc",
        initialWidth: 150,
        type: "isoDate",
      },
      {
        field: "groupName",
        headerName: t`tableColumns.projectGroup`,
        enableRowGroup: true,
        initialWidth: 140,
        filter: "agSetColumnFilter",
        initialHide: !projectGroupsEnabled,
        suppressColumnsToolPanel: !projectGroupsEnabled,
      },
      {
        field: "state",
        headerName: t`tableColumns.status`,
        enableRowGroup: true,
        initialWidth: 100,
        filter: "agSetColumnFilter",
      },
      {
        field: "programType",
        headerName: t`tableColumns.published`,
        enableRowGroup: true,
        initialWidth: 150,
        filter: "agSetColumnFilter",
        valueGetter: getPublishedState,
      },
      {
        colId: "edit",
        headerName: "",
        pinned: "right",
        width: 106,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        cellRenderer: projectEditCellRenderer,
      },
    ],
    [t, projectGroupsEnabled, getPublishedState, projectEditCellRenderer]
  );

  const defaultColDef: ColDef<ProjectRowData> = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      flex: 1,
      tooltipComponent: projectsTooltip,
    }),
    []
  );

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: StatusBarButtons,
          key: "statusBarCompKey",
          statusPanelParams: {
            openCreateProjectView,
            setTransportFileModalOpen,
          },
        },
      ],
    };
  }, [openCreateProjectView]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: t`columns`,
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const handleProjectDetailsModalClose = () =>
    setEditProject((current) => ({ ...current, open: false }));

  const handleProjectDetailsModalSaveClose = (savedProjectId: number) => {
    if (savedProjectId) {
      selectRowsAsync([savedProjectId.toString()]);
    }
    handleProjectDetailsModalClose();
  };

  const { configEvents } = useUsersAgGridConfiguration(
    gridReady,
    gridRef,
    "project-list"
  );

  const onGridReady = () => {
    setGridReady(true);
  };

  return (
    <>
      <Table<ProjectRowData>
        autoGroupColumnDef={{
          filter: "agGroupColumnFilter",
        }}
        className="w-full h-full mt-4"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowGroupPanelShow={"always"}
        rowSelection={{ mode: "singleRow", checkboxes: false }}
        statusBar={statusBar}
        sideBar={sideBar}
        gridRef={gridRef}
        getRowId={getProjectRowId}
        accentedSort={true}
        onRowDoubleClicked={(e) =>
          navigate("EstimationFERoute", { projectId: e.data?.id })
        }
        {...configEvents}
        onGridReady={onGridReady}
      />
      {transportFileModalOpen && (
        <TransportFileUploadModal
          closeModal={() => {
            setTransportFileModalOpen(false);
          }}
          isOpen={transportFileModalOpen}
        />
      )}
      {editProject.open && (
        <ProjectDetailsModal
          isOpen={editProject.open}
          closeModal={handleProjectDetailsModalClose}
          projectId={editProject.project?.id}
          newProject={editProject.newProject}
          onSave={handleProjectDetailsModalSaveClose}
        />
      )}
      {deleteProject.open && (
        <ConfirmDeleteProjectModal
          isOpen={deleteProject.open}
          closeModal={() => {
            setDeleteProject((current) => ({ ...current, open: false }));
          }}
          project={deleteProject.project}
        />
      )}
    </>
  );
};

function getProjectRowId(params: GetRowIdParams<ProjectRowData>): string {
  return String(params.data.id);
}
