import {
  AllEnterpriseModule,
  LicenseManager,
  ModuleRegistry,
} from "ag-grid-enterprise";

export function licenseAgGrid() {
  ModuleRegistry.registerModules([AllEnterpriseModule]);
  LicenseManager.setLicenseKey(
    "Using_this_{AG_Grid}_Enterprise_key_{AG-067724}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tocoman_Oy}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Tofuman}_only_for_{7}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Tofuman}_need_to_be_licensed___{Tofuman}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_November_2025}____[v3]_[01]_MTc2NDM3NDQwMDAwMA==3638bf3e3092f8ff9ed84618c4a31061"
  );
}
